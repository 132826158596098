import { useMemo } from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import useNetworksActions from 'store/actions/networks'
import {
  useNetworksSidebar,
  useSelectedNetworkId,
  useNetworks,
  useNetworksLoaders,
} from 'store/selectors'
import ArrowLeft from '@mui/icons-material/ArrowLeft'
import CircularProgress from '@mui/material/CircularProgress'

const List = ({
  networks,
  selectedNetworkId,
  selectNetwork,
  bottomDivider = false,
  loadingNetworkId = null,
}) =>
  networks.map((network, index) => (
    <Box key={network.id}>
      <Box
        onClick={selectNetwork.bind(null, network.id)}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 80,
          height: 40,
          cursor: 'pointer',
          fontWeight: selectedNetworkId === network.id ? 700 : 400,
          backgroundColor:
            selectedNetworkId === network.id ? 'action.selected' : undefined,
          '&:hover': {
            backgroundColor:
              selectedNetworkId !== network.id ? 'action.hover' : undefined,
          },
        }}
      >
        {network.id === loadingNetworkId ? (
          <CircularProgress size={15} />
        ) : (
          <>
            {network.short_name}
            {selectedNetworkId === network.id && (
              <ArrowLeft
                sx={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: -17,
                  height: 40,
                  width: 40,
                }}
              />
            )}
          </>
        )}
      </Box>
      {index !== networks.length - +!bottomDivider && <Divider />}
    </Box>
  ))

const NetworkList = () => {
  const sidebar = useNetworksSidebar()
  const networks = useNetworks()
  const selectedNetworkId = useSelectedNetworkId()
  const { selectNetwork } = useNetworksActions()
  const { network: loadingNetworkId } = useNetworksLoaders()

  const topNetworks = useMemo(() => {
    if (!sidebar) return
    return sidebar.top.map((networkId) => networks[networkId])
  }, [sidebar, networks])

  const staffNetworks = useMemo(() => {
    if (!sidebar) return
    return sidebar.staff.map((networkId) => networks[networkId])
  }, [sidebar, networks])

  const headerBoxStyles = {
    flex: 0,
    p: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: 'divider',
    borderStyle: 'solid',
    height: 41,
    fontWeight: 700,
    backgroundColor: 'background.paper',
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {topNetworks.length > 0 && (
        <Box sx={{ flex: 1 }}>
          <Box sx={headerBoxStyles}>Top {topNetworks.length}</Box>
          <Box
            sx={{
              flex: 1,
              position: 'relative',
              width: '100%',
              height: 'calc(100% - 41px)',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'auto',
              }}
            >
              <List
                networks={topNetworks}
                selectedNetworkId={selectedNetworkId}
                selectNetwork={selectNetwork}
                bottomDivider
                loadingNetworkId={loadingNetworkId}
              />
            </Box>
          </Box>
        </Box>
      )}
      {staffNetworks.length > 0 && (
        <Box sx={{ flex: 0 }}>
          <Box sx={{ ...headerBoxStyles, borderTopWidth: 1 }}>Staff</Box>
          <List
            networks={staffNetworks}
            selectedNetworkId={selectedNetworkId}
            selectNetwork={selectNetwork}
            loadingNetworkId={loadingNetworkId}
          />
        </Box>
      )}
    </Box>
  )
}

export default NetworkList
