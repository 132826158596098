import { useState, useEffect, useRef, useCallback } from 'react'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'
import useSearchResults from './useSearchResults'
import InfiniteScroll from 'react-infinite-scroll-component'
import SearchResults from './SearchResults'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const PAGE_SIZE = 10

const initialParams = {
  searchTerm: '',
  offset: 0,
  limit: PAGE_SIZE,
}

const SearchBar = ({ loadingMessage, onSelectUser, selectedUser }) => {
  const [params, setParams] = useState(initialParams)
  const results = useSearchResults(params)
  const containerRef = useRef(null)

  const handleInputChange = useCallback((e) => {
    setParams((params) => ({
      ...params,
      searchTerm: e.target.value,
      offset: 0,
    }))
  }, [])

  const handleClickResult = useCallback(
    (user) => {
      setParams(initialParams)
      onSelectUser(user)
    },
    [onSelectUser]
  )

  // close search bar when clicking outside
  useEffect(() => {
    const handler = (event) => {
      if (!containerRef.current.contains(event.target)) setParams(initialParams)
    }

    document.addEventListener('click', handler)
    return () => document.removeEventListener('click', handler)
  }, [])

  const handleNextPage = useCallback(() => {
    setParams((params) => ({
      ...params,
      offset: params.offset + PAGE_SIZE,
    }))
  }, [])

  return (
    <Box
      ref={containerRef}
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        py: '10px',
        px: 2,
        '& input': {
          width: '100%',
          border: 0,
          backgroundColor: 'transparent',
          color: 'text.primary',
          fontSize: '1.1em',
        },
      }}
    >
      {(() => {
        if (loadingMessage) return <Box sx={{ flex: 1 }}>{loadingMessage}</Box>

        if (selectedUser)
          return <Box sx={{ flex: 1 }}>{selectedUser.full_name}</Box>

        return (
          <input
            placeholder="Search Users"
            value={params.searchTerm}
            onChange={handleInputChange}
          />
        )
      })()}
      <Box
        sx={{
          width: '30px',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {(() => {
          if (loadingMessage) return <CircularProgress size={16} />

          if (selectedUser)
            return (
              <IconButton size="small" onClick={onSelectUser.bind(null, null)}>
                <CloseIcon />
              </IconButton>
            )

          return <SearchIcon />
        })()}
      </Box>
      {results.items.length > 0 && (
        <Box
          id="searchResults"
          sx={{
            position: 'absolute',
            top: 'calc(100% + 1px)',
            left: 0,
            right: 0,
            backgroundColor: 'background.paper',
            maxHeight: '400px',
            overflow: 'scroll',
          }}
        >
          <InfiniteScroll
            dataLength={results.items.length}
            next={results.items.length === 0 ? undefined : handleNextPage}
            hasMore={results.hasMore}
            scrollableTarget="searchResults"
            scrollThreshold={0.6}
          >
            <SearchResults
              users={results.items}
              onClickUser={handleClickResult}
            />
          </InfiniteScroll>
        </Box>
      )}
    </Box>
  )
}

export default SearchBar
