import Box from '@mui/material/Box'

const Header = ({ text }) => (
  <Box
    sx={{
      fontWeight: '700',
      fontStyle: 'italic',
      fontSize: '1em',
      mb: 1,
      mt: 6,
    }}
  >
    {text}
  </Box>
)

export default Header
