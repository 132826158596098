import Box from '@mui/material/Box'

const GetName = ({ name, onChange }) => {
  return (
    <Box
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'divider',
        borderRadius: '6px',
        '& > input': {
          border: 0,
          outline: 0,
          backgroundColor: 'transparent',
          padding: '16px',
          color: 'text.primary',
          fontSize: '16px',
          width: '100%',
        },
      }}
    >
      <input
        onChange={(e) => onChange(e.target.value)}
        value={name}
        placeholder="Enter name"
      />
    </Box>
  )
}

export default GetName
