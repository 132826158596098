import React, { useEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box'
import * as d3 from 'd3'
import VisHooks from './VisHooks'

function createVis(container, nodes, links) {
  const { width, height } = container.getBoundingClientRect()

  const svg = d3
    .select(container)
    .append('svg')
    .attr('viewBox', [-width / 2, -height / 2, width, height])

  const zoomG = svg.append('g').attr('class', 'zoom')
  const rotationG = zoomG.append('g').attr('class', 'rotation')
  const linkG = rotationG.append('g').attr('class', 'links')
  const nodeG = rotationG.append('g').attr('class', 'nodes')

  const link = linkG
    .selectAll('line')
    .data(links)
    .join('line')
    .attr('class', (d) => `link strength-${d.strength}`)

  const node = nodeG.selectAll('circle').data(nodes).join('circle')

  return { svg, zoomG, rotationG, node, link, width, height }
}

const ForceDirectedGraph = ({ nodes, links }) => {
  const containerRef = useRef(null)
  const [visElements, setVisElements] = useState(null)

  useEffect(() => {
    if (!nodes || !links) return

    const container = containerRef.current

    const { svg, zoomG, rotationG, node, link, width, height } = createVis(
      container,
      nodes,
      links
    )

    const simulation = d3
      .forceSimulation()
      .on('tick', () => {
        link
          .attr('x1', (d) => d.source.x)
          .attr('y1', (d) => d.source.y)
          .attr('x2', (d) => d.target.x)
          .attr('y2', (d) => d.target.y)

        node.attr('cx', (d) => d.x).attr('cy', (d) => d.y)
      })
      .stop()
      .alpha(0)

    setVisElements({
      nodes,
      links,
      svg,
      zoomG,
      rotationG,
      node,
      link,
      simulation,
      width,
      height,
    })

    return () => {
      container.innerHTML = ''
      simulation.stop().alpha(0)
    }
  }, [nodes, links])

  const coverStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }

  return (
    <>
      <Box ref={containerRef} sx={{ ...coverStyles, '& > svg': coverStyles }} />
      {visElements && <VisHooks visElements={visElements} inDragMode={false} />}
    </>
  )
}

export default ForceDirectedGraph
