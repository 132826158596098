import axios from 'axios'
import queryString from 'query-string'
import { ADMIN_API_URL, AI_SEARCH_URL } from 'const'

export const searchReclips = async (searchTerm, page, limitToUser = false) => {
  try {
    const params = queryString.stringify({
      q: searchTerm,
      page,
      limit_to_user: limitToUser,
    })

    const url = `${ADMIN_API_URL}/search/reclips?${params}`
    const { data: results } = await axios.get(url)
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const exportReclips = async (searchTerm, limitToUser = false) => {
  try {
    const params = queryString.stringify({
      q: searchTerm,
      limit_to_user: limitToUser,
    })

    const url = `${ADMIN_API_URL}/search/reclips/export?${params}`
    const { data } = await axios.get(url)
    return data.url
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const searchReclipsVector = async (
  searchTerm,
  userId = undefined,
  engineName
) => {
  try {
    const params = queryString.stringify({
      q: searchTerm,
      user_id: userId,
      engine_name: engineName,
    })

    const url = `${AI_SEARCH_URL}/search?${params}`
    const { data: results } = await axios.get(url)
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const listVectorSearchEngines = async () => {
  try {
    const url = `${AI_SEARCH_URL}/search/engines`
    const { data: results } = await axios.get(url)
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
