import axios from 'axios'
import { ADMIN_API_URL } from 'const'

export const getInitialData = async () => {
  try {
    const url = `${ADMIN_API_URL}/reactions/init`
    const { data } = await axios.get(url)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const react = async (postData) => {
  try {
    const url = `${ADMIN_API_URL}/reactions`
    const { data } = await axios.post(url, postData)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
