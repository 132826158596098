import { useCallback, useMemo } from 'react'
import useNetworksActions from 'store/actions/networks'
import {
  useSelectedMetric,
  useNetworksMetrics,
  useSelectedNetwork,
  useSelectedNetworkNodes,
  useSelectedNetworkLinks,
} from 'store/selectors'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Histogram from './Histogram'
import {
  NULL_METRIC_FILL,
  HIDDEN_USER_FILL,
  HIDDEN_USER_STROKE,
  LINK_STROKE,
  LINK_STROKE_WIDTH,
  LINK_STROKE_CHAT,
  LINK_STROKE_WIDTH_CHAT,
} from '../config'
import { hueGradient } from '../utils'
import MetricSelector from '../MetricSelector'
// import Controls from './Controls'

const Legend = () => {
  const selectedMetric = useSelectedMetric()
  const metrics = useNetworksMetrics()
  const nodes = useSelectedNetworkNodes()
  const links = useSelectedNetworkLinks()
  const totals = useSelectedNetwork()
  const { highlightNodes, highlightLinks } = useNetworksActions()

  const metric = useMemo(() => {
    return metrics.find((m) => m.name === selectedMetric)
  }, [metrics, selectedMetric])

  const points = useMemo(() => {
    return nodes
      .map((node) => node.metrics[selectedMetric])
      .filter((point) => point !== null && point !== undefined)
  }, [nodes, selectedMetric])

  const setHighlightedNodes = useCallback(
    (nodesFilter) => {
      const nodeIds = nodesFilter(nodes).map(({ id }) => id)
      highlightNodes(nodeIds)
    },
    [nodes, highlightNodes]
  )

  const setHighlightedLinks = useCallback(
    (filteredLinks) => {
      const linkIds = filteredLinks.map(({ id }) => id)
      highlightLinks(linkIds)
    },
    [highlightLinks]
  )

  const nullCount = useMemo(() => {
    return nodes
      .filter((n) => n.is_reclip_user)
      .filter(
        (n) =>
          n.metrics[selectedMetric] === undefined ||
          n.metrics[selectedMetric] === null
      ).length
  }, [nodes, selectedMetric])

  const nodesLegend = [
    {
      background: hueGradient({ hueRange: [170, 360] }),
      border: '1px black solid',
      text: 'users w/ metric',
      count: totals.num_reclip_nodes - nullCount,
      nodesFilter: (nodes) =>
        nodes.filter((n) => {
          if (!n.is_reclip_user) return false
          const value = n.metrics[selectedMetric]
          return value !== null && value !== undefined
        }),
    },
    {
      background: NULL_METRIC_FILL,
      border: '1px black solid',
      text: 'users w/out metric',
      count: nullCount,
      nodesFilter: (nodes) =>
        nodes.filter((n) => {
          if (!n.is_reclip_user) return false
          const value = n.metrics[selectedMetric]
          return value === null || value === undefined
        }),
    },
    {
      background: HIDDEN_USER_FILL,
      border: `1px ${HIDDEN_USER_STROKE} solid`,
      text: 'hidden users',
      count: totals.num_non_reclip_nodes,
      nodesFilter: (nodes) => nodes.filter((n) => !n.is_reclip_user),
    },
  ]

  const linksLegend = [
    {
      background: LINK_STROKE_CHAT,
      height: LINK_STROKE_WIDTH_CHAT,
      text: 'friendship + contact',
      filteredLinks: links.filter((l) => !!l.friendship && !!l.contacts),
    },
    {
      background: '#fc7600',
      height: LINK_STROKE_WIDTH_CHAT,
      text: 'friendship only',
      filteredLinks: links.filter((l) => !!l.friendship && !l.contacts),
    },
    {
      background: LINK_STROKE,
      height: LINK_STROKE_WIDTH,
      text: 'contact only',
      filteredLinks: links.filter((l) => !l.friendship && !!l.contacts),
    },
  ]

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'background.default',
        border: (theme) => `1px ${theme.palette.divider} solid`,
        width: '250px',
        borderRadius: 2,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          color: 'text.primary',
          textTransform: 'capitalize',
        },
      }}
    >
      <Box
        sx={{
          mt: 2,
          mx: 2,
          mb: 1,
        }}
      >
        <MetricSelector />
      </Box>
      <Box sx={{ height: 150, px: 3, pb: 0 }}>
        <Histogram points={points} is_date={metric.is_date} />
      </Box>
      <Divider />
      <Box sx={{ m: 1 }} onMouseLeave={highlightNodes.bind(null, [])}>
        {nodesLegend.map(({ background, border, text, count, nodesFilter }) => (
          <Box
            key={text}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 1,
              py: '2px',
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
            onMouseEnter={setHighlightedNodes.bind(null, nodesFilter)}
          >
            <Box
              sx={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                mr: '12px',
                background,
                border,
              }}
            />
            <Box sx={{ flex: 1 }}>{text}</Box>
            <Box>{count}</Box>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box sx={{ m: 1 }} onMouseLeave={highlightLinks.bind(null, [])}>
        {linksLegend.map(({ background, height, text, filteredLinks }) => (
          <Box
            key={text}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 1,
              py: '2px',
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
            onMouseEnter={setHighlightedLinks.bind(null, filteredLinks)}
          >
            <Box
              sx={{
                width: '16px',
                height: `${height}px`,
                mr: '12px',
                background,
              }}
            />
            <Box sx={{ flex: 1 }}>{text}</Box>
            <Box>{filteredLinks.length}</Box>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box
        sx={{
          px: 2,
          py: 1.5,
          textAlign: 'left',
          color: 'text.secondary',
        }}
      >
        <Box>● use the mousewheel to zoom</Box>
        <Box>● click and drag to move the viz</Box>
      </Box>
      {/* <Divider />
      <Controls /> */}
    </Box>
  )
}

export default Legend
