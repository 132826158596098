import { useEffect, useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import { useMap } from 'store/selectors'
import useMapActions from 'store/actions/map'
import Legend from './Legend'
import Map from './Map'

const ReclipsMap = () => {
  const {
    data: { reclips },
    error,
  } = useMap()
  const { getRecentReclips } = useMapActions()
  const [isLoading, setIsLoading] = useState(true)
  const [days, setDays] = useState(3)

  useEffect(() => {
    setIsLoading(true)
    getRecentReclips(days).then(() => {
      setIsLoading(false)
    })
  }, [getRecentReclips, days])

  const geocodedReclips = useMemo(() => {
    if (!reclips) return null
    return reclips.filter((r) => !!r.clipped_at?.coordinates)
  }, [reclips])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          zIndex: 1,
        }}
      >
        <Legend
          isLoading={isLoading}
          reclips={reclips}
          error={error}
          days={days}
          setDays={setDays}
        />
      </Box>
      <Map reclips={geocodedReclips} />
    </Box>
  )
}

export default ReclipsMap
