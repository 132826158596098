import axios from 'axios'
import { ADMIN_API_URL } from 'const'
import queryString from 'query-string'

export const searchUsers = async (q, offset, limit) => {
  try {
    const params = queryString.stringify({ q, limit, offset })
    const url = `${ADMIN_API_URL}/users/search?${params}`
    const { data: users } = await axios.get(url)
    return users
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getUser = async (userId) => {
  try {
    const url = `${ADMIN_API_URL}/users/${userId}`
    const { data: user } = await axios.get(url)
    return user
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
