import { Fragment } from 'react'
import Box from '@mui/material/Box'
import useNetworksActions from 'store/actions/networks'
import VirtualizedList from 'components/core/VirtualizedList'
import Avatar from 'components/core/Avatar'
import { useSelectedNetworkNodes } from 'store/selectors'

const SuggestionList = ({ nodes, emptyText }) => {
  const { highlightNodes, selectNode } = useNetworksActions()
  const selectedNetworkNodes = useSelectedNetworkNodes()

  const Description = ({ description, parentNodeId }) => {
    if (!description) return null

    const regex = /@.*?!\[.*?\]/g
    const users = description.match(regex)
    const textStrings = description.split(/@.*?!\[.*?\]/g)

    return textStrings.map((textString, i) => {
      if (!users) return <Fragment key={i.toString}>{textString}</Fragment>

      const user = users[i]
      const button = (() => {
        if (!user) return null

        const username = user.match(/@.*?!(?=\[)/g)[0].slice(1, -1)
        const userId = user.match(/!\[.*?\]/g)[0].slice(2, -1)
        const nodeId = selectedNetworkNodes.find(
          (node) => node.user?.id === userId
        )?.id

        return (
          <Box
            sx={{
              display: 'inline-block',
              fontWeight: 700,
              '&:hover': { textDecoration: 'underline' },
            }}
            onClick={(e) => {
              e.stopPropagation()
              selectNode(nodeId)
            }}
            onMouseEnter={highlightNodes.bind(null, [nodeId])}
            onMouseLeave={highlightNodes.bind(null, [parentNodeId])}
          >
            @{username}
          </Box>
        )
      })()

      return (
        <Fragment key={i.toString()}>
          {textString}
          {button}
        </Fragment>
      )
    })
  }

  const RowComponent = ({ item: node }) => {
    if (!node.user)
      return (
        <Box
          sx={{
            cursor: 'pointer',
            px: 1,
            py: 2,
            // height: '80px',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
          onMouseEnter={highlightNodes.bind(null, [node.id])}
          onMouseLeave={highlightNodes.bind(null, [])}
          onClick={() => {
            selectNode(node.id)
            highlightNodes([])
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>{node.phone_number}</Box>
          </Box>
          <Box sx={{ fontStyle: 'italic', mt: 2, color: 'text.secondary' }}>
            <Description
              description={node.suggestion.description}
              parentNodeId={node.id}
            />
          </Box>
        </Box>
      )

    return (
      <Box
        sx={{
          cursor: 'pointer',
          px: 1,
          py: 1,
          // height: '80px',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
        onMouseEnter={highlightNodes.bind(null, [node.id])}
        onMouseLeave={highlightNodes.bind(null, [])}
        onClick={() => {
          selectNode(node.id)
          highlightNodes([])
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            name={node.user.full_name}
            image={node.user.picture}
            size={30}
          />
          <Box sx={{ ml: 2, minWidth: '180px' }}>@{node.user.username}</Box>
          <Box sx={{ flex: 1 }}>{node.user.full_name}</Box>
        </Box>
        <Box sx={{ fontStyle: 'italic', mt: 1, color: 'text.secondary' }}>
          <Description
            description={node.suggestion.description}
            parentNodeId={node.id}
          />
        </Box>
        <Box
          sx={{
            fontSize: '0.85em',
            mt: 2,
            pr: 1,
            '& > table': {
              width: '100%',
              '& th, td': {
                textAlign: 'right',
              },
              '& th:nth-of-type(1),td:nth-of-type(1)': {
                textAlign: 'left',
              },
              '& tfoot': {
                fontWeight: 700,
              },
            },
          }}
        >
          <table>
            <thead>
              <tr>
                <th>Factor</th>
                <th>Value</th>
                <th>Weight</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Friends in Common</td>
                <td>{node.suggestion.friends_in_common}</td>
                <td>{node.suggestion.friends_in_common_weight}</td>
                <td>
                  {node.suggestion.friends_in_common *
                    node.suggestion.friends_in_common_weight}
                </td>
              </tr>
              <tr>
                <td>Contacts in Common</td>
                <td>{node.suggestion.contacts_in_common}</td>
                <td>{node.suggestion.contacts_in_common_weight}</td>
                <td>
                  {node.suggestion.contacts_in_common *
                    node.suggestion.contacts_in_common_weight}
                </td>
              </tr>
              <tr>
                <td>Connections in Common</td>
                <td>{node.suggestion.connections_in_common}</td>
                <td>{node.suggestion.connections_in_common_weight}</td>
                <td>
                  {node.suggestion.connections_in_common *
                    node.suggestion.connections_in_common_weight}
                </td>
              </tr>
              <tr>
                <td>In Their Contacts</td>
                <td>{node.suggestion.in_their_contacts ? 'true' : 'false'}</td>
                <td>{node.suggestion.in_their_contacts_weight}</td>
                <td>
                  {+node.suggestion.in_their_contacts *
                    node.suggestion.in_their_contacts_weight}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3}>Total Score</td>
                <td>{node.suggestion.score}</td>
              </tr>
            </tfoot>
          </table>
        </Box>
      </Box>
    )
  }

  if (!nodes) return null

  return (
    <Box sx={{ p: 2, flex: 1 }}>
      <VirtualizedList
        items={nodes}
        RowComponent={RowComponent}
        emptyText={emptyText}
        divider
      />
    </Box>
  )
}

export default SuggestionList
