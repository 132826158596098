import Box from '@mui/material/Box'
import SearchBar from './SearchBar'
import { useNetworksLoaders } from 'store/selectors'
import useNetworksActions from 'store/actions/networks'

const SearchBarLoader = () => {
  const { username } = useNetworksLoaders()
  const { selectUser } = useNetworksActions()

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '450px',
        borderRadius: '8px',
        backgroundColor: 'background.paper',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100% - 8px)',
      }}
    >
      <SearchBar
        loadingMessage={username ? `Loading @${username}'s network...` : null}
        onSelectUser={(user) => selectUser(user.username)}
      />
    </Box>
  )
}

export default SearchBarLoader
