import axios from 'axios'
import { ADMIN_API_URL } from 'const'

export const searchSchools = async (query) => {
  try {
    const url = `${ADMIN_API_URL}/schools/search`
    const { data: schools } = await axios.post(url, query)
    return schools
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getSchool = async (schoolId) => {
  try {
    const url = `${ADMIN_API_URL}/schools?id=${encodeURIComponent(schoolId)}`
    const { data: school } = await axios.get(url)
    return school
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getMap = async () => {
  try {
    const url =
      'https://storage.googleapis.com/production-web-admin-assets/schools_map.json'
    const data = await fetch(url)
    return await data.json()
  } catch (e) {
    throw new Error(e)
  }
}
