import { useEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box'
import {
  Avatar,
  messageHasReactions,
  MessageOptions,
  MessageRepliesCountButton,
  MessageStatus,
  MessageText,
  MessageTimestamp,
  ReactionSelector,
  SimpleReactionsList,
  useMessageContext,
} from 'stream-chat-react'
import ReclipPreview from '../../reclips/Preview'
import { getReclip } from '../../reclips/utils'
// import MessageText from './MessageText'

// import './CustomMessage.css'

export const ReclipMessage = () => {
  const {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
  } = useMessageContext()
  const [reclip, setReclip] = useState(null)

  const messageWrapperRef = useRef(null)
  const hasReactions = messageHasReactions(message)
  const { user } = message

  useEffect(() => {
    getReclip(message).then(setReclip)
  }, [message])

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <Box sx={{ mb: 2.5 }}>
        <Avatar image={user.image} name={user.name || user.id} />
      </Box>
      <Box>
        <Box sx={{ mb: 1 }}>
          <ReclipPreview reclip={reclip} />
        </Box>
        <MessageOptions
          displayLeft={false}
          messageWrapperRef={messageWrapperRef}
        />
        <Box
          sx={{
            '& p': { m: 0 },
          }}
        >
          {/* <MessageText text={message.text} buttons={message.buttons} /> */}
          <MessageText />
        </Box>
        <Box
          sx={{
            opacity: 0.5,
            display: 'flex',
            fontSize: '10px',
            alignItems: 'center',
            mt: 0.5,
          }}
        >
          <Box sx={{ fontWeight: 700, mr: 0.5 }}>{message.user?.name}</Box>
          <MessageTimestamp calendar />
        </Box>
        {showDetailedReactions && isReactionEnabled && (
          <ReactionSelector ref={reactionSelectorRef} />
        )}
        <MessageStatus />
        {hasReactions && !showDetailedReactions && isReactionEnabled && (
          <SimpleReactionsList />
        )}
        <MessageRepliesCountButton reply_count={message.reply_count} />
      </Box>
    </Box>
  )
}

export default ReclipMessage
