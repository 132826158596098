import Box from '@mui/material/Box'

export const TextArea = ({ value, onChange, rows }) => (
  <Box
    sx={{
      '& textarea': {
        backgroundColor: 'background.paper',
        width: '100%',
        height: '100%',
        display: 'block',
        resize: 'none',
        border: 0,
        p: 2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'divider',
        borderRadius: '8px',
        '&:focus': {
          borderColor: 'white',
        },
      },
    }}
  >
    <textarea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      rows={rows || 4}
    />
  </Box>
)

export const Header = ({ text, mt = 4 }) => (
  <Box sx={{ fontWeight: 700, mb: 1, mt, fontStyle: 'italic' }}>{text}</Box>
)

export const Text = ({ children }) => (
  <Box sx={{ fontSize: '0.9em', mt: 1, mb: 2 }}>{children}</Box>
)
