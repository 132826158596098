import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { useEffect, useMemo, useState, useCallback } from 'react'
import { Avatar, useChatContext } from 'stream-chat-react'
import api from 'services/api'
import { isDate, toDateString } from 'services/utils'
import CopyToClipboard from 'components/core/CopyToClipboard'

const EXCLUDED_METRICS = ['username', 'age', 'signup_date']

function renderMetric(value) {
  const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/
  return typeof value === 'string' && value.match(dateRegex)
    ? toDateString(value)
    : value
}

function getAge(birthdayStr) {
  const birthday = new Date(birthdayStr)
  if (!isDate(birthday)) return null
  const ageDifMs = Date.now() - birthday
  const ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

const Heading = ({ title }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontStyle: 'italic',
      color: 'text.secondary',
      mx: 2,
      fontSize: '0.9em',
    }}
  >
    <Divider sx={{ flex: 1 }} />
    <Box sx={{ mx: 1 }}>{title}</Box>
    <Divider sx={{ flex: 1 }} />
  </Box>
)

const UserProfile = () => {
  const { channel: activeChannel, client } = useChatContext()
  const [metrics, setMetrics] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const user = useMemo(() => {
    if (!activeChannel) return null
    const { members } = activeChannel.state
    const userId = Object.keys(members).filter((id) => id !== client.userID)[0]
    return members[userId]?.user
  }, [activeChannel, client.userID])

  const loadMetrics = useCallback(async (user) => {
    if (!user) return setMetrics(null)

    setIsLoading(true)
    try {
      const newMetrics = await api.networks.getUserMetrics(user.username)
      setMetrics(newMetrics)
    } catch (e) {
      setError(e.message)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    loadMetrics(user)
  }, [user, loadMetrics])

  if (!user) return null

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 2,
          py: 3,
        }}
      >
        <Avatar name={user.name} image={user.image} size={80} />
        <Box sx={{ fontWeight: 700, mt: 2 }}>{user.name}</Box>
        <Box sx={{ mt: 1, fontSize: '0.9em', textAlign: 'center' }}>
          <Box>@{user.username}</Box>
          <Box>{getAge(user.date_of_birth)} years old</Box>
          <Box>Joined {toDateString(user.signup_date)}</Box>
        </Box>
      </Box>
      <Heading title="metrics" />
      <Box sx={{ textAlign: 'center', py: 2, flex: 1 }}>
        {(() => {
          if (isLoading) return null

          if (error)
            return <Box sx={{ fontStyle: 'italic' }}>Error: {error}</Box>

          if (!metrics)
            return <Box sx={{ fontStyle: 'italic' }}>No metrics available</Box>

          return (
            <Box sx={{ px: 4, py: 1 }}>
              {Object.keys(metrics)
                .filter((field) => !EXCLUDED_METRICS.includes(field))
                .map((field) => (
                  <Box
                    key={field}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '0.8em',
                    }}
                  >
                    <Box sx={{ fontWeight: 700, mr: 1 }}>{field}:</Box>
                    <Box>{renderMetric(metrics[field])}</Box>
                  </Box>
                ))}
            </Box>
          )
        })()}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          justifyContent: 'center',
          fontSize: '0.8em',
        }}
      >
        <Box
          sx={{
            fontStyle: 'italic',
            color: 'text.secondary',
            textAlign: 'center',
            mr: 1,
          }}
        >
          {user.id}
        </Box>
        <CopyToClipboard data={user.id} title="Copy user id" />
      </Box>
    </Box>
  )
}

export default UserProfile
