/* eslint-disable import/no-webpack-loader-syntax */

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MAPBOX_TOKEN } from 'const'

// see https://github.com/mapbox/mapbox-gl-js/issues/10173
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

mapboxgl.accessToken = MAPBOX_TOKEN

export const mapbox = mapboxgl
