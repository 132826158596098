import Box from '@mui/material/Box'
import { Avatar } from 'stream-chat-react'

const CustomAvatar = ({ enlargeOnHover = false, ...props }) => (
  <Box
    sx={{
      '& .str-chat__avatar': {
        marginRight: '0 !important',
      },
    }}
  >
    <Avatar {...props} />
  </Box>
)

export default CustomAvatar
