import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import DateRangePicker from './DateRangePicker'
import dayjs from 'dayjs'

const DateSelector = ({ onChangeDates }) => {
  const [days, setDays] = useState('365')
  const [dateRange, setDateRange] = useState(null)

  useEffect(() => {
    if (days !== 'custom')
      setDateRange({
        startDate: dayjs().subtract(+days, 'day'),
        endDate: dayjs(),
      })
  }, [days])

  useEffect(() => {
    if (dateRange === null) return
    onChangeDates(dateRange)
  }, [dateRange, onChangeDates])

  return (
    <Box>
      <Box
        sx={{
          p: 0.75,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          '& select': {
            backgroundColor: 'transparent',
            border: 0,
            outline: 0,
            width: '100%',
            minWidth: '150px',
            color: 'text.primary',
            fontWeight: 700,
          },
        }}
      >
        <select
          value={days.toString()}
          onChange={(e) => setDays(e.target.value)}
        >
          <option value="1">past 24 hours</option>
          <option value="3">past 3 days</option>
          <option value="7">past 7 days</option>
          <option value="30">past 30 days</option>
          <option value="365">past year</option>
          <option value="custom">enter date range</option>
        </select>
      </Box>
      {days === 'custom' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <DateRangePicker
            initialStartDate={dateRange.startDate}
            initialEndDate={dateRange.endDate}
            onSubmit={setDateRange}
          />
        </Box>
      )}
    </Box>
  )
}

export default DateSelector
