import React, { useMemo, useState } from 'react'
import { useSelectedNetworkNodes } from 'store/selectors'
import useNetworksActions from 'store/actions/networks'
import Box from '@mui/material/Box'
import VirtualizedList from 'components/core/VirtualizedList'

const HiddenUsers = () => {
  const nodes = useSelectedNetworkNodes()
  const { highlightNodes, selectNode } = useNetworksActions()
  const [sortBy /* setSortBy */] = useState('num_reclip_connections')
  const [sortOrder /* setSortOrder */] = useState('desc')

  const sortedNodes = useMemo(() => {
    if (!nodes) return []

    const modifiedNodes = nodes
      .filter((node) => !node.is_reclip_user)
      .map((node) => ({
        ...node,
        num_reclip_connections: node.connections.length,
      }))

    const out = modifiedNodes.sort((a, b) => b[sortBy] - a[sortBy])

    if (sortOrder === 'asc') out.reverse()

    return out
  }, [nodes, sortBy, sortOrder])

  const RowComponent = ({
    item: { id, phone_number, num_reclip_connections },
  }) => (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      }}
      onMouseEnter={highlightNodes.bind(null, [id])}
      onMouseLeave={highlightNodes.bind(null, [])}
      onClick={selectNode.bind(null, id)}
    >
      <Box sx={{ width: '50%' }}>{phone_number}</Box>
      <Box sx={{ width: '50%' }}>{num_reclip_connections}</Box>
    </Box>
  )

  if (!sortedNodes) return null

  return (
    <>
      {sortedNodes.length > 0 && (
        <Box
          sx={{
            m: 2,
            mt: 3,
            mb: -2,
            pb: 1,
            px: 1,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 700,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: 'divider',
          }}
        >
          <Box sx={{ width: '50%' }}>phone number</Box>
          <Box sx={{ width: '50%' }}>reclip connections</Box>
        </Box>
      )}
      <Box sx={{ p: 2, flex: 1 }}>
        <VirtualizedList
          items={sortedNodes}
          RowComponent={RowComponent}
          emptyText="There are no hidden users in this network."
          divider
        />
      </Box>
    </>
  )
}

export default HiddenUsers
