import Box from '@mui/material/Box'
import Highlighter from 'react-highlight-words'
import findChunksAutoComplete from './findChunksAutocomplete'
import CoverPhoto from '../CoverPhoto'
import { toDateString } from 'services/utils'
import TooltipIconButton, { TooltipIcon } from 'components/core/Tooltip'
import CopyToClipboardButton from 'components/core/CopyToClipboard'
import WatchIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import useModalsActions from 'store/actions/modals'
import { itemStatuses } from '../const'
import ConsentedIcon from '@mui/icons-material/Verified'
import ChatIcon from '@mui/icons-material/Chat'

function getAddIconTitle(status) {
  switch (status) {
    case itemStatuses.SHARED_WITH_TR:
      return 'add to draft'
    case itemStatuses.DRAFT_ADD:
      return 'already added to draft'
    default:
      return 'published'
  }
}

const ListItem = ({
  item,
  highlightText = '',
  setItemStatus,
  editReclip,
  openChat,
}) => {
  const { id, share, status, consent_to_publish } = item
  const title = share.reclip.title || share.reclip.title_auto
  const { openModal } = useModalsActions()
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ flex: 0, mr: 2 }}>
        <CoverPhoto reclip={share.reclip} />
      </Box>
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            @
            <Highlighter
              searchWords={[highlightText]}
              textToHighlight={share.reclip.username}
              findChunks={findChunksAutoComplete}
            />
          </Box>
          <TooltipIcon
            Icon={ConsentedIcon}
            title={
              consent_to_publish
                ? 'user has consented to publication'
                : 'user has not consented to publication'
            }
            sx={{
              color: consent_to_publish ? 'aqua' : undefined,
              fontSize: '0.95em',
              opacity: consent_to_publish ? 1 : 0.3,
              ml: '6px',
            }}
          />
          {share.editor && (
            <Box sx={{ ml: '6px' }}>
              (edited by @
              <Highlighter
                searchWords={[highlightText]}
                textToHighlight={share.editor.username}
                findChunks={findChunksAutoComplete}
              />
              )
            </Box>
          )}
        </Box>
        {title && (
          <Box sx={{ fontWeight: 700 }}>
            <Highlighter
              searchWords={[highlightText]}
              textToHighlight={title}
              findChunks={findChunksAutoComplete}
            />
          </Box>
        )}
        <Box sx={{ fontSize: '0.9em' }}>
          shared: {toDateString(share.created_at)}
        </Box>
        {share.text && (
          <Box sx={{ fontSize: '0.9em' }}>
            <Highlighter
              searchWords={[highlightText]}
              textToHighlight={share.text}
              findChunks={findChunksAutoComplete}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          mx: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <TooltipIconButton
            Icon={AddIcon}
            title={getAddIconTitle(status)}
            onClick={setItemStatus.bind(null, id, itemStatuses.DRAFT_ADD)}
            placement="left"
            disabled={status !== itemStatuses.SHARED_WITH_TR}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <TooltipIconButton
            Icon={WatchIcon}
            title="watch share"
            onClick={() => openModal('sharePlayer', { shareCode: share.code })}
            placement="left"
          />
        </Box>
        {editReclip && (
          <Box sx={{ mt: 1 }}>
            <TooltipIconButton
              Icon={EditIcon}
              title="edit clip"
              onClick={editReclip.bind(null, share.code)}
              placement="left"
            />
          </Box>
        )}
        <Box sx={{ mt: 1 }}>
          <TooltipIconButton
            Icon={ChatIcon}
            title="chat with owner"
            onClick={openChat.bind(null, item)}
            placement="left"
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <CopyToClipboardButton
            title="copy share link"
            data={`${window.location.origin}/s/${share.code}`}
            placement="left"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ListItem
