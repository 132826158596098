// scrollableDiv.current.scrollTop = 0

import { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import ListItem from './ListItem'
import { itemStatuses } from '../const'
import ViewTypeSelector from './ViewTypeSelector'
import Toggle from 'components/core/Toggle'
import PublishButton from './PublishButton'

const viewTypes = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
}

const itemColors = {
  [itemStatuses.PUBLISHED]: 'background.paper',
  [itemStatuses.DRAFT_ADD]: '#2cbb2c',
  [itemStatuses.DRAFT_DELETE]: 'red',
}

function switchItems(arr, index0, index1) {
  const item0 = arr[index0]
  const item1 = arr[index1]
  arr[index0] = item1
  arr[index1] = item0
  return arr
}

const Story = ({
  items,
  setItemStatus,
  updateDraftOrder,
  publish,
  shareDraftWithStaff,
  setShareDraftWithStaff,
}) => {
  const [viewType, setViewType] = useState(viewTypes.DRAFT)
  const [showDeleted, setShowDeleted] = useState(false)
  const scroller = useRef(null)
  const prevItems = useRef([])

  const displayedItems = useMemo(() => {
    switch (viewType) {
      case viewTypes.DRAFT:
        if (showDeleted) return items
        else return items.filter((i) => i.status !== itemStatuses.DRAFT_DELETE)
      case viewTypes.PUBLISHED:
        return items.filter((i) =>
          [itemStatuses.PUBLISHED, itemStatuses.DRAFT_DELETE].includes(i.status)
        )
      default:
        return []
    }
  }, [viewType, items, showDeleted])

  const { orderedItems, minDraftOrder, maxDraftOrder } = useMemo(() => {
    let draftOrder = 0
    displayedItems.forEach((item) => {
      if (item.status === itemStatuses.DRAFT_ADD) {
        item.draftOrder = draftOrder
        draftOrder += 1
      }
    })
    return {
      orderedItems: displayedItems,
      minDraftOrder: 0,
      maxDraftOrder: draftOrder - 1,
    }
  }, [displayedItems])

  const counts = useMemo(() => {
    const c = {
      [itemStatuses.PUBLISHED]: 0,
      [itemStatuses.DRAFT_ADD]: 0,
      [itemStatuses.DRAFT_DELETE]: 0,
    }

    items.forEach((item) => {
      c[item.status] += 1
    })

    return c
  }, [items])

  useEffect(() => {
    if (items.length > prevItems.current.length) {
      scroller.current.scrollTop = scroller.current.scrollHeight
    }
    prevItems.current = items
  }, [items])

  const hasChanges =
    counts[itemStatuses.DRAFT_ADD] !== 0 ||
    counts[itemStatuses.DRAFT_DELETE] !== 0

  const moveUp = useCallback(
    (item) => {
      const itemIds = orderedItems
        .filter((item) => item.status === itemStatuses.DRAFT_ADD)
        .map((item) => item.id)
      const newItemIds = switchItems(
        itemIds,
        item.draftOrder,
        item.draftOrder - 1
      )
      updateDraftOrder(newItemIds)
    },
    [orderedItems, updateDraftOrder]
  )

  const moveDown = useCallback(
    (item) => {
      const itemIds = orderedItems
        .filter((item) => item.status === itemStatuses.DRAFT_ADD)
        .map((item) => item.id)
      const newItemIds = switchItems(
        itemIds,
        item.draftOrder,
        item.draftOrder + 1
      )
      updateDraftOrder(newItemIds)
    },
    [orderedItems, updateDraftOrder]
  )

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ fontWeight: 700 }}>Team Reclip Story</Box>
        <Box sx={{ visibility: hasChanges ? 'visible' : 'hidden' }}>
          <ViewTypeSelector
            viewType={viewType}
            setViewType={setViewType}
            viewTypes={viewTypes}
          />
        </Box>
      </Box>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <Box sx={{ fontSize: '0.9em', fontStyle: 'italic', textAlign: 'center' }}>
        {viewType === viewTypes.DRAFT &&
          !hasChanges &&
          counts[itemStatuses.PUBLISHED] > 0 && (
            <>
              <Box>This is the published story.</Box>
              <Box>Start a new draft by adding or removing an item.</Box>
            </>
          )}
        {viewType === viewTypes.DRAFT &&
          !hasChanges &&
          counts[itemStatuses.PUBLISHED] === 0 && (
            <>
              <Box>The published story is currently empty.</Box>
              <Box>Start a draft by adding an item.</Box>
            </>
          )}
        {viewType === viewTypes.DRAFT && hasChanges && (
          <>
            <Box>This a draft version of the story.</Box>
            <Box>Hit publish to replace the published version with this.</Box>
          </>
        )}
        {viewType === viewTypes.PUBLISHED &&
          counts[itemStatuses.PUBLISHED] > 0 && (
            <>
              <Box>This is the published story.</Box>
            </>
          )}
        {viewType === viewTypes.PUBLISHED &&
          counts[itemStatuses.PUBLISHED] === 0 && (
            <>
              <Box>This published story is currently empty.</Box>
            </>
          )}
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box ref={scroller} sx={{ flex: 1, overflow: 'auto' }}>
        {orderedItems.map((item, index) => (
          <Box
            key={index.toString()}
            sx={{
              p: 2,
              mb: 2,
              borderWidth: 2,
              borderStyle: 'solid',
              borderRadius: 4,
              borderColor:
                viewType === viewTypes.DRAFT
                  ? itemColors[item.status]
                  : itemColors[itemStatuses.PUBLISHED],
            }}
          >
            <ListItem
              item={item}
              setItemStatus={
                viewType === viewTypes.DRAFT ? setItemStatus : undefined
              }
              moveUp={
                viewType === viewTypes.DRAFT &&
                item.status === itemStatuses.DRAFT_ADD &&
                item.draftOrder !== minDraftOrder
                  ? moveUp
                  : undefined
              }
              moveDown={
                viewType === viewTypes.DRAFT &&
                item.status === itemStatuses.DRAFT_ADD &&
                item.draftOrder !== maxDraftOrder
                  ? moveDown
                  : undefined
              }
            />
          </Box>
        ))}
      </Box>
      {viewType === viewTypes.DRAFT && hasChanges && (
        <>
          <Divider />
          <Box
            sx={{
              textAlign: 'center',
              pt: 1,
            }}
          >
            <Box
              sx={{
                mb: 1.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '0.9em',
              }}
            >
              <Toggle
                isChecked={shareDraftWithStaff}
                setIsChecked={setShareDraftWithStaff}
                label="share draft with staff"
              />
              {counts[itemStatuses.DRAFT_DELETE] !== 0 && (
                <Toggle
                  isChecked={showDeleted}
                  setIsChecked={setShowDeleted}
                  label="show removed items"
                />
              )}
            </Box>
            <PublishButton publish={publish} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default Story
