import { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import { Avatar } from 'stream-chat-react'

const UserList = ({ users, onSelectUser }) => {
  const [focusedUser, setFocusedUser] = useState(null)

  const handleKeyDown = useCallback(
    (e) => {
      // check for up(38) or down(40) key
      if (e.which === 38) {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0
          return prevFocused === 0 ? users.length - 1 : prevFocused - 1
        })
      }
      if (e.which === 40) {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0
          return prevFocused === users.length - 1 ? 0 : prevFocused + 1
        })
      }
      if (e.which === 13) {
        e.preventDefault()
        if (focusedUser !== undefined) {
          onSelectUser(users[focusedUser])
          return setFocusedUser(undefined)
        }
      }
    },
    [users, focusedUser, onSelectUser]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {!users.length && (
        <Box
          sx={{
            padding: '12px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          No people found...
        </Box>
      )}
      {users.map((user, idx) => (
        <Box
          key={user.id}
          onClick={onSelectUser.bind(null, user)}
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            margin: '0',
            padding: '12px',
            cursor: 'pointer',
            backgroundColor: focusedUser === idx ? 'action.focus' : undefined,
            '&:hover': {
              backgroundColor: 'action.hover',
            },
            borderBottomWidth: idx === users.length - 1 ? 0 : '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'divider',
          }}
        >
          <Avatar image={user.image} name={user.name || user.id} size={40} />
          <Box>
            <Box>{user.name}</Box>
            <Box sx={{ lineHeight: '16px', fontSize: '13px' }}>
              @{user.username}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default UserList
