import Box from '@mui/material/Box'

const Tabs = ({ tabs, tab, setTab }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      {tabs.map(({ type, text }, index) => (
        <Box
          key={type}
          sx={{
            fontWeight: 700,
            fontSize: '16px',
            px: 3,
            py: 1,
            borderLeftWidth: 1,
            borderTopWidth: 1,
            borderBottomWidth: 0,
            borderStyle: 'solid',
            borderColor: 'divider',
            cursor: 'default',
            userSelect: 'none',
            borderTopLeftRadius: index === 0 ? '6px' : undefined,
            borderTopRightRadius: index === tabs.length - 1 ? '6px' : undefined,
            borderRightWidth: index === tabs.length - 1 ? 1 : 0,
            backgroundColor: type === tab.type ? 'action.selected' : undefined,
            '&:hover':
              type === tab.type
                ? undefined
                : {
                    backgroundColor: 'action.hover',
                    cursor: 'pointer',
                  },
          }}
          onClick={setTab.bind(null, type)}
        >
          {text}
        </Box>
      ))}
    </Box>
  )
}

export default Tabs
