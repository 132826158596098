import { useMemo } from 'react'
import Box from '@mui/material/Box'
import {
  List,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer,
} from 'react-virtualized'

const VirtualizedList = ({
  RowComponent,
  items,
  defaultHeight,
  emptyText,
  divider,
}) => {
  const cache = useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight,
    })
  }, [defaultHeight])

  const renderRow = ({ index, key, parent, style }) => (
    <CellMeasurer
      key={key}
      cache={cache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      {({ registerChild }) => (
        <Box
          ref={registerChild}
          sx={{
            ...(divider && index !== items.length - 1
              ? {
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: 'divider',
                }
              : {}),
            ...style,
          }}
        >
          <RowComponent item={items[index]} />
        </Box>
      )}
    </CellMeasurer>
  )

  if (emptyText && items.length === 0)
    return (
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          fontStyle: 'italic',
        }}
      >
        {emptyText}
      </Box>
    )

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <List
            width={width}
            height={height}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={renderRow}
            rowCount={items.length}
            overscanRowCount={3}
          />
        )
      }}
    </AutoSizer>
  )
}

export default VirtualizedList
