// adapted from https://www.d3-graph-gallery.com/graph/histogram_basic.html

import { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import Box from '@mui/material/Box'
import { interpolateColor, toDateString } from '../utils'

const Histogram = ({ points, num_bins = 30, is_date = false }) => {
  const histRef = useRef(null)

  useEffect(() => {
    if (!points || points.length === 0) return

    const margin = { top: 10, right: 0, bottom: 35, left: 0 }
    const width = histRef.current.offsetWidth - margin.left - margin.right
    const height = histRef.current.offsetHeight - margin.top - margin.bottom

    const svg = d3
      .select(histRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const xMin = d3.min(points, (d) => d)
    const xMax = d3.max(points, (d) => d)
    const x = d3.scaleLinear().domain([xMin, xMax]).range([0, width])

    const vizG = svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3.axisBottom(x).tickValues(points.length === 1 ? [xMin] : [xMin, xMax])
      )

    vizG.selectAll('.domain').attr('stroke-width', 0)
    vizG.selectAll('.tick').attr('stroke-width', 0).style('font-size', 12)

    if (points.length === 1) {
      vizG.selectAll('.tick text').style('text-anchor', 'middle')
    } else {
      vizG.selectAll('.tick:first-of-type text').style('text-anchor', 'start')
      vizG.selectAll('.tick:last-of-type text').style('text-anchor', 'end')
    }

    if (is_date) vizG.selectAll('.tick text').text(toDateString)

    const histogram = d3
      .histogram()
      .value((d) => d)
      .domain(x.domain())
      .thresholds(x.ticks(num_bins))

    const bins = histogram(points)

    const y = d3.scaleLinear().range([height, 0])
    y.domain([0, d3.max(bins, (d) => d.length)])

    const barWidth = width / bins.length
    const barSpacing = 0

    svg
      .selectAll('rect')
      .data(bins)
      .enter()
      .append('rect')
      .attr('x', 1)
      .attr(
        'transform',
        (d, i) => `translate(${i * barWidth + 0.5 * barSpacing},${y(d.length)})`
      )
      .attr('width', (d) => barWidth - barSpacing)
      .attr('height', (d) => height - y(d.length))
      .style('fill', (d) => interpolateColor(d.x0, { min: xMin, max: xMax }))

    const histContainer = histRef.current

    return function cleanup() {
      histContainer.innerHTML = ''
    }
  }, [points, num_bins, is_date])

  return <Box ref={histRef} sx={{ height: '100%' }} />
}

const HistogramLoader = ({ points, num_bins = 30, is_date = false }) => {
  if (!points || points.length === 0)
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontStyle: 'italic',
          color: 'white',
          fontSize: '0.9em',
        }}
      >
        <Box>No Data</Box>
      </Box>
    )

  return <Histogram points={points} num_bins={num_bins} is_date={is_date} />
}

export default HistogramLoader
