import React, { useEffect, useRef, useState } from 'react'
import { useChatContext } from 'stream-chat-react'
import _debounce from 'lodash.debounce'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import './SelectUsers.css'
import UserList from '../UserList'

const SelectUsers = ({ selectedUsers, onChangeSelectedUsers }) => {
  const { client } = useChatContext()

  const [inputText, setInputText] = useState('')
  const [resultsOpen, setResultsOpen] = useState(false)
  const [searching, setSearching] = useState(false)
  const [users, setUsers] = useState([])

  const inputRef = useRef()

  const clearState = () => {
    setInputText('')
    setResultsOpen(false)
  }

  useEffect(() => {
    const clickListener = () => {
      if (resultsOpen) clearState()
    }

    document.addEventListener('click', clickListener)

    return () => document.removeEventListener('click', clickListener)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const findUsers = async () => {
    if (searching) return
    setSearching(true)

    const selectedUserIds = selectedUsers.map((user) => user.id)

    try {
      const response = await client.queryUsers(
        {
          id: { $nin: [client.userID, ...selectedUserIds] },
          $or: [
            { username: { $autocomplete: inputText } },
            { name: { $autocomplete: inputText } },
          ],
        },
        { id: 1 },
        { limit: 30 }
      )

      if (response.users.length) {
        setUsers(response.users)
      }

      setResultsOpen(true)
    } catch (error) {
      console.log({ error })
    }

    setSearching(false)
  }

  const findUsersDebounce = _debounce(findUsers, 100, {
    trailing: true,
  })

  useEffect(() => {
    if (inputText) {
      findUsersDebounce()
    }
  }, [inputText]) // eslint-disable-line react-hooks/exhaustive-deps

  const addUser = (u) => {
    const isAlreadyAdded = selectedUsers.find((user) => user.id === u.id)
    if (isAlreadyAdded) return

    onChangeSelectedUsers([...selectedUsers, u])
    setResultsOpen(false)
    setInputText('')
    inputRef.current.focus()
  }

  const removeUser = (user) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id)
    onChangeSelectedUsers(newUsers)
    inputRef.current.focus()
  }

  return (
    <Box sx={{ position: 'relative', p: 1 }}>
      <Box sx={{ '& input': { color: 'text.primary', padding: 1 } }}>
        {!!selectedUsers?.length && (
          <Box sx={{ marginBottom: 1, display: 'flex', flexWrap: 'wrap' }}>
            {selectedUsers.map((user) => (
              <Box
                sx={{
                  backgroundColor: 'background.default',
                  color: 'text.primary',
                }}
                className="messaging-create-channel__user"
                onClick={() => removeUser(user)}
                key={user.id}
              >
                <div className="messaging-create-channel__user-text">
                  {user.name}
                </div>
                <CloseIcon sx={{ fontSize: 16 }} />
              </Box>
            ))}
          </Box>
        )}
        <input
          style={{
            width: '100%',
            fontSize: 16,
            backgroundColor: 'transparent',
            outline: 0,
            border: 0,
          }}
          autoFocus
          ref={inputRef}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Type for suggestions"
          type="text"
        />
      </Box>
      {inputText && (
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(100% + 4px)',
            left: '4px',
            width: '250px',
            zIndex: 10000,
            maxHeight: 500,
            overflow: 'auto',
            borderRadius: '10px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'divider',
          }}
        >
          <UserList users={users} onSelectUser={addUser} />
        </Box>
      )}
    </Box>
  )
}

export default React.memo(SelectUsers)
