import { useModal } from 'store/selectors'
import useModalsActions from 'store/actions/modals'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import UserProfile from 'components/core/UserProfile'

const Profile = () => {
  const {
    isOpen,
    params: { userId },
  } = useModal('profile')
  const { closeModal } = useModalsActions()

  if (!isOpen) return null

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeModal('profile')}
      maxWidth="md"
      PaperProps={{
        style: {
          backgroundImage: 'none',
          backgroundColor: 'background.default',
          boxShadow: 'none',
          width: '600px',
          height: '90vh',
        },
      }}
    >
      <Box sx={{ p: 2, height: '100%' }}>
        <UserProfile userId={userId} />
      </Box>
    </Dialog>
  )
}

export default Profile
