import Box from '@mui/material/Box'
import DeleteButton from './DeleteButton'
import { toDateString, toTimeString } from 'services/utils'
import ViewButton from './ViewButton'
import { getRecipients } from './utils'

const ScheduledList = ({ broadcasts, deleting, onDelete }) => {
  return (
    <Box
      sx={{
        maxHeight: '600px',
        overflow: 'auto',
        fontSize: '0.9em',
        '& table': {
          borderCollapse: 'collapse',
          width: '100%',
        },
        '& th, td': {
          padding: 1,
          textAlign: 'left',
        },
        '& tbody tr:not(:last-of-type) td': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        },
        '& tbody tr td': {
          cursor: 'default',
        },
        '& th': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Scheduled For</th>
            <th>Sender</th>
            <th>Recipients</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {broadcasts.map(
            ({
              id,
              name,
              segment_type,
              scheduled_for,
              messages,
              num_user_ids,
            }) => (
              <tr key={id}>
                <td>{name}</td>
                <td>
                  <Box sx={{ whiteSpace: 'nowrap' }}>
                    {toDateString(scheduled_for) +
                      ' @ ' +
                      toTimeString(scheduled_for)}
                  </Box>
                </td>
                <td>{messages[0].custom_data.staff_member}</td>
                <td>{getRecipients(segment_type, num_user_ids)}</td>
                <td>
                  <ViewButton message={messages[0]} />
                </td>
                <td>
                  <DeleteButton
                    isLoading={deleting.includes(id)}
                    onClick={onDelete.bind(null, id)}
                  />
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {broadcasts.length === 0 && (
        <Box sx={{ fontStyle: 'italic', mt: 4 }}>
          There are no scheduled broadcasts.
        </Box>
      )}
    </Box>
  )
}

export default ScheduledList
