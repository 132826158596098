import { useEffect } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Loader from 'components/core/Loader'
import Header from './Header'
import Sidebar from './Sidebar'
import Main from './Main'
import Background from './Background'
import useNetworksActions from 'store/actions/networks'
import {
  useNetworksMeta,
  useNetworksError,
  useSelectedNetworkId,
  useNetworksSidebar,
} from 'store/selectors'

const Layout = () => (
  <>
    <Box sx={{ flex: 0, minHeight: 56 }}>
      <Header />
    </Box>
    <Divider />
    <Box sx={{ width: '100%', display: 'flex', flex: 1, overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flex: 1 }}>
        <Main />
      </Box>
    </Box>
  </>
)

const SelectNetwork = () => {
  const selectedNetworkId = useSelectedNetworkId()
  const sidebar = useNetworksSidebar()
  const { selectNetwork } = useNetworksActions()

  useEffect(() => {
    if (selectedNetworkId) return

    const id = sidebar.top[0] || sidebar.staff[0]
    if (id) selectNetwork(id)
    else console.error('No networks to select')
  }, [selectedNetworkId, selectNetwork, sidebar])

  if (!selectedNetworkId) return null

  return <Layout />
}

const LoadMeta = () => {
  const meta = useNetworksMeta()
  const error = useNetworksError()
  const { getNetworksReport } = useNetworksActions()

  useEffect(() => {
    if (!meta) getNetworksReport()
  }, [meta, getNetworksReport])

  if (!meta) return <Loader text="Loading Networks" error={error} />

  return <SelectNetwork />
}

const Networks = () => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      fontSize: '0.9em',
      position: 'relative',
      height: '100%',
      overflow: 'hidden',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
      }}
    >
      <Background />
    </Box>
    <LoadMeta />
  </Box>
)

export default Networks
