import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

const Publish = ({
  saveConfig,
  cancelConfigChanges,
  configChanged,
  isSavingConfig,
}) => (
  <Box sx={{ display: 'flex', mt: 4, justifyContent: 'space-between' }}>
    <Button
      variant="outlined"
      onClick={cancelConfigChanges}
      disabled={!configChanged}
    >
      Cancel Changes
    </Button>
    <LoadingButton
      variant="contained"
      onClick={saveConfig}
      disabled={!configChanged}
      loading={isSavingConfig}
    >
      Publish Changes
    </LoadingButton>
  </Box>
)

export default Publish
