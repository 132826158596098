import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/reclips`

export const getReclip = async (sourceId) => {
  try {
    const { data: reclip } = await axios.get(`${BASE_URL}/${sourceId}`)
    return reclip
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getReclipAudio = async (sourceId) => {
  try {
    const { data: audio } = await axios.get(`${BASE_URL}/${sourceId}/audio`)
    return audio
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const editReclip = async (shareCode, editorUserId) => {
  try {
    const { data: audio } = await axios.post(`${BASE_URL}/edit_from_share`, {
      share_code: shareCode,
      editor_user_id: editorUserId,
    })
    return audio
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
