import { useCallback } from 'react'
import { Avatar, useChatContext } from 'stream-chat-react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CreateIcon from '@mui/icons-material/AddCircle'
import BroadcastIcon from '@mui/icons-material/CellTower'
import WelcomeIcon from '@mui/icons-material/EmojiPeople'
import AttachmentsIcon from '@mui/icons-material/Attachment'
import { useNavigate, useLocation } from 'react-router-dom'
import { BROADCAST_CHANNEL_ID } from 'const'

const TABS = [
  {
    path: '/chat/create',
    title: 'Create Channel',
    Icon: CreateIcon,
  },
  {
    path: '/chat/attachments',
    title: 'Attachments',
    Icon: AttachmentsIcon,
  },
  {
    path: '/chat/broadcasts',
    title: 'Broadcast',
    Icon: BroadcastIcon,
  },
  {
    path: '/chat/welcome',
    title: 'Welcome',
    Icon: WelcomeIcon,
  },
]

const ChannelListHeader = () => {
  const chatContext = useChatContext()
  const { client, setActiveChannel, channel: activeChannel } = chatContext
  const { image, name } = client.user
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleClick = useCallback(
    (path) => {
      if (pathname.startsWith(path)) {
        if (activeChannel?.id === BROADCAST_CHANNEL_ID) setActiveChannel(null)
        navigate('/chat')
      } else {
        navigate(path)
      }
    },
    [activeChannel, setActiveChannel, pathname, navigate]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        width: '100%',
      }}
    >
      <Avatar image={image} name={name} size={30} />
      <Box sx={{ flex: 1, fontWeight: 700 }}>{name}</Box>
      {TABS.map(({ path, title, Icon }) => (
        <IconButton
          key={path}
          title={title}
          onClick={handleClick.bind(null, path)}
          sx={{ ml: 1 }}
        >
          <Icon />
        </IconButton>
      ))}
    </Box>
  )
}

export default ChannelListHeader
