import { useCallback } from 'react'
import Box from '@mui/material/Box'
import { Header, Text, TextArea } from './Bits'
import ModelSelector from './ModelSelector'
import ModelInfo from './ModelInfo'

const ConfigEditor = ({ config, updateConfig, models }) => {
  const handleChange = useCallback(
    (field, value) => {
      updateConfig(field, value)
    },
    [updateConfig]
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
        <Header text="GPT Model" mt={0} />
        <Box sx={{ ml: 4, width: 240, mb: 1 }}>
          <ModelSelector
            model={config.model}
            models={models}
            setModel={handleChange.bind(null, 'model')}
          />
        </Box>
      </Box>
      <ModelInfo model={models.find((m) => m.name === config.model)} />

      <Header text="Instructions" />
      <Text>
        This is for general information provided to GPT about its role in
        responding to the prompt.
      </Text>
      <TextArea
        value={config.instructions}
        onChange={handleChange.bind(null, 'instructions')}
        rows={6}
      />
      <Header text="Prompt Template" />
      <Text>
        This is the prompt send to GPT when asking for {config.type} for a
        reclip. The string "{config.placeholder}" is replaced with the actual
        transcript.
      </Text>
      <TextArea
        value={config.template}
        onChange={handleChange.bind(null, 'template')}
        rows={6}
      />
    </Box>
  )
}

export default ConfigEditor
