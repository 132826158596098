// adapted from https://www.d3-graph-gallery.com/graph/histogram_basic.html

import { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3'
import Box from '@mui/material/Box'

const Histogram = ({ points, num_bins = 30, is_date = false }) => {
  const histRef = useRef(null)
  const [stats, setStats] = useState(null)

  useEffect(() => {
    if (!points) return

    const margin = { top: 0, right: 0, bottom: 0, left: 0 }
    const width = histRef.current.offsetWidth - margin.left - margin.right
    const height = histRef.current.offsetHeight - margin.top - margin.bottom

    const svg = d3
      .select(histRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
    // .style('outline', '1px red solid')

    const now = Date.now()
    const xMin = now - 1000 * 60 * 60 * num_bins
    const xMax = now
    const x = d3.scaleLinear().domain([xMin, xMax]).range([0, width])

    const thresholds = []
    for (let i = 1; i < num_bins; i++) thresholds.push(xMin + i * 3600 * 1000)

    // const vizG = svg
    //   .append('g')
    //   .attr('transform', `translate(0,${height})`)
    //   .call(d3.axisBottom(x).tickValues([xMin, xMax])))

    // vizG.selectAll('.domain').attr('stroke-width', 0)
    // vizG.selectAll('.tick').attr('stroke-width', 0).style('font-size', 12)
    // vizG.selectAll('.tick:first-of-type text').style('text-anchor', 'start')
    // vizG.selectAll('.tick:last-of-type text').style('text-anchor', 'end')

    // if (is_date) vizG.selectAll('.tick text').text(toDateString)

    const histogram = d3
      .histogram()
      .value((d) => d)
      .domain(x.domain())
      .thresholds(thresholds)

    const bins = histogram(points)

    setStats({
      max: d3.max(bins, (d) => d.length),
      mean: Math.round(d3.mean(bins, (d) => d.length)),
    })

    const y = d3.scaleLinear().range([height, 0])
    y.domain([0, d3.max(bins, (d) => d.length)])
    // svg.append('g').call(d3.axisLeft(y))

    const barWidth = width / bins.length
    const barSpacing = 0

    svg
      .selectAll('rect')
      .data(bins)
      .enter()
      .append('rect')
      .attr('x', 1)
      .attr(
        'transform',
        (d, i) => `translate(${i * barWidth + 0.5 * barSpacing},${y(d.length)})`
      )
      .attr('width', (d) => barWidth - barSpacing)
      .attr('height', (d) => height - y(d.length))
      .style('fill', '#FFB800')
    // .style('fill', (d) => interpolateColor(d.x0, { min: xMin, max: xMax }))

    const histContainer = histRef.current

    return function cleanup() {
      histContainer.innerHTML = ''
    }
  }, [points, num_bins, is_date])

  return (
    <Box
      sx={{
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        visibility: stats ? 'visible' : 'hidden',
      }}
    >
      <Box ref={histRef} sx={{ height: '100px' }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          fontSize: '0.8em',
          mt: '4px',
          position: 'relative',
        }}
      >
        <Box sx={{ position: 'absolute', top: 0, left: 0, fontSize: '0.8em' }}>
          -{num_bins} hours
        </Box>
        <Box>
          <Box>clips per hour</Box>
          <Box>
            (max: <b>{stats?.max}</b>, avg: <b>{stats?.mean}</b>)
          </Box>
        </Box>
        <Box sx={{ position: 'absolute', top: 0, right: 0, fontSize: '0.8em' }}>
          now
        </Box>
      </Box>
    </Box>
  )
}

export default Histogram
