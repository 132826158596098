import { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import api from 'services/api'
import Divider from '@mui/material/Divider'
import SearchIcon from '@mui/icons-material/Search'
import Preview from './Preview'
import dayjs from 'dayjs'
import CopyToClipboardButton from 'components/core/CopyToClipboard'

const Reclip = ({ reclip }) => (
  <Box
    sx={{
      mb: 2,
      mr: 1,
      display: 'flex',
    }}
  >
    <Preview reclip={reclip} />
    <Box sx={{ flex: '1', ml: 2 }}>
      <Box sx={{ fontWeight: 700 }}>{reclip.title}</Box>
      <Box sx={{ fontWeight: 700, fontSize: '0.9em' }}>
        @{reclip.username} |{' '}
        {dayjs(reclip.created_at + 'Z').format('MMM D, YYYY')} |
        <Box sx={{ display: 'inline-block' }}>
          <CopyToClipboardButton data={reclip.source_id} title="copy id" />
        </Box>
      </Box>
      <Box sx={{ maxHeight: 125, overflow: 'auto', fontSize: '0.9em' }}>
        {reclip.text}
      </Box>
    </Box>
  </Box>
)

const SearchResults = ({ tagType, tag, filters }) => {
  const [results, setResults] = useState([])

  const searchTags = useCallback(
    async (tag) => {
      setResults([])
      const params = {
        tagType,
        tag,
        startDate: filters.startDate,
        endDate: filters.endDate,
        limit: 100,
      }
      const reclips = await api.openai.searchTags(params)
      setResults(reclips)
    },
    [filters, tagType]
  )

  useEffect(() => {
    if (tag) searchTags(tag)
  }, [tag, searchTags])

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'default',
      }}
    >
      <Box
        sx={{
          p: 2,
          fontSize: '1.25em',
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <SearchIcon sx={{ fontSize: 32 }} />
        <Box sx={{ ml: 2 }}>
          {tagType}: {tag}
        </Box>
      </Box>
      <Divider />
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'auto',
            p: 2,
          }}
        >
          {results.map((reclip, index) => (
            <Reclip key={index.toString()} reclip={reclip} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default SearchResults
