import { useState } from 'react'
import ChannelListInner from './ChannelListInner'
// import ChannelSearch from './ChannelSearch'
import ChannelFilter from './ChannelFilter'
import ChannelListHeader from './ChannelListHeader'
// import UserSearch from '../UserSearch'
import UserSearch from 'components/Main/Networks/Header/SearchBar'
import Box from '@mui/material/Box'
import useChatActions from 'store/actions/chat'
import { useChat } from 'store/selectors'

const ChannelListContainer = () => {
  // const [searchTerm, setSearchTerm] = useState('')
  const [filterType, setFilterType] = useState('all')
  const { setSelectedUser } = useChatActions()
  const { selectedUser } = useChat()

  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          height: '75px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        }}
      >
        <ChannelListHeader />
      </Box>
      <Box
        sx={{
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
          position: 'relative',
          fontSize: '0.95em',
        }}
      >
        {/* <ChannelSearch
          searchTerm={searchTerm}
          onChangeSearchTerm={setSearchTerm}
        /> */}
        <UserSearch
          onSelectUser={setSelectedUser}
          selectedUser={selectedUser}
        />
      </Box>
      {!selectedUser && (
        <Box
          sx={{
            height: '40px',
            borderBottomWidth: 1,
            borderBottomColor: 'divider',
            borderBottomStyle: 'solid',
          }}
        >
          <ChannelFilter
            filterType={filterType}
            setFilterType={setFilterType}
          />
        </Box>
      )}

      <Box sx={{ height: 'calc(100% - 175px)' }}>
        <ChannelListInner
          // searchTerm={searchTerm}
          filterType={filterType}
          selectedUser={selectedUser}
        />
      </Box>
    </Box>
  )
}

export default ChannelListContainer
