import { useEffect, useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import CircularProgress from '@mui/material/CircularProgress'
import api from 'services/api'
import Loader from 'components/core/Loader'
import AddUser from './AddUser'

function sortedUsers(users) {
  users.sort((a, b) => {
    if (a.full_name?.toLowerCase() > b.full_name?.toLowerCase()) return 1
    if (b.full_name?.toLowerCase() > a.full_name?.toLowerCase()) return -1
    if (a.phone_number || 0 > b.phone_number || 0) return 1
    return -1
  })
  return users
}

const Users = () => {
  const [users, setUsers] = useState(null)
  const [error, setError] = useState(null)
  const [isAdding, setIsAdding] = useState(false)
  const [deleting, setDeleting] = useState([])

  useEffect(() => {
    api.staff
      .listStaffMembers()
      .then((users) => {
        setUsers(sortedUsers(users))
      })
      .catch((error) => setError(error.message))
  }, [])

  const onCreate = useCallback((newUser) => {
    setUsers((users) =>
      sortedUsers([newUser, ...users.filter((user) => user.id !== newUser.id)])
    )
  }, [])

  const onDelete = useCallback((userId) => {
    setDeleting((deleting) => [...deleting, userId])
    api.staff.deleteStaffMember(userId).then(() => {
      setUsers((users) => users.filter((user) => user.id !== userId))
      setDeleting((deleting) => deleting.filter((id) => id !== userId))
    })
  }, [])

  if (!users) return <Loader text="Loading Reclip Phones" error={error} />

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1200px',
        padding: 8,
        margin: '0 auto 100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        <Box sx={{ fontSize: '1.4em', fontWeight: 700 }}>
          Reclip Staff Phones
        </Box>
        <Button
          variant="contained"
          disabled={isAdding}
          onClick={() => setIsAdding(true)}
        >
          Add Phone
        </Button>
      </Box>
      {isAdding && (
        <AddUser onCreate={onCreate} onClose={() => setIsAdding(false)} />
      )}
      <Box
        sx={{
          mt: 3,
          '& table': {
            width: '100%',
            borderCollapse: 'collapse',
            '& th': {
              textAlign: 'left',
            },
            '& tbody td': {
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: 'divider',
              height: '46px',
            },
            '& thead th': {
              py: 2,
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: 'divider',
            },
            '& td:last-of-type': {
              textAlign: 'center',
              width: '50px',
              verticalAlign: 'middle',
            },
          },
        }}
      >
        <table>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Username</th>
              <th>Phone Number</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.full_name}</td>
                <td>{user.username}</td>
                <td>{user.phone_number}</td>
                <td>
                  {deleting.includes(user.user_id) ? (
                    <CircularProgress size={14} />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          '&:hover': {
                            color: 'primary.main',
                            cursor: 'pointer',
                          },
                        }}
                        onClick={onDelete.bind(null, user.id)}
                      />
                    </Box>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box sx={{ mt: 6, pb: 8, fontSize: '0.9em', fontStyle: 'italic' }}>
        <Box>The accounts above are marked "is_staff" in the database.</Box>
        <Box sx={{ mt: 1 }}>
          Any phone number on this list can be used to log into this site.
        </Box>
      </Box>
    </Box>
  )
}

export default Users
