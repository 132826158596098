import {
  useEffect,
  useRef,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { mapbox } from 'services/mapbox'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import createCircle from './createCircle'

const EMPTY = {
  type: 'FeatureCollection',
  features: [],
}

const Map = forwardRef(
  (
    {
      schools,
      onSelectSchool,
      selectedSchool,
      datasets,
      onSelectLocation,
      location,
      hoveredSchool,
    },
    ref
  ) => {
    const [map, setMap] = useState(null)
    const mapContainer = useRef(null)
    const { openModal } = useModalsActions()

    const colors = useMemo(() => {
      if (!datasets) return null
      return datasets.reduce((p, c) => {
        p[c.id] = c.color
        return p
      }, {})
    }, [datasets])

    const geoJson = useMemo(() => {
      if (!schools || !colors) return null
      return {
        type: 'FeatureCollection',
        features: schools.map((school) => ({
          type: 'Feature',
          properties: {
            schoolId: school.id,
            color: colors[school.dataset_id],
          },
          geometry: school.location,
        })),
      }
    }, [schools, colors])

    useEffect(() => {
      const map = new mapbox.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [-99, 39],
        zoom: 4,
      })

      map.on('load', () => {
        map.addSource('schools', {
          type: 'geojson',
          data: null,
        })

        map.addLayer({
          id: 'schools',
          type: 'circle',
          source: 'schools',
          paint: {
            'circle-radius': {
              base: 5,
              stops: [
                [5, 3],
                [8, 6],
              ],
            },
            'circle-color': ['get', 'color'],
            'circle-opacity': 0.8,
          },
        })

        map.addSource('location-radius', {
          type: 'geojson',
          data: null,
        })

        map.addLayer({
          id: 'location-radius',
          type: 'line',
          source: 'location-radius',
          paint: {
            'line-color': 'white',
            'line-width': 2,
          },
        })

        map.addSource('location-center', {
          type: 'geojson',
          data: null,
        })

        map.addLayer({
          id: 'location-center',
          type: 'circle',
          source: 'location-center',
          paint: {
            'circle-radius': {
              base: 5,
              stops: [
                [5, 5],
                [8, 10],
              ],
            },
            'circle-color': 'white',
            // 'circle-opacity': 0.8,
          },
        })

        map.addSource('selected-school', {
          type: 'geojson',
          data: null,
        })

        map.addLayer({
          id: 'selected-school',
          type: 'circle',
          source: 'selected-school',
          paint: {
            'circle-radius': {
              base: 10,
              stops: [
                [5, 10],
                [8, 20],
              ],
            },
            'circle-color': ['get', 'color'],
            // 'circle-opacity': 0.8,
          },
        })

        map.addSource('hovered-school', {
          type: 'geojson',
          data: null,
        })

        map.addLayer({
          id: 'hovered-school',
          type: 'circle',
          source: 'hovered-school',
          paint: {
            'circle-radius': {
              base: 10,
              stops: [
                [5, 10],
                [8, 20],
              ],
            },
            'circle-color': ['get', 'color'],
            // 'circle-opacity': 0.8,
          },
        })

        setMap(map)
      })

      map.on('click', (e) => {
        // ignore clicks on schools
        const features = map.queryRenderedFeatures(e.point)
        if (features.filter((f) => f.layer.source === 'schools').length > 0)
          return

        onSelectLocation(e.lngLat)
      })

      return () => {
        // map.removeLayer('schools')
        // map.removeSource('schools')
        // map.removeLayer('location-radius')
        // map.removeSource('location-radius')
        // map.removeLayer('location-center')
        // map.removeSource('location-center')
        // map.removeLayer('hovered-school')
        // map.removeSource('hovered-school')
        // map.remove()
      }
    }, [onSelectLocation])

    useEffect(() => {
      if (!map || !geoJson) return

      try {
        map.getSource('schools').setData(geoJson)
      } catch (e) {}
    }, [map, geoJson])

    useEffect(() => {
      if (!map || !datasets) return

      map.on('mouseenter', 'schools', () => {
        map.getCanvas().style.cursor = 'pointer'
      })

      map.on('mouseleave', 'schools', () => {
        map.getCanvas().style.cursor = ''
      })

      map.on('click', 'schools', (e) => {
        const feature = e.features.find((f) => f.layer.id === 'schools')

        let { schoolId } = feature.properties
        onSelectSchool(schoolId)
      })
    }, [map, datasets, openModal, onSelectSchool])

    useEffect(() => {
      if (!map) return

      if (!location || !location.center)
        map.getSource('location-center').setData(EMPTY)

      if (!location || !location.center || location.radius === null)
        map.getSource('location-radius').setData(EMPTY)

      if (location && location.center !== null)
        map.getSource('location-center').setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [location.center.lng, location.center.lat],
              },
            },
          ],
        })

      if (location && location.center && location.radius !== null)
        map
          .getSource('location-radius')
          .setData(createCircle(location.center, location.radius * 1.60934))
    }, [map, location])

    useEffect(() => {
      if (!map) return

      if (!hoveredSchool) {
        map.getSource('hovered-school').setData(EMPTY)
        return
      }

      map.getSource('hovered-school').setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              schoolId: hoveredSchool.id,
              color: colors[hoveredSchool.dataset_id],
            },
            geometry: {
              type: 'Point',
              coordinates: hoveredSchool.location.coordinates,
            },
          },
        ],
      })
    }, [map, colors, hoveredSchool])

    useEffect(() => {
      if (!map) return

      if (!selectedSchool) {
        map.getSource('selected-school').setData(EMPTY)
        return
      }

      map.getSource('selected-school').setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              schoolId: selectedSchool.id,
              color: colors[selectedSchool.dataset_id],
            },
            geometry: {
              type: 'Point',
              coordinates: selectedSchool.location.coordinates,
            },
          },
        ],
      })
    }, [map, selectedSchool, colors])

    useImperativeHandle(
      ref,
      () => {
        return {
          flyTo: (center) => {
            map.flyTo({
              center,
              zoom: 12,
              speed: 2.0,
            })
          },
        }
      },
      [map]
    )

    return <Box ref={mapContainer} sx={{ width: '100%', height: '100%' }} />
  }
)

export default Map
