import Box from '@mui/material/Box'

const ReclipPreview = ({ reclip }) => {
  const color1 = reclip.background_color1
  const color2 = reclip.background_color2
  const hasCoverPhoto = !!reclip.cover_photo_url

  return (
    <Box>
      <Box
        sx={{
          width: 120,
          height: 180,
          background: hasCoverPhoto
            ? undefined
            : `linear-gradient(to top, ${color1}, ${color2})`,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 3,
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 700,
          position: 'relative',
        }}
      >
        {hasCoverPhoto && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              borderRadius: 2,
              backgroundImage: `url(${reclip.cover_photo_url})`,
              backgroundSize: 'cover',
            }}
          />
        )}
      </Box>
      {/* <Box
        sx={{
          mt: 1,
          textAlign: 'center',
          fontSize: '0.9em',
          fontStyle: 'italic',
        }}
      >
        @{reclip.username}
      </Box> */}
    </Box>
  )
}

export default ReclipPreview
