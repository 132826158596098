import { useMemo } from 'react'
import Box from '@mui/material/Box'
import LinkButton from 'components/core/LinkButton'
import CloseIcon from '@mui/icons-material/Close'

const SelectedSchool = ({ school, datasets, onClose }) => {
  const fields = useMemo(() => {
    if (school === null) return null

    console.log(school)

    return [
      ['age_range', school.age_range],
      ['grade_range', school.grade_range],
      ['enrollment', school.enrollment],
      [
        'school website',
        (() => {
          if (school.website === null) return null
          return <LinkButton url={school.website} />
        })(),
      ],
      [
        'gov website',
        (() => {
          if (school.gov_website === null) return null
          return <LinkButton url={school.gov_website} />
        })(),
      ],
      ['reclip users', 0],
    ]
  }, [school])

  if (school === null) return null

  return (
    <Box
      sx={{
        borderRadius: 2,
        position: 'relative',
        backgroundColor: 'background.default',
        p: 2,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: datasets.find((d) => d.id === school.dataset_id)
            .color,
        }}
      />
      <CloseIcon
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          cursor: 'pointer',
          fontSize: 16,
          '&:hover': {
            opacity: 0.6,
          },
        }}
        onClick={onClose}
      />
      <Box
        sx={{
          textAlign: 'center',
          fontSize: '1.2em',
          lineHeight: '1.3em',
          fontWeight: 700,
          mb: '0.25em',
          mx: 5,
          mt: 1,
        }}
      >
        {school.name}
      </Box>
      <Box sx={{ textAlign: 'center', mb: '0.25em', mx: 2 }}>
        {school.address ? `${school.address}, ` : ''}
        {school.city}, {school.state} {school.country}
      </Box>
      <Box
        sx={{
          fontSize: '0.8em',
          fontStyle: 'italic',
          textAlign: 'center',
          mb: 2,
          mt: 0.25,
        }}
      >
        {school.id}
      </Box>
      <Box sx={{ px: 2, mb: 1 }}>
        {fields
          .filter((field) => field[1] !== null)
          .map((field) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              key={field[0]}
            >
              <Box sx={{ fontWeight: 700 }}>{field[0]}:</Box>
              <Box>{field[1]}</Box>
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default SelectedSchool
