import Box from '@mui/material/Box'
import { toDateString } from 'components/Main/Networks/utils'

const Line = ({ name, val }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.0em',
      justifyContent: 'space-between',
    }}
  >
    <Box sx={{ mr: 1 }}>{name}:</Box>
    <Box>{val}</Box>
  </Box>
)

const ChatHistory = ({ history }) => {
  return (
    <Box
      sx={{
        mx: 2,
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        overflow: 'auto',
      }}
    >
      {!history ? (
        <Box sx={{ fontStyle: 'italic' }}>No messages.</Box>
      ) : (
        <Box sx={{ width: '300px', lineHeight: '24px' }}>
          <Line
            name="First message"
            val={toDateString(history.first_message)}
          />
          <Line
            name="Latest message"
            val={toDateString(history.last_message)}
          />
          <Line name="Total messages" val={history.messages.length} />
          <Box sx={{ ml: 2 }}>
            {Object.keys(history.message_counts).map((messageType) => (
              <Line
                key={messageType}
                name={`● ${messageType}`}
                val={history.message_counts[messageType]}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ChatHistory
