export function isReclipMessage(message) {
  return (
    message.attachments.length > 0 &&
    message.attachments[0].isReclipPlaybackAttachment
  )
}

export async function getReclip(message) {
  if (!isReclipMessage(message)) return null

  return fetch(message.attachments[1].asset_url)
    .then((res) => res.json())
    .then((data) => ({
      ...data,
      audio: {
        asset_url: message.attachments[0].asset_url,
        mime_tyle: message.attachments[0].mime_type,
      },
      message: {
        username: message.user.username,
        created_at: message.created_at,
      },
    }))
}
