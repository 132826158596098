import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import SetMode from './SetMode'
import DateTimePicker from './DateTimePicker'
import * as dayjs from 'dayjs'

const modes = [
  {
    type: 'now',
    label: 'Send Now',
  },
  {
    type: 'later',
    label: 'Send Later',
  },
]

const GetSchedule = ({ scheduledFor, setScheduledFor }) => {
  const [mode, setMode] = useState('')

  useEffect(() => {
    switch (mode) {
      case '':
        return
      case 'now':
        return setScheduledFor(null)
      case 'later':
        return setScheduledFor(dayjs().add(1, 'day'))
      default:
        return
    }
  }, [mode, setScheduledFor])

  return (
    <Box>
      <SetMode modes={modes} mode={mode} setMode={setMode} />
      {!!scheduledFor && (
        <Box sx={{ mt: '10px' }}>
          <DateTimePicker date={scheduledFor} setDate={setScheduledFor} />
        </Box>
      )}
    </Box>
  )
}

export default GetSchedule
