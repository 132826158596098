import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useChatContext } from 'stream-chat-react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import ChannelPreview from '../ChannelPreview'
import { NoChannels, ChannelLoader } from './Helpers'
import { getChannel, getChannels, markChannelAsRead } from '../utils'
import { BROADCAST_CHANNEL_ID } from 'const'

const ChannelListInner = ({ searchTerm, filterType, selectedUser }) => {
  const { client, setActiveChannel, channel: activeChannel } = useChatContext()
  const navigate = useNavigate()

  // STATE

  const [searchParams, setSearchParams] = useState({
    searchTerm,
    filterType,
    selectedUser,
    offset: 0,
  })

  const [searchResults, setSearchResults] = useState({
    channels: [],
    hasMore: true,
    error: null,
  })

  // CALLBACKS

  const searchChannels = useCallback(
    async ({ searchTerm, filterType, selectedUser, offset }) => {
      try {
        const { channels, hasMore } = await getChannels(
          client,
          searchTerm,
          filterType,
          selectedUser,
          offset
        )

        setSearchResults((results) => ({
          channels:
            offset === 0 ? channels : [...results.channels, ...channels],
          hasMore,
          error: null,
        }))
      } catch (e) {
        setSearchResults((results) => ({
          ...results,
          error: e.message,
        }))
      }
    },
    [client]
  )

  const retrySearch = useCallback(() => {
    setSearchResults((results) => ({
      ...results,
      error: null,
    }))

    setTimeout(() => {
      setSearchParams((params) => ({ ...params }))
    }, 0)
  }, [])

  const updateOffset = useCallback(() => {
    setSearchParams((params) => ({
      ...params,
      offset: searchResults.channels.length,
    }))
  }, [searchResults])

  const prependChannel = useCallback((channel) => {
    setSearchResults((results) => ({
      ...results,
      channels: [
        channel,
        ...results.channels.filter((c) => c.cid !== channel.cid),
      ],
    }))
  }, [])

  const replaceChannel = useCallback((channel) => {
    setSearchResults((results) => ({
      ...results,
      channels: results.channels.map((c) =>
        c.cid === channel.cid ? channel : c
      ),
    }))
  }, [])

  const handlePreviewClick = useCallback(
    async (channel) => {
      await markChannelAsRead(channel)
      navigate('/chat')
      setActiveChannel(channel)
    },
    [navigate, setActiveChannel]
  )

  const handleFaveClick = useCallback(async (channel, event) => {
    event.stopPropagation()
    if (channel.data.team_reclip_faves) {
      await channel.updatePartial({ unset: ['team_reclip_faves'] })
    } else {
      await channel.updatePartial({ set: { team_reclip_faves: true } })
    }
  }, [])

  // EFFECTS

  // load channels whenever search params change
  useEffect(() => {
    searchChannels(searchParams)
    if (searchParams.offset === 0) setActiveChannel(null)
  }, [searchChannels, searchParams, setActiveChannel])

  // set active channel if there's a selectedUser
  useEffect(() => {
    if (selectedUser && searchResults.channels.length === 1)
      setActiveChannel(searchResults.channels[0])
  }, [searchResults, selectedUser, setActiveChannel])

  // update the search params whenever the searchTerm or filterType changes
  useEffect(() => {
    if (
      searchParams.searchTerm === searchTerm &&
      searchParams.filterType === filterType &&
      searchParams.selectedUser === selectedUser
    )
      return

    setSearchResults({
      channels: [],
      hasMore: true,
      error: null,
    })

    setSearchParams({
      searchTerm,
      filterType,
      selectedUser,
      offset: 0,
    })
  }, [searchParams, searchTerm, filterType, selectedUser])

  // handle channel events
  useEffect(() => {
    const handleEvent = async (event) => {
      const eventsToProcess = [
        'message.new',
        'notification.message_new',
        'message.read',
        'channel.updated',
      ]

      // skip events we don't care about
      if (!eventsToProcess.includes(event.type)) return

      // ignore all events in the broadcast channel
      if (event.channel_id === BROADCAST_CHANNEL_ID) return

      // skip broadcast and welcome messages
      if (
        ['notification.message_new', 'message.new'].includes(event.type) &&
        (event.message.is_welcome_message || event.message.is_broadcast_message)
      ) {
        return
      }

      try {
        var eventChannel = await getChannel(client, event.cid)
      } catch (e) {
        console.log('error loading channel', event.cid, e)
        return
      }

      switch (event.type) {
        case 'message.new':
        case 'notification.message_new':
          if (
            searchParams.filterType === 'unread' &&
            !eventChannel.data.team_reclip_unread &&
            !(eventChannel.cid === activeChannel?.cid)
          )
            replaceChannel(eventChannel)
          else if (!selectedUser) prependChannel(eventChannel)

          break

        case 'message.read':
        case 'channel.updated':
          replaceChannel(eventChannel)

          break

        default:
          return
      }
    }

    const listener = client.on(handleEvent)
    return () => listener.unsubscribe()
  }, [
    client,
    searchParams,
    prependChannel,
    replaceChannel,
    activeChannel,
    selectedUser,
  ])

  if (searchResults.channels.length === 0 && !searchResults.hasMore)
    return <NoChannels filterType={searchParams.filterType} />

  return (
    <Box id="scroller" sx={{ height: '100%', overflow: 'auto' }}>
      <InfiniteScroll
        scrollableTarget="scroller"
        dataLength={searchResults.channels.length}
        hasMore={searchResults.hasMore}
        next={updateOffset}
        loader={
          <ChannelLoader error={searchResults.error} onRetry={retrySearch} />
        }
      >
        {searchResults.channels.map((channel) => (
          <ChannelPreview
            key={channel.cid}
            channel={channel}
            onClick={handlePreviewClick.bind(null, channel)}
            onFaveClick={handleFaveClick.bind(null, channel)}
            userID={client.userID}
            isActiveChannel={channel?.id === activeChannel?.id}
          />
        ))}
      </InfiniteScroll>
    </Box>
  )
}

export default ChannelListInner
