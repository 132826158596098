import { useEffect, useMemo, useState } from 'react'
import useDeepgramActions from 'store/actions/deepgram'
import { useDeepgram } from 'store/selectors'
import Loader from 'components/core/Loader'
import Box from '@mui/material/Box'
import Stats from './Stats'
import Histogram from './Histogram'
import ScatterPlot from './ScatterPlot'
import RequestsTable from './RequestsTable'
import Pulse from './Pulse'

const [TABLE, HISTOGRAM, SCATTERPLOT] = ['table', 'histogram', 'scatterplot']

const Deepgram = () => {
  const { data: requests, error } = useDeepgram()
  const { getDeepgramData, updateDeepgramData } = useDeepgramActions()
  const [isLoading, setIsLoading] = useState(true)
  const [limit, setLimit] = useState(100)
  const [chartType, setChartType] = useState(TABLE)

  useEffect(() => {
    setIsLoading(true)
    getDeepgramData({ limit }).then(() => setIsLoading(false))
    const timer = setInterval(updateDeepgramData, 5000)
    return () => clearInterval(timer)
  }, [limit, getDeepgramData, updateDeepgramData])

  const content = useMemo(() => {
    if (isLoading) return <Loader text="Loading Deepgram Data" error={error} />

    switch (chartType) {
      case TABLE:
        return <RequestsTable requests={requests} />
      case HISTOGRAM:
        return (
          <Histogram
            points={requests.map((r) => r.processing_time)}
            num_bins={50}
            x_label="Processing Time (seconds)"
            y_label="Request Count"
          />
        )
      case SCATTERPLOT:
        return (
          <ScatterPlot
            points={requests.map(({ audio_duration, processing_time }) => ({
              x: audio_duration,
              y: processing_time,
            }))}
          />
        )
      default:
        return null
    }
  }, [isLoading, chartType, requests, error])

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100% - 200px)',
        margin: '100px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        <Box sx={{ fontSize: '24px', fontWeight: 700, mr: 2 }}>
          Recent Deepgram Requests
        </Box>
        <Pulse />
      </Box>
      <Box
        sx={{
          mb: 6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 0,
          width: '100%',
        }}
      >
        <Box>{requests && <Stats requests={requests} />}</Box>
        <Box
          sx={{
            textAlign: 'right',
            '& select': {
              backgroundColor: 'transparent',
              border: 0,
              outline: 0,
              color: 'text.primary',
              width: '200px',
              fontSize: '16px',
            },
            '& .label': {
              mr: 2,
              whiteSpace: 'nowrap',
              width: '200px',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box className="label">query size:</Box>
            <select value={limit} onChange={(e) => setLimit(e.target.value)}>
              <option key={0} value={10}>
                10 requests
              </option>
              <option key={1} value={100}>
                100 requests
              </option>
              <option key={2} value={1000}>
                1000 requests
              </option>
            </select>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box className="label">display:</Box>
            <select
              value={chartType}
              onChange={(e) => setChartType(e.target.value)}
            >
              <option key={0} value={TABLE}>
                table
              </option>
              <option key={1} value={HISTOGRAM}>
                histogram
              </option>
              {/* <option key={2} value={SCATTERPLOT}>
                Scatterplot
              </option> */}
            </select>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 1, width: '100%', position: 'relative' }}>{content}</Box>
    </Box>
  )
}

export default Deepgram
