import React, { createContext, useContext, useState } from 'react'
import Box from '@mui/material/Box'

const tooltipContext = createContext()

export const TooltipProvider = ({ children }) => {
  const [tooltip, setTooltip] = useState(null)

  return (
    <>
      {tooltip && (
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: (theme) => theme.palette.background.paper,
            color: (theme) => theme.palette.text.primary,
            borderRadius: '5px',
            transform: 'translate(-50%, -120%)',
            pointerEvents: 'none',
            zIndex: 1000000,
            borderWidth: 1,
            borderColor: 'divider',
            borderStyle: 'solid',
          }}
          style={{ left: tooltip.x, top: tooltip.y }}
        >
          {tooltip.content}
        </Box>
      )}
      <tooltipContext.Provider value={setTooltip}>
        {children}
      </tooltipContext.Provider>
    </>
  )
}

export const useTooltip = () => {
  return useContext(tooltipContext)
}
