import { useEffect, useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import Header from './Header'
import Table from './Table'
import api from 'services/api'
import LoadButton from './LoadButton'
import LoadingButton from '@mui/lab/LoadingButton'
import dayjs from 'dayjs'
import ShareDateFilter from './ShareDateFilter'
import ShareDayFilter from './ShareDayFilter'

const INITIAL_FILTERS = {
  share_created_at_min: dayjs().utc().subtract(7, 'day').startOf('day'),
  share_created_at_max: dayjs().utc().endOf('day'),
  share_day_min: null,
  share_day_max: null,
}

const SharedReclips = () => {
  const [shares, setShares] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isExporting, setIsExporting] = useState(false)
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const loadShares = useCallback(async (filters) => {
    setIsLoading(true)
    const shares = await api.chat.getSharedReclips(filters)
    setShares(shares)
    setIsLoading(false)
  }, [])

  const exportShares = useCallback(async () => {
    setIsExporting(true)
    const url = await api.chat.exportSharedReclips(filters)
    setIsExporting(false)
    window.open(url, '_blank')
  }, [filters])

  const updateFilters = useCallback((field, value) => {
    setFilters((f) => ({
      ...f,
      [field]: value,
    }))
  }, [])

  useEffect(() => {
    loadShares(filters)
  }, [loadShares, filters])

  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }}>
      <Header />
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 6,
          px: 6,
          position: 'relative',
          height: 'calc(100% - 60px)',
          display: 'flex',
          justifyContent: 'center',
          minWidth: 1050,
        }}
      >
        <Box sx={{ width: '1400px', maxWidth: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              mb: 6,
            }}
          >
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 2, fontWeight: 700 }}>Shared between</Box>
                <Box sx={{ mr: 2 }}>
                  <ShareDateFilter
                    value={filters.share_created_at_min}
                    setValue={updateFilters.bind(null, 'share_created_at_min')}
                  />
                </Box>
                <Box sx={{ mr: 2, fontWeight: 700 }}>and</Box>
                <ShareDateFilter
                  value={filters.share_created_at_max}
                  setValue={updateFilters.bind(null, 'share_created_at_max')}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ mr: 2, fontWeight: 700 }}>Shared by</Box>
                  <ShareDayFilter filters={filters} setFilters={setFilters} />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LoadButton
                isLoading={false}
                onClick={loadShares.bind(null, filters)}
              />
              <LoadingButton
                sx={{ ml: 3 }}
                size="small"
                variant="contained"
                onClick={exportShares}
                loading={isExporting}
              >
                Export to Google Sheet
              </LoadingButton>
            </Box>
          </Box>
          <Table shares={shares} isLoading={isLoading} />
        </Box>
      </Box>
    </Box>
  )
}

export default SharedReclips
