import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/map`

export const getRecentReclips = async (days) => {
  try {
    const url = `${BASE_URL}/reclips?days=${days}`
    const { data: reclips } = await axios.get(url)
    return reclips
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getReclipDetail = async (reclipId) => {
  try {
    const { data: reclips } = await axios.get(`${BASE_URL}/reclips/${reclipId}`)
    return reclips
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getUser = async (id) => {
  try {
    const { data: user } = await axios.get(BASE_URL)
    return user
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getProfile = async (userId) => {
  try {
    const { data: profile } = await axios.get(`${BASE_URL}/profiles/${userId}`)
    return profile
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
