import axios from 'axios'
import { ADMIN_API_URL } from 'const'
// import { delay } from 'services/utils'

const BASE_URL = `${ADMIN_API_URL}/chat`

export const getChat = async () => {
  try {
    const { data: chat } = await axios.get(BASE_URL)
    return chat
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getChatUserIds = async () => {
  try {
    const { data: userIds } = await axios.get(BASE_URL + '/chat/user_ids')
    return userIds
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getWelcomeMessages = async () => {
  try {
    const { data: messages } = await axios.get(BASE_URL + '/welcome')
    return messages
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const postToFeedback = async ({ title, message }) => {
  try {
    return await axios.post(BASE_URL + '/feedback', { title, message })
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getSharedReclips = async (filters) => {
  try {
    const { data: reclips } = await axios.post(
      BASE_URL + '/shared_reclips',
      filters
    )
    return reclips
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const exportSharedReclips = async (filters) => {
  try {
    const { data } = await axios.post(
      BASE_URL + '/shared_reclips/export',
      filters
    )
    return data.url
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
