import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import InputAdornment from '@mui/material/InputAdornment'
import api from 'services/api'

const AddUser = ({ onCreate, onClose }) => {
  const [phone, setPhone] = useState('')
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [isCreating, setIsCreating] = useState(false)

  const createStaffMember = useCallback(async () => {
    try {
      setIsCreating(true)
      setError(null)
      setMessage(null)

      const cleanPhone = phone.startsWith('+') ? phone : '+' + phone
      const newUser = await api.staff.createStaffMember(cleanPhone)

      setMessage(`Success: ${cleanPhone} now has staff permissions.`)
      setPhone('')
      onCreate(newUser)
    } catch (e) {
      console.log('ERROR', e)
      setError(e.message)
    } finally {
      setIsCreating(false)
    }
  }, [phone, onCreate])

  return (
    <Box
      sx={{
        p: 4,
        borderWidth: 1,
        borderColor: 'divider',
        borderStyle: 'solid',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          label="phone number"
          placeholder="enter phone number with country code and area code"
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{ backgroundColor: 'background.paper' }}
                position="start"
              >
                +
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 4,
        }}
      >
        <Box
          sx={{
            flex: 1,
            fontSize: !!error ? '0.8em' : undefined,
            color: !!error ? 'error.main' : 'success.main',
            lineHeight: !!error ? '1.2em' : undefined,
            pr: 4,
          }}
        >
          {error || message}
        </Box>
        <LoadingButton
          disabled={!phone}
          sx={{ mr: 4 }}
          variant="contained"
          onClick={createStaffMember}
          loading={isCreating}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Box>
  )
}

export default AddUser
