import React, { useEffect } from 'react'
import * as d3 from 'd3'
import { useTooltip } from '../Tooltip'
import Node from '../Node'
import Link from '../Link'
import useNetworksActions from 'store/actions/networks'
// import api from 'services/api'

export default function useAddMouse({ node, link, simulation, inDragMode }) {
  const setTooltip = useTooltip()
  const { hoverOnNode, hoverOnLink, selectNode, selectLink } =
    useNetworksActions()
  // const open = useCallback((file) => dispatch(openFile(file)), [dispatch])

  useEffect(() => {
    if (!simulation || !node || inDragMode) return

    //// DRAGGING ////

    const drag = (simulation) => {
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart()
        d.fx = d.x
        d.fy = d.y
        setTooltip(null)
      }

      function dragged(event, d) {
        d.fx = event.x
        d.fy = event.y
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0)
        d.fx = null
        d.fy = null
      }

      return d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    }

    node.call(drag(simulation))

    //// TOOLTIP ////

    node
      .on('mouseenter', (e, d) => hoverOnNode(d.id))
      .on('mousemove', (e, d) => {
        setTooltip({
          x: e.offsetX,
          y: e.offsetY,
          content: <Node node={d} />,
        })
      })
      .on('mouseout', () => {
        hoverOnNode(null)
        setTooltip(null)
      })
      .on('click', (e, d) => {
        selectNode(d.id)
      })

    link
      .filter((d) => !!d.friendship)
      .on('mouseenter', (e, d) => hoverOnLink(d.index))
      .on('mousemove', (e, d) => {
        setTooltip({
          x: e.offsetX,
          y: e.offsetY,
          content: <Link link={d} />,
        })
      })
      .on('mouseout', () => {
        hoverOnLink(null)
        setTooltip(null)
      })
      .on('click', (e, d) => {
        if (!d.friendship) return
        selectLink(d.id)
      })

    //// OPEN FILE ////

    // node.on('click', (e, d) => {
    //   if (!d.children) open(d.data)
    // })

    return () => {
      node.on('mousedown.drag', null)
      node.on('mousemove', null)
      node.on('mouseout', null)
      node.on('click', null)
      setTooltip(null)
    }
  }, [
    simulation,
    node,
    link,
    setTooltip,
    inDragMode,
    hoverOnNode,
    hoverOnLink,
    selectNode,
    selectLink,
  ])
}
