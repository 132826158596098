import api from 'services/api'
import useActions from 'hooks/useActions'
import { setTokens, deleteTokens } from 'services/auth'

export const types = {
  GET_USER_SUCCESS: 'auth/GET_USER_SUCCESS',
  GET_USER_FAILURE: 'auth/GET_USER_FAILURE',
  LOGOUT: 'auth/LOGOUT',
}

export const getUser = ({ token, refreshToken, authUser }) => {
  setTokens(token, refreshToken)

  return async (dispatch) => {
    try {
      const user = await api.user.getUser(authUser.phoneNumber)

      dispatch({
        type: types.GET_USER_SUCCESS,
        data: {
          token,
          authUser,
          user,
        },
      })
    } catch (error) {
      dispatch({
        type: types.GET_USER_FAILURE,
        data: error.message,
      })
    }
  }
}

export const logout = () => {
  deleteTokens()
  return { type: types.LOGOUT }
}

export default useActions.bind(null, {
  getUser,
  logout,
})

const initialState = {
  token: null,
  authUser: null,
  user: null,
  error: null,
  loggedOut: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        token: action.data.token,
        authUser: action.data.authUser,
        user: action.data.user,
        error: null,
        loggedOut: false,
      }

    case types.GET_USER_FAILURE:
      return {
        ...state,
        authUser: null,
        user: null,
        error: action.data,
        loggedOut: true,
      }

    case types.LOGOUT:
      return {
        token: null,
        authUser: null,
        user: null,
        error: null,
        loggedOut: true,
      }

    default:
      return state
  }
}
