import Box from '@mui/material/Box'
import { toDateString } from 'services/utils'
import { useSelectedUserProfile, useNetworksMetrics } from 'store/selectors'

const EXCLUDED_METRICS = ['username', 'age', 'signup_date']

function renderMetric(value, isDate) {
  if (value === null || value === undefined)
    return <Box sx={{ fontStyle: 'italic', color: 'text.secondary' }}>null</Box>
  return <Box>{isDate ? toDateString(new Date(value)) : value}</Box>
}

const Metrics = () => {
  const { metrics } = useSelectedUserProfile()
  const schema = useNetworksMetrics()

  if (!metrics) return null

  return (
    <Box
      sx={{
        mx: 2,
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        overflow: 'auto',
      }}
    >
      <Box sx={{ width: '300px', lineHeight: '24px' }}>
        {schema
          .filter(({ name }) => !EXCLUDED_METRICS.includes(name))
          .map(({ name }) => (
            <Box
              key={name}
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.0em',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ mr: 1 }}>{name}:</Box>
              {renderMetric(
                metrics[name],
                !!schema.find((col) => col.name === name)?.is_date
              )}
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default Metrics
