import { useEffect, useState, useRef, useCallback } from 'react'
import api from 'services/api'

const initialResults = {
  items: [],
  hasMore: false,
  error: null,
  isLoading: false,
}

export default function useSearchResults(params) {
  const [results, setResults] = useState(initialResults)
  const pending = useRef([])
  const term = useRef('')

  const searchUsers = useCallback(
    async (searchTerm, offset, limit, requestKey) => {
      try {
        setResults((results) => ({ ...results, isLoading: true }))
        const data = await api.users.searchUsers(searchTerm, offset, limit)

        if (pending.current.includes(requestKey))
          setResults((results) => ({
            items: offset === 0 ? data : [...results.items, ...data],
            hasMore: data.length === limit,
            error: null,
            isLoading: false,
          }))
      } catch (err) {
        setResults((results) => ({
          ...results,
          error: err.message,
          isLoading: false,
        }))
      } finally {
        pending.current = pending.current.filter((key) => key !== requestKey)
      }
    },
    []
  )

  useEffect(() => {
    const { searchTerm = '', offset = 0, limit = 10 } = params || {}

    if (!searchTerm) {
      pending.current = []
      return setResults(initialResults)
    }

    if (term.current !== searchTerm) {
      pending.current = []
      term.current = searchTerm
    }

    const requestKey = Math.random()
    pending.current.push(requestKey)
    searchUsers(searchTerm, offset, limit, requestKey)
  }, [params, searchUsers])

  return results
}
