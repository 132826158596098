export function getRecipients(segmentType, numUserIds) {
  switch (segmentType) {
    case 'ALL':
      return 'All users'
    case 'UNDER_21':
      return 'All users under 21'
    case 'SELECTION':
      return `${numUserIds} ${numUserIds === 1 ? 'user' : 'users'}`
    default:
      return null
  }
}
