import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

const Loader = ({ text, error, size, onRetry }) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      p: 4,
    }}
  >
    {error ? (
      <Box sx={{ textAlign: 'center' }}>
        <Box>{error}</Box>
        {onRetry && (
          <Button sx={{ mt: 4 }} onClick={onRetry} variant="contained">
            Try Again
          </Button>
        )}
      </Box>
    ) : (
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ mb: 3 }}>{text}</Box>
        <CircularProgress size={size} />
      </Box>
    )}
  </Box>
)

export default Loader
