import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import SchoolsMap from './Map'
import api from 'services/api'
import SelectedSchool from './SelectedSchool'
import Legend from './Legend'
import { getColorForDataset } from './utils'
import useSearchResults from './useSearchResults'
import Filters from './Filters'
import SearchResults from './SearchResults'
import { DEFAULT_RADIUS, DEFAULT_SLOP } from './const'

const PAGE_SIZE = 20

const initialFilters = {
  text: '',
  location: {
    center: null,
    radius: DEFAULT_RADIUS,
  },
  age: {
    mid: null,
    slop: DEFAULT_SLOP,
  },
}

const initialQuery = {
  filters: initialFilters,
  offset: 0,
  limit: PAGE_SIZE,
}

const Schools = () => {
  const [selectedSchool, setSelectedSchool] = useState(null)
  const [schools, setSchools] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [datasets, setDatasets] = useState(null)
  const mapRef = useRef(null)
  const [query, setQuery] = useState(initialQuery)
  const [filters, setFilters] = useState(initialFilters)
  const results = useSearchResults(query)
  const [hoveredSchoolId, setHoveredSchoolId] = useState(null)
  const resultsRef = useRef(null)

  useEffect(() => {
    setIsLoading(true)
    api.schools
      .getMap()
      .then(({ schools, datasets }) => {
        setSchools(schools)
        setDatasets(
          datasets.map((dataset) => ({
            ...dataset,
            color: getColorForDataset(datasets, dataset.id),
          }))
        )
        setIsLoading(false)
      })
      .catch(setError)
  }, [])

  useEffect(() => {
    setQuery((q) => ({ ...q, offset: 0, filters }))
    setSelectedSchool(null)
    resultsRef.current?.scrollToTop()
  }, [filters])

  const onSelectSchoolMap = useCallback((schoolId) => {
    setHoveredSchoolId(null)
    setFilters(initialFilters)

    if (schoolId === null) return setSelectedSchool(null)

    api.schools.getSchool(schoolId).then(setSelectedSchool)
  }, [])

  const onSelectSchoolSearchBar = useCallback((schoolId) => {
    setHoveredSchoolId(null)
    setFilters(initialFilters)

    if (schoolId === null) return setSelectedSchool(null)

    api.schools.getSchool(schoolId).then((school) => {
      setSelectedSchool(school)
      mapRef.current.flyTo(school.location.coordinates)
    })
  }, [])

  const handleNextPage = useCallback(() => {
    setQuery((q) => ({
      ...q,
      offset: q.offset + PAGE_SIZE,
    }))
  }, [])

  const onSelectLocation = useCallback((center) => {
    setFilters((f) => ({
      ...f,
      location: {
        ...f.location,
        center,
      },
    }))
  }, [])

  const hoveredSchool = useMemo(() => {
    if (!results || !hoveredSchoolId) return null

    return results.items.find((s) => s.id === hoveredSchoolId)
  }, [results, hoveredSchoolId])

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        fontSize: '0.9em',
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: 'black',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          right: 20,
          zIndex: 20,
          background: 'transparent',
        }}
      >
        <Legend isLoading={isLoading} error={error} datasets={datasets} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
          width: 380,
          zIndex: 20,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            borderRadius: 2,
          }}
        >
          <Filters filters={filters} setFilters={setFilters} />
        </Box>
        <Box sx={{ height: 10 }} />
        {!selectedSchool && (
          <Box
            sx={{
              backgroundColor: 'background.default',
              borderRadius: 2,
            }}
          >
            <SearchResults
              ref={resultsRef}
              results={results}
              handleNextPage={handleNextPage}
              onSelectSchool={onSelectSchoolSearchBar}
              onHoverSchool={setHoveredSchoolId}
            />
          </Box>
        )}
        {selectedSchool && (
          <SelectedSchool
            school={selectedSchool}
            datasets={datasets}
            onClose={setSelectedSchool.bind(null, null)}
          />
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <SchoolsMap
          onSelectSchool={onSelectSchoolMap}
          selectedSchool={selectedSchool}
          schools={schools}
          datasets={datasets}
          onSelectLocation={onSelectLocation}
          location={filters.location}
          hoveredSchool={hoveredSchool}
          ref={mapRef}
        />
      </Box>
    </Box>
  )
}

export default Schools
