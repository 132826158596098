import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import SaveIcon from '@mui/icons-material/Save'
import TooltipIcon from 'components/core/Tooltip'

const SaveButton = ({ onSave, isSaving }) => (
  <Box
    sx={{
      width: 40,
      height: 40,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {isSaving ? (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CircularProgress sx={{ display: 'block' }} size={24} />
      </Box>
    ) : (
      <TooltipIcon
        Icon={SaveIcon}
        sx={{ fontSize: '1.75em' }}
        title="get video"
        onClick={onSave}
      />
    )}
  </Box>
)

export default SaveButton
