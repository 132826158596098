import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

const NamedDivider = ({ title, sx = {} }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // height: '100%',
      fontStyle: 'italic',
      color: 'text.secondary',
      mx: 2,
      fontSize: '0.9em',
      ...sx,
    }}
  >
    <Divider sx={{ flex: 1 }} />
    <Box sx={{ mx: 1 }}>{title}</Box>
    <Divider sx={{ flex: 1 }} />
  </Box>
)

export default NamedDivider
