import { useState, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box'
import CreateBroadcast from './Create'
import BroadcastList from './List'
import Header from './Header'
import api from 'services/api'
import Loader from 'components/core/Loader'

const Broadcast = () => {
  const [createOpen, setCreateOpen] = useState(false)
  const [broadcasts, setBroadcasts] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [deleting, setDeleting] = useState([])

  const loadBroadcasts = useCallback(() => {
    setIsLoading(true)
    api.broadcasts
      .listBroadcasts()
      .then(setBroadcasts)
      .then(() => setIsLoading(false))
      .catch((e) => setError(e.message))
  }, [])

  useEffect(() => loadBroadcasts(), [loadBroadcasts])

  const onDelete = useCallback((broadcastId) => {
    setDeleting((deleting) => [...new Set([...deleting, broadcastId])])
    api.broadcasts.deleteBroadcast(broadcastId).then(() => {
      setBroadcasts((broadcasts) =>
        broadcasts.filter((b) => b.id !== broadcastId)
      )
      setDeleting((deleting) => deleting.filter((id) => id !== broadcastId))
    })
  }, [])

  const onCreate = useCallback(() => {
    setCreateOpen(false)
    loadBroadcasts()
  }, [loadBroadcasts])

  if (broadcasts === null)
    return <Loader text="Loading broadcasts" error={error} />

  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }}>
      <Header />
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 6,
          px: 6,
          position: 'relative',
          height: 'calc(100% - 60px)',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '1400px', maxWidth: '100%' }}>
          <BroadcastList
            broadcasts={broadcasts}
            loadBroadcasts={loadBroadcasts}
            isLoading={isLoading}
            deleting={deleting}
            onDelete={onDelete}
            onCreate={setCreateOpen.bind(null, true)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: '800px',
          overflow: 'auto',
          transition: 'all 0.5s ease',
          transform: !createOpen ? 'translateX(100%)' : undefined,
          backgroundColor: 'background.paper',
          zIndex: 1,
        }}
      >
        {createOpen && (
          <CreateBroadcast
            onCreate={onCreate}
            onCancel={setCreateOpen.bind(null, false)}
          />
        )}
      </Box>
    </Box>
  )
}

export default Broadcast
