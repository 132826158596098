import { useRef, forwardRef, useImperativeHandle, useCallback } from 'react'
import Box from '@mui/material/Box'
import InfiniteScroll from 'react-infinite-scroll-component'

const ResultsList = forwardRef(
  ({ results, onSelectSchool, onHoverSchool, handleNextPage }, ref) => {
    const scrollableRef = useRef(null)

    useImperativeHandle(
      ref,
      () => ({
        scrollToTop: () => {
          if (scrollableRef.current !== null)
            scrollableRef.current.scrollTop = 0
        },
      }),
      []
    )

    const logScoreDetails = useCallback((school, e) => {
      e.stopPropagation()
      console.log(school.score_details)
    }, [])

    return (
      <Box
        sx={{ backgroundColor: 'background.default', borderRadius: '0.5em' }}
      >
        {results.query &&
          (results.query.filters.text.length > 0 ||
            results.query.filters.location ||
            results.query.filters.age) && (
            <Box
              sx={{
                fontStyle: 'italic',
                p: 1.5,
                borderBottomStyle: 'solid',
                borderBottomColor: 'divider',
                borderBottomWidth: results.numHits > 0 ? 1 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>{results.numHits} results</Box>
              <Box>{results.processingTimeMs} ms</Box>
            </Box>
          )}
        {results.items.length > 0 && (
          <Box
            id="searchResults"
            sx={{
              maxHeight: 500,
              overflow: 'scroll',
            }}
            onMouseLeave={onHoverSchool.bind(null, null)}
            ref={scrollableRef}
          >
            <InfiniteScroll
              dataLength={results.items.length}
              next={results.items.length === 0 ? undefined : handleNextPage}
              hasMore={results.hasMore}
              scrollableTarget="searchResults"
              scrollableRef={scrollableRef}
              scrollThreshold={0.6}
            >
              {results.items.map((school, index) => (
                <Box
                  key={school.id}
                  sx={{
                    px: 2,
                    py: 1.5,
                    '&:hover': { backgroundColor: 'action.hover' },
                    cursor: 'pointer',
                    borderBottomWidth:
                      index === results.items.length - 1 ? 0 : 1,
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'divider',
                  }}
                  onClick={onSelectSchool.bind(null, school.id)}
                  onMouseEnter={onHoverSchool.bind(null, school.id)}
                >
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box sx={{ fontWeight: 700, pr: 1 }}>{school.name}</Box>
                    {school.score !== undefined && (
                      <Box
                        sx={{
                          fontWeight: 700,
                          '&:hover': {
                            textDecoration: school.score_details
                              ? 'underline'
                              : 'none',
                          },
                        }}
                        onClick={
                          school.score_details
                            ? logScoreDetails.bind(null, school)
                            : undefined
                        }
                      >
                        {school.score.toFixed(2)}
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      {school.city}, {school.state} {school.country}
                    </Box>
                    <Box sx={{ mr: 1, fontSize: '0.9em', fontStyle: 'italic' }}>
                      {school.enrollment !== null
                        ? 'enr. ' + school.enrollment + ' | '
                        : ''}
                      ages {school.age_range}
                    </Box>
                  </Box>
                </Box>
              ))}
            </InfiniteScroll>
          </Box>
        )}
      </Box>
    )
  }
)

export default ResultsList
