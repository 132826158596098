import { useEffect } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { Chat } from 'stream-chat-react'
import Box from '@mui/material/Box'
import ChannelList from '../ChannelList'
import ViewChannel from '../ViewChannel'
import CreateChannel from '../CreateChannel'
import Broadcast from '../Broadcast'
import Welcome from '../Welcome'
import SharedReclips from '../SharedReclips'
import Modals from '../modals'
import 'stream-chat-react/dist/css/index.css'
import './Main.css'
import { useSettings } from 'store/selectors'

const Main = ({ client }) => {
  const { theme } = useSettings()
  const navigate = useNavigate()

  useEffect(() => {
    const listener = client.on((event) => {
      if (event.type === 'user.updated' && event.user.id === client.userID)
        console.log('is_broadcasting', event.user.is_broadcasting)
    })

    return () => listener.unsubscribe()
  }, [client, navigate])

  return (
    <Chat client={client} theme={`messaging ${theme}`}>
      <Box
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          width: '370px',
          height: '100%',
        }}
      >
        <ChannelList />
      </Box>
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          height: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Routes>
          <Route path="" element={<ViewChannel />} />
          <Route path="create" element={<CreateChannel />} />
          <Route path="attachments" element={<SharedReclips />} />
          <Route path="broadcasts/*" element={<Broadcast />} />
          <Route path="welcome/*" element={<Welcome />} />
          <Route path="*" element={<Navigate to="" replace />} />
        </Routes>
      </Box>
      <Modals />
    </Chat>
  )
}

export default Main
