import Box from '@mui/material/Box'

const ChannelFilter = ({ filterType, setFilterType }) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
      }}
    >
      {['all', 'unread', 'faves'].map((type) => (
        <Box
          key={type}
          sx={{
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: type === filterType ? 700 : 400,
            backgroundColor:
              type === filterType ? 'background.paper' : 'background.default',
            textTransform: 'capitalize',
            cursor: type === filterType ? 'default' : 'pointer',
          }}
          onClick={setFilterType.bind(null, type)}
        >
          {type}
        </Box>
      ))}
    </Box>
  )
}

export default ChannelFilter
