import api from 'services/api'
import useActions from 'hooks/useActions'

export const types = {
  GET_RECENT_RECLIPS_SUCCESS: 'map/GET_RECENT_RECLIPS_SUCCESS',
  GET_RECENT_RECLIPS_FAILURE: 'map/GET_RECENT_RECLIPS_FAILURE',
  GET_USER_SUCCESS: 'map/GET_USER_SUCCESS',
  GET_USER_FAILURE: 'map/GET_USER_FAILURE',
}

export const getRecentReclips = (params = {}) => {
  return async (dispatch) => {
    try {
      const recentReclips = await api.map.getRecentReclips(params)
      dispatch({
        type: types.GET_RECENT_RECLIPS_SUCCESS,
        data: recentReclips,
      })
    } catch (error) {
      dispatch({
        type: types.GET_RECENT_RECLIPS_FAILURE,
        data: error.message,
      })
    }
  }
}

export const getUser = (id) => {
  return async (dispatch) => {
    try {
      const recentReclips = await api.map.getUser(id)
      dispatch({
        type: types.GET_USER_SUCCESS,
        data: recentReclips,
      })
    } catch (error) {
      dispatch({
        type: types.GET_USER_FAILURE,
        data: error.message,
      })
    }
  }
}

export default useActions.bind(null, {
  getRecentReclips,
})

const initialState = {
  data: {
    reclips: null,
  },
  error: null,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RECENT_RECLIPS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          reclips: action.data,
        },
        error: null,
      }

    case types.GET_RECENT_RECLIPS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          reclips: null,
        },
        error: action.data,
      }

    case types.GET_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          users: {
            ...state.data.users,
            [action.data.id]: action.data,
          },
        },
        error: null,
      }

    default:
      return state
  }
}
