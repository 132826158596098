import Box from '@mui/material/Box'
import ConfigSelector from './ConfigSelector'
import { Text } from './Bits'

const Intro = ({ config, configs, setConfig }) => (
  <Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ fontSize: '1.2em', fontWeight: 700 }}>GPT Configuration</Box>
      <ConfigSelector config={config} configs={configs} setConfig={setConfig} />
    </Box>
    <Text>
      This is the current configuration for GPT {config.type}. You can test
      alternative configurations here.
    </Text>
  </Box>
)

export default Intro
