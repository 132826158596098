// NOTE: coordinated with model in backend
export const itemStatuses = {
  SHARED_WITH_TR: 'SHARED_WITH_TR',
  DRAFT_ADD: 'DRAFT_ADD',
  DRAFT_DELETE: 'DRAFT_DELETE',
  PUBLISHED: 'PUBLISHED',
  EXPIRED: 'EXPIRED',
  HIDDEN: 'HIDDEN',
}

export const coverPhotoTypes = {
  NONE: 'NONE',
  STILL: 'STILL',
  LIVE: 'LIVE',
}
