import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import SelectUsers from '../../../SelectUsers'
import SetMode from './SetMode'
import CSV from './CSV'

const modes = [
  {
    type: 'all',
    label: 'All users',
  },
  {
    type: 'under_21',
    label: 'All users under 21',
  },
  {
    type: 'selection',
    label: 'Selected users',
  },
  {
    type: 'csv',
    label: 'CSV',
  },
]

const segmentTypes = {
  ALL: 'ALL',
  UNDER_21: 'UNDER_21',
  SELECTION: 'SELECTION',
}

const GetSegment = ({ onChange }) => {
  const [mode, setMode] = useState('')
  const [userIds, setUserIds] = useState([])
  const [users, setUsers] = useState([])

  useEffect(() => {
    switch (mode) {
      case '':
        return
      case 'all':
        return onChange({
          type: segmentTypes.ALL,
          userIds: null,
        })
      case 'under_21':
        return onChange({
          type: segmentTypes.UNDER_21,
          userIds: null,
        })
      case 'selection':
        return onChange({
          type: segmentTypes.SELECTION,
          userIds: users.map((user) => user.id),
        })
      case 'csv':
        return onChange({
          type: segmentTypes.SELECTION,
          userIds,
        })
      default:
        return
    }
  }, [mode, userIds, users, onChange])

  return (
    <Box>
      <SetMode modes={modes} mode={mode} setMode={setMode} />
      {(() => {
        switch (mode) {
          case 'all':
          case 'under_21':
            return null
          case 'selection':
            return (
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  mt: 1,
                  borderColor: 'divider',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderRadius: 2,
                }}
              >
                <SelectUsers
                  selectedUsers={users}
                  onChangeSelectedUsers={setUsers}
                />
              </Box>
            )
          case 'csv':
            return (
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  mt: 1,
                  borderColor: 'divider',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderRadius: 2,
                }}
              >
                <CSV onChangeSelectedUserIds={setUserIds} />
              </Box>
            )
          default:
            return null
        }
      })()}
    </Box>
  )
}

export default GetSegment
