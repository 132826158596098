import { useSelectedNode } from 'store/selectors'
import SelectedUser from './SelectedUser'
import SelectedHiddenUser from './SelectedHiddenUser'

const SelectedNode = () => {
  const selectedNode = useSelectedNode()
  return !!selectedNode.user ? <SelectedUser /> : <SelectedHiddenUser />
}

export default SelectedNode
