import Box from '@mui/material/Box'
import { useState, useCallback, useRef } from 'react'
import IdleOverlay from './IdleOverlay'
import PlayingOverlay from './PlayingOverlay'
import EndedOverlay from './EndedOverlay'
import TooltipIcon from 'components/core/Tooltip'
import TrimStart from '@mui/icons-material/KeyboardDoubleArrowRight'
import TrimEnd from '@mui/icons-material/KeyboardDoubleArrowLeft'
import SaveButton from './SaveButton'

const [
  LOADING, // before the canplay event fires
  IDLE, // not started yet OR paused
  PLAYING, // during playback
  ENDED, // ended OR paused within STING_LENGTH of the end
] = [0, 1, 2, 3]

const Player = ({ reclip, audioUrl, trimmed = false, onSave, isSaving }) => {
  const audioRef = useRef(null)
  const [overlay, setOverlay] = useState(LOADING)
  const [time, setTime] = useState(trimmed ? reclip.start : 0)
  const initialPlay = useRef(true)

  const play = useCallback(() => {
    audioRef.current.play()
  }, [])

  const replay = useCallback(() => {
    play()
  }, [play])

  const pause = useCallback(() => {
    audioRef.current.pause()
  }, [])

  const onCanPlay = useCallback(() => {
    if (overlay !== PLAYING) setOverlay(IDLE)

    if (initialPlay.current) {
      initialPlay.current = false
      play()
    }
  }, [overlay, play])

  const onPlay = useCallback(() => {
    setOverlay(PLAYING)
  }, [])

  const onPause = useCallback(() => {
    setOverlay(IDLE)
  }, [])

  const onEnded = useCallback(() => {
    setOverlay(ENDED)
  }, [])

  const onTimeUpdate = useCallback(() => {
    if (trimmed) setTime(audioRef.current.currentTime + reclip.start)
    else setTime(audioRef.current.currentTime)
  }, [trimmed, reclip])

  const coverPhotoUrl = reclip.cover_photo?.is_visible
    ? reclip.cover_photo?.url
    : null

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          position: 'relative',
          borderRadius: '12px',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            background: `linear-gradient(to bottom, ${reclip.background_color1}, ${reclip.background_color2})`,
            backgroundImage: coverPhotoUrl
              ? `url(${coverPhotoUrl})`
              : undefined,
            backgroundSize: 'cover',
          }}
        >
          {(() => {
            switch (overlay) {
              case LOADING:
                return null
              case IDLE:
                return <IdleOverlay play={play} reclip={reclip} />
              case PLAYING:
                return (
                  <PlayingOverlay pause={pause} reclip={reclip} time={time} />
                )
              case ENDED:
                return <EndedOverlay replay={replay} reclip={reclip} />
              default:
                return null
            }
          })()}
        </Box>
      </Box>
      <Box sx={{ width: '100%', mt: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              flex: 1,
              '& audio': {
                width: '99.5%', // cuz it gets cut off at the end for some reason
                display: 'block',
              },
            }}
          >
            <audio
              ref={audioRef}
              preload="auto"
              onPlay={onPlay}
              onPause={onPause}
              onEnded={onEnded}
              onTimeUpdate={onTimeUpdate}
              onCanPlay={onCanPlay}
              controls
            >
              <source src={audioUrl} type="audio/x-wav" />
              Your browser does not support the audio element.
            </audio>
          </Box>
          {onSave && (
            <Box sx={{ ml: 0.5 }}>
              <SaveButton onSave={onSave} isSaving={isSaving} />
            </Box>
          )}
        </Box>
        {!trimmed && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              fontSize: '0.8em',
              mt: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TooltipIcon
                title="trim start"
                Icon={TrimStart}
                onClick={() => (audioRef.current.currentTime = reclip.start)}
              />
              <Box sx={{ ml: 0.5 }}>{reclip.start.toFixed(0)}s</Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 0.5 }}>{reclip.end.toFixed(0)}s</Box>
              <TooltipIcon
                title="trim end"
                Icon={TrimEnd}
                onClick={() => (audioRef.current.currentTime = reclip.end)}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Player
