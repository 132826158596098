import Box from '@mui/material/Box'
import {
  useSelectedNetwork,
  useSelectedNode,
  useSelectedLink,
} from 'store/selectors'
import useNetworksActions from 'store/actions/networks'

const Chevron = () => (
  <Box sx={{ mx: 2, fontSize: '1em', userSelect: 'none' }}>&#8594;</Box>
)

const BreadCrumbs = () => {
  const { selectNetwork } = useNetworksActions()
  const selectedNetwork = useSelectedNetwork()
  const selectedNode = useSelectedNode()
  const selectedLink = useSelectedLink()

  const networkCrumb = selectedNetwork ? (
    <Box
      sx={{ cursor: selectedNode || selectedLink ? 'pointer' : 'default' }}
      onClick={selectNetwork.bind(null, selectedNetwork.id)}
    >
      {selectedNetwork.name}
    </Box>
  ) : null

  const items = [networkCrumb]

  if (selectedNode) {
    items.push(<Chevron />)
    items.push(
      <Box>
        {!!selectedNode.user
          ? `@${selectedNode.user.username}`
          : selectedNode.phone_number}
      </Box>
    )
  }

  if (selectedLink) {
    items.push(<Chevron />)
    items.push(
      <Box>
        {!!selectedLink.friendship
          ? selectedLink.friendship.nodes
              .map((node) => '@' + node?.user?.username)
              .join(' and ')
          : 'No friendship found'}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        fontWeight: 700,
        fontSize: '1.2em',
      }}
    >
      {items.map((item, index) => (
        <Box key={index.toString()}>{item}</Box>
      ))}
    </Box>
  )
}

export default BreadCrumbs
