import { useEffect, useState, useCallback, useRef } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import api from 'services/api'
import SuggestionList from './SuggestionList'
import RefreshIcon from '@mui/icons-material/Refresh'
import CircularProgress from '@mui/material/CircularProgress'
import NumberSelect from 'components/core/NumberSelect'
import Button from '@mui/material/Button'

const BATCH_SIZE = 50

const EvergreenSuggestions = () => {
  const listRef = useRef(null)
  const [results, setResults] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [params, setParams] = useState({
    age: 15,
    offset: 0,
  })

  const getSuggestions = useCallback(async ({ age, offset }) => {
    setIsLoading(true)
    const results = await api.suggestions.getEvergreenSuggestions({
      age: age,
      offset: offset,
      limit: BATCH_SIZE,
      register_suggestions: false,
    })
    setIsLoading(false)
    setResults(results)
    if (offset === 0) {
      setSuggestions(results.hits)
      listRef.current.scrollToTop()
    } else {
      setSuggestions((s) => [...s, ...results.hits])
    }
  }, [])

  const takeSuggestions = useCallback(async () => {
    setIsLoading(true)
    await api.suggestions.getEvergreenSuggestions({
      age: params.age,
      offset: 0,
      limit: 1,
      register_suggestions: true,
    })
    setParams({ ...params, offset: 0 })
  }, [params])

  useEffect(() => {
    getSuggestions(params)
  }, [getSuggestions, params])

  const handleAgeChange = useCallback((age) => {
    setParams((p) => ({ offset: 0, age }))
  }, [])

  const refresh = useCallback(() => {
    setParams((p) => ({ ...p, offset: 0 }))
  }, [])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 500,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ py: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ fontWeight: 700, flex: 1 }}>Evergreen Suggestions</Box>
          <Box sx={{ mr: 1 }}>
            <NumberSelect
              value={params.age}
              minValue={13}
              maxValue={120}
              onSelect={handleAgeChange}
              label="requester age"
            />
          </Box>
        </Box>
        <Divider />
        <Box sx={{ py: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1, fontStyle: 'italic' }}>
            {results
              ? `showing ${suggestions.length} of ${results.num_hits}`
              : ''}
          </Box>
          <Box
            sx={{
              width: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <RefreshIcon
                size={20}
                onClick={refresh}
                sx={{ cursor: 'pointer', '&:hover': { opacity: '0.6' } }}
              />
            )}
          </Box>
          <Button
            variant="outlined"
            onClick={takeSuggestions.bind(null, params.age)}
            sx={{ ml: 1 }}
          >
            take one
          </Button>
        </Box>
        <Divider />
        <Box sx={{ flex: 1, position: 'relative' }}>
          <Box
            sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
          >
            <SuggestionList
              ref={listRef}
              suggestions={suggestions}
              handleNextPage={() =>
                setParams((p) => ({ ...p, offset: p.offset + BATCH_SIZE }))
              }
              hasMore={results && results.num_hits > suggestions.length}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default EvergreenSuggestions
