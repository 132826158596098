import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import { reducer as auth, types as authTypes } from './actions/auth'
import { reducer as chat } from './actions/chat'
import { reducer as networks } from './actions/networks'
import { reducer as deepgram } from './actions/deepgram'
import { reducer as map } from './actions/map'
import { reducer as settings } from './actions/settings'
import { reducer as toaster } from './actions/toaster'
import { reducer as modals } from './actions/modals'

const appReducer = combineReducers({
  auth,
  chat,
  networks,
  deepgram,
  map,
  settings,
  toaster,
  modals,
})

// wipe store on logout
const rootReducer = (state, action) => {
  if (action.type === authTypes.LOGOUT) state = undefined

  return appReducer(state, action)
}

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

export default store
