import { useCallback } from 'react'
import LoadingIcon from 'components/core/LoadingIcon'
import DeleteIcon from '@mui/icons-material/Delete'
import useModalsActions from 'store/actions/modals'

const DeleteButton = ({ isLoading, onClick }) => {
  const { openModal } = useModalsActions()

  const doOpen = useCallback(() => {
    openModal('confirmDeleteBroadcast', { onConfirm: onClick })
  }, [onClick, openModal])

  return (
    <LoadingIcon isLoading={isLoading} onClick={doOpen} Icon={DeleteIcon} />
  )
}

export default DeleteButton
