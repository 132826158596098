import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'

const CSV = ({ onChangeSelectedUserIds }) => {
  const [detectedUserIds, setDetectedUserIds] = useState(0)

  const handleInput = useCallback(
    (e) => {
      const userIds = e.target.value
        .replace(/(\r\n|\n|\r|")/gm, '')
        .split(',')
        .map((userId) => userId.trim())
        .filter((userId) => !!userId)

      onChangeSelectedUserIds(userIds)
      setDetectedUserIds(userIds.length)
    },
    [onChangeSelectedUserIds]
  )

  return (
    <Box
      sx={{
        position: 'relative',
        '& > textarea': {
          height: '250px',
          width: '100%',
          border: 0,
          backgroundColor: 'transparent',
          outline: 0,
          margin: 0,
          display: 'block',
          marginRight: 0,
          resize: 'none',
          padding: 2,
          fontFamily: 'typography.fontFamily',
          color: 'text.primary',
          fontSize: '1em',
        },
      }}
    >
      <textarea
        autoFocus
        onChange={handleInput}
        placeholder="Enter comma-separated list of user ids"
        spellCheck={false}
      />
      <Box
        sx={{
          fontSize: '0.8em',
          position: 'absolute',
          top: 'calc(100% + 8px)',
          right: 0,
          color: 'text.secondary',
          fontStyle: 'italic',
        }}
      >
        {detectedUserIds} user ids
      </Box>
    </Box>
  )
}

export default CSV
