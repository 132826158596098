import { Routes, Route, Navigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import WelcomeMessages from './Messages'
// import WelcomeHistory from './History'
import Header from './Header'

const HEADER_HEIGHT = 60

const Welcome = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ height: `${HEADER_HEIGHT}px` }}>
        <Header />
      </Box>
      <Box sx={{ height: `calc(100% - ${HEADER_HEIGHT}px)`, overflow: 'auto' }}>
        <Routes>
          <Route path="list" element={<WelcomeMessages />} />
          {/* <Route path="history" element={<WelcomeHistory />} /> */}
          <Route path="*" element={<Navigate to="list" replace />} />
        </Routes>
      </Box>
    </Box>
  )
}

export default Welcome
