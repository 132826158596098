import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import Box from '@mui/material/Box'

export default function BasicDateTimePicker({ date, setDate }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ maxWidth: 300 }}>
        <DemoContainer components={['DateTimePicker']}>
          <DateTimePicker
            label="Choose date and time"
            onChange={setDate}
            value={date}
            disablePast
          />
        </DemoContainer>
      </Box>
    </LocalizationProvider>
  )
}
