import useActions from 'hooks/useActions'

export const types = {
  TOGGLE_THEME: 'settings/TOGGLE_THEME',
}

export const toggleTheme = () => {
  return {
    type: types.TOGGLE_THEME,
  }
}

export default useActions.bind(null, {
  toggleTheme,
})

const initialState = {
  theme: 'dark',
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_THEME:
      return {
        ...state,
        theme: state.theme === 'dark' ? 'light' : 'dark',
      }

    default:
      return state
  }
}
