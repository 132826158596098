import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import api from 'services/api'
import UserSelector from './UserSelector'
import ShareSelector from './ShareSelector'

const EMOJIS = ['❤️', '😊', '👍', '😳', '💩', '🍆', '😰', '🪼', '🎉', '🍔']

function makeid(length) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

const Reactions = () => {
  const [message, setMessage] = useState('')
  const [groupId, setGroupId] = useState(makeid(12))
  const [sequenceNumber, setSequenceNumber] = useState(0)
  const [share, setShare] = useState(null)
  const [reactingUser, setReactingUser] = useState(null)
  const [initialData, setInitialData] = useState(null)

  useEffect(() => {
    api.reactions.getInitialData().then(({ shares, staff_users }) => {
      setInitialData({ shares, staff_users })
      setShare(shares[0])
      setReactingUser(staff_users[0])
    })
  }, [])

  useEffect(() => {
    const handleKeyDown = (e) => {
      let reaction = null
      try {
        const keyInt = (+e.key + 9) % 10
        reaction = EMOJIS[keyInt]
      } catch (e) {}

      if (reaction !== undefined) {
        api.reactions.react({
          reaction: {
            group_id: groupId.toString(),
            sequence_number: sequenceNumber,
            share_code: share.code,
            reaction,
          },
          reacting_user_id: reactingUser.id,
        })
        setMessage((message) => message + reaction)
        setSequenceNumber(sequenceNumber + 1)
      }
    }

    document.addEventListener('keydown', handleKeyDown, true)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true)
    }
  }, [groupId, sequenceNumber, share, reactingUser])

  useEffect(() => {
    setGroupId(makeid(12))
    setSequenceNumber(0)
    setMessage('')
  }, [share, reactingUser])

  if (initialData === null) return null

  if (initialData.shares.length === 0)
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        You need at least one share to use this.
      </Box>
    )

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        Use the number keys to send
        <br />
        reactions to your phone
      </Box>
      <Box
        sx={{
          height: 300,
          width: 240,
          fontSize: '2em',
          overflowY: 'auto',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'background.paper',
          borderRadius: '8px',
          padding: 2,
        }}
      >
        {message}
      </Box>
      <Box sx={{ mt: 1, fontSize: '0.8em', textAlign: 'center' }}>
        <Box>group_id: {groupId}</Box>
        <Box>sequence_number: {sequenceNumber}</Box>
      </Box>
      <Button
        variant="outlined"
        onClick={() => {
          setGroupId(makeid(12))
          setSequenceNumber(0)
          setMessage('')
        }}
        sx={{ mt: 4 }}
      >
        Start new Group
      </Button>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 320,
        }}
      >
        <Box sx={{ fontWeight: 700 }}>reacting user:</Box>
        <UserSelector
          users={initialData.staff_users}
          user={reactingUser}
          setUser={setReactingUser}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 320,
        }}
      >
        <Box sx={{ fontWeight: 700 }}>share code:</Box>
        <ShareSelector
          shares={initialData.shares}
          share={share}
          setShare={setShare}
        />
      </Box>
    </Box>
  )
}

export default Reactions
