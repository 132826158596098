import { useCallback } from 'react'
import Box from '@mui/material/Box'
import TypeSelector from './TypeSelector'
import DateSelector from './DateSelector'

const Filters = ({ tagTypes, tagType, onChangeTagType, onChangeFilters }) => {
  const onChangeDates = useCallback(
    ({ startDate, endDate }) => {
      onChangeFilters((f) => ({
        ...f,
        startDate,
        endDate,
      }))
    },
    [onChangeFilters]
  )

  return (
    <Box>
      <TypeSelector
        tagType={tagType}
        tagTypes={tagTypes}
        onChange={onChangeTagType}
      />
      <Box sx={{ height: 20 }} />
      <DateSelector onChangeDates={onChangeDates} />
    </Box>
  )
}

export default Filters
