import api from 'services/api'
import useActions from 'hooks/useActions'
import { DEFAULT_METRIC } from 'components/Main/Networks/config'
import { getBounds, toTimestamp } from 'components/Main/Networks/utils'
import { selectedNetworkNodes, networks, networksMetrics } from '../selectors'

export const types = {
  GET_NETWORKS_REPORT_SUCCESS: 'networks/GET_NETWORKS_REPORT_SUCCESS',
  GET_NETWORKS_REPORT_FAILURE: 'networks/GET_NETWORKS_REPORT_FAILURE',
  GET_NETWORK_SUCCESS: 'networks/GET_NETWORK_SUCCESS',
  GET_NETWORK_FAILURE: 'networks/GET_NETWORK_FAILURE',
  GET_NETWORKS_SUCCESS: 'networks/GET_NETWORKS_SUCCESS',
  GET_NETWORKS_FAILURE: 'networks/GET_NETWORKS_FAILURE',
  HIGHLIGHT_NODES: 'networks/HIGHLIGHT_NODES',
  HIGHLIGHT_LINKS: 'networks/HIGHLIGHT_LINKS',
  HOVER_ON_NODE: 'networks/HOVER_ON_NODE',
  HOVER_ON_LINK: 'networks/HOVER_ON_LINK',
  SELECT_METRIC: 'networks/SELECT_METRIC',
  SELECT_NETWORK_PENDING: 'networks/SELECT_NETWORK_PENDING',
  SELECT_NETWORK: 'networks/SELECT_NETWORK',
  SELECT_NODE: 'networks/SELECT_NODE',
  SELECT_LINK: 'networks/SELECT_LINK',
  SELECT_USER_PENDING: 'networks/SELECT_USER_PENDING',
  SELECT_USER: 'networks/SELECT_USER',
  SET_BOUNDS: 'networks/SET_BOUNDS',
}

function convertDateFields(nodes, metrics) {
  metrics.forEach((metric) => {
    if (!metric.is_date) return

    metric.min = toTimestamp(metric.min)
    metric.max = toTimestamp(metric.max)
    nodes.forEach((node) => {
      if (node.is_reclip_user)
        node.metrics[metric.name] = toTimestamp(node.metrics[metric.name])
    })
  })
}

export const getNetworksReport = () => {
  return async (dispatch) => {
    try {
      const report = await api.networks.getNetworksReport()
      dispatch({
        type: types.GET_NETWORKS_REPORT_SUCCESS,
        data: report,
      })
    } catch (error) {
      dispatch({
        type: types.GET_NETWORKS_REPORT_FAILURE,
        data: error.message,
      })
    }
  }
}

export const getNetwork = (networkId) => {
  return async (dispatch, getState) => {
    dispatch({ type: types.SELECT_NETWORK_PENDING })
    try {
      const network = await api.networks.getNetwork(networkId)
      const metrics = networksMetrics(getState())
      convertDateFields(network.nodes, metrics)
      dispatch({
        type: types.GET_NETWORK_SUCCESS,
        data: network,
      })
    } catch (error) {
      dispatch({
        type: types.GET_NETWORK_FAILURE,
        data: error.message,
      })
    }
  }
}

export const getNetworks = () => {
  return async (dispatch) => {
    try {
      const users = await api.networks.getNetworks()
      dispatch({
        type: types.GET_NETWORKS_SUCCESS,
        data: users,
      })
    } catch (error) {
      dispatch({
        type: types.GET_NETWORKS_FAILURE,
        data: error.message,
      })
    }
  }
}

export const highlightNodes = (nodeIds) => ({
  type: types.HIGHLIGHT_NODES,
  data: nodeIds,
})

export const highlightLinks = (linkIndices) => ({
  type: types.HIGHLIGHT_LINKS,
  data: linkIndices,
})

export const hoverOnNode = (nodeId) => ({
  type: types.HOVER_ON_NODE,
  data: nodeId,
})

export const hoverOnLink = (linkIndex) => ({
  type: types.HOVER_ON_LINK,
  data: linkIndex,
})

export const selectMetric = (selectedMetric) => ({
  type: types.SELECT_METRIC,
  data: selectedMetric,
})

export const selectNetwork = (selectedNetworkId) => {
  return async (dispatch, getState) => {
    const network = getState().networks.networks[selectedNetworkId]
    if (network?.isLoaded)
      return dispatch({
        type: types.SELECT_NETWORK,
        data: selectedNetworkId,
      })

    try {
      dispatch({ type: types.SELECT_NETWORK_PENDING, data: selectedNetworkId })
      const network = await api.networks.getNetwork(selectedNetworkId)
      const metrics = networksMetrics(getState())
      convertDateFields(network.nodes, metrics)
      dispatch({
        type: types.GET_NETWORK_SUCCESS,
        data: network,
      })
      dispatch({
        type: types.SELECT_NETWORK,
        data: selectedNetworkId,
      })
    } catch (error) {
      dispatch({
        type: types.GET_NETWORK_FAILURE,
        data: error.message,
      })
    }
  }
}

export const selectNode = (selectedNodeId) => ({
  type: types.SELECT_NODE,
  data: selectedNodeId,
})

export const selectLink = (selectedLinkId) => ({
  type: types.SELECT_LINK,
  data: selectedLinkId,
})

function findUsernameNode(networks, selectedUsername) {
  if (!networks || !selectedUsername) return null

  const loadedNetworks = Object.values(networks).filter(
    (network) => network.isLoaded
  )

  for (let i = 0; i < loadedNetworks.length; i++) {
    const network = loadedNetworks[i]
    const node = network.nodes.find(
      (node) => node.user?.username === selectedUsername
    )
    if (node) return node
  }

  return null
}

export const selectUser = (selectedUsername) => {
  return async (dispatch, getState) => {
    const usernameNode = findUsernameNode(
      networks(getState()),
      selectedUsername
    )

    if (usernameNode)
      return dispatch({
        type: types.SELECT_USER,
        data: {
          selectedNetworkId: usernameNode.network_id,
          selectedNodeId: usernameNode.id,
        },
      })

    try {
      dispatch({ type: types.SELECT_USER_PENDING, data: selectedUsername })

      const network = await api.networks.getNetworkByUsername(selectedUsername)
      const metrics = networksMetrics(getState())
      convertDateFields(network.nodes, metrics)
      const selectedNodeId = network.nodes.find(
        (n) => n.user?.username === selectedUsername
      )?.id

      dispatch({
        type: types.GET_NETWORK_SUCCESS,
        data: network,
      })
      dispatch({
        type: types.SELECT_USER,
        data: {
          selectedNetworkId: network.id,
          selectedNodeId,
        },
      })
    } catch (error) {
      dispatch({
        type: types.GET_NETWORK_FAILURE,
        data: error.message,
      })
    }
  }
}

export const setBounds = (bounds) => ({
  type: types.SET_BOUNDS,
  data: bounds,
})

export const zoomToNetwork = (networkId) => {
  return (dispatch, getState) => {
    const { nodes } = getState().networks.data
    const networkNodes = nodes.filter((n) => n.network_id === networkId)
    const bounds = getBounds(networkNodes, 0.6)
    return dispatch({
      type: types.SET_BOUNDS,
      data: bounds,
    })
  }
}

export const zoomToCenter = () => {
  return (dispatch, getState) => {
    const nodes = selectedNetworkNodes(getState())
    const bounds = getBounds(nodes, 0.05)
    return dispatch({
      type: types.SET_BOUNDS,
      data: bounds,
    })
  }
}

export default useActions.bind(null, {
  getNetworksReport,
  getNetwork,
  getNetworks,
  highlightNodes,
  highlightLinks,
  hoverOnNode,
  hoverOnLink,
  selectMetric,
  selectNetwork,
  selectNode,
  selectLink,
  selectUser,
  setBounds,
  zoomToNetwork,
  zoomToCenter,
})

const initialState = {
  meta: null,
  sidebar: null,
  networks: {},
  metrics: null,
  error: null,
  nodes: [],
  links: [],
  ui: {
    highlightedNodes: {},
    highlightedLinks: {},
    hoveredNode: null,
    hoveredLink: null,
    selectedMetric: DEFAULT_METRIC,
    selectedNetworkId: null,
    selectedNodeId: null,
    selectedLinkId: null,
    selectedUsername: null,
    selectedFriendshipId: null,
    bounds: null,
  },
  loaders: {
    network: null,
    username: null,
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NETWORKS_REPORT_SUCCESS:
      const {
        meta: { metrics, ...meta },
        top,
        staff,
      } = action.data
      return {
        ...state,
        meta,
        metrics,
        sidebar: {
          top: top.map((network) => network.id),
          staff: staff.map((network) => network.id),
        },
        networks: {
          ...[...top, ...staff].reduce((p, c) => {
            p[c.id] = { ...c, isLoaded: false }
            return p
          }, {}),
        },
      }

    case types.GET_NETWORKS_REPORT_FAILURE:
      return {
        ...state,
        error: action.data,
      }

    case types.SELECT_NETWORK_PENDING:
      return {
        ...state,
        error: null,
        loaders: {
          ...state.loaders,
          network: action.data,
        },
      }

    case types.GET_NETWORK_SUCCESS:
      return {
        ...state,
        error: null,
        networks: {
          ...state.networks,
          [action.data.id]: { ...action.data, isLoaded: true },
        },
        loaders: {
          ...state.loaders,
          network: null,
        },
      }

    case types.GET_NETWORKS_SUCCESS:
      return {
        ...state,
        data: action.data,
        error: null,
        ui: {
          ...state.ui,
          selectedNetworkId: action.data.networks[0].id,
        },
      }

    case types.GET_NETWORKS_FAILURE:
      return {
        ...state,
        data: null,
        error: action.data,
      }

    case types.HIGHLIGHT_NODES:
      return {
        ...state,
        ui: {
          ...state.ui,
          highlightedNodes: action.data.reduce((prev, cur) => {
            prev[cur] = true
            return prev
          }, {}),
        },
      }

    case types.HIGHLIGHT_LINKS:
      return {
        ...state,
        ui: {
          ...state.ui,
          highlightedLinks: action.data.reduce((prev, cur) => {
            prev[cur] = true
            return prev
          }, {}),
        },
      }

    case types.HOVER_ON_NODE:
      return {
        ...state,
        ui: {
          ...state.ui,
          hoveredNode: action.data,
        },
      }

    case types.HOVER_ON_LINK:
      return {
        ...state,
        ui: {
          ...state.ui,
          hoveredLink: action.data,
        },
      }

    case types.SELECT_METRIC:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedMetric: action.data,
        },
      }

    case types.SELECT_NETWORK:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedNetworkId: action.data,
          selectedNodeId: null,
          selectedLinkId: null,
          selectedUsername: null,
          selectedFriendshipId: null,
          bounds: null,
        },
      }

    case types.SELECT_NODE:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedNodeId: action.data,
          selectedLinkId: null,
        },
      }

    case types.SELECT_LINK:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedNodeId: null,
          selectedLinkId: action.data,
        },
      }

    case types.SELECT_USER_PENDING:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          username: action.data,
        },
      }

    case types.SELECT_USER:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedNetworkId: action.data.selectedNetworkId,
          selectedNodeId: action.data.selectedNodeId,
          selectedLinkId: null,
        },
        loaders: {
          ...state.loaders,
          username: null,
        },
      }

    case types.SET_BOUNDS:
      return {
        ...state,
        ui: {
          ...state.ui,
          bounds: action.data,
        },
      }

    default:
      return state
  }
}
