import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/me`

export const getUser = async (phoneNumber) => {
  try {
    const { data: user } = await axios.get(BASE_URL)
    return user
  } catch (err) {
    const message =
      err.response?.status === 401
        ? `${phoneNumber} is not a Reclip staff phone.`
        : err.response?.data?.detail || err.message
    throw new Error(message)
  }
}
