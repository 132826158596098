import { useCallback } from 'react'
import Box from '@mui/material/Box'

const ConfigSelector = ({ config, configs, setConfig }) => {
  const handleChange = useCallback(
    (e) => {
      const newConfig = configs.find((c) => c.type === e.target.value)
      setConfig(newConfig)
    },
    [configs, setConfig]
  )

  return (
    <Box
      sx={{
        p: 0.75,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          minWidth: '150px',
          color: 'text.primary',
          fontWeight: 700,
        },
      }}
    >
      <select onChange={handleChange} value={config.type}>
        {configs.map((c) => (
          <option key={c.type} value={c.type}>
            {c.type}
          </option>
        ))}
      </select>
    </Box>
  )
}

export default ConfigSelector
