import React, { useEffect, useState } from 'react'
import { StreamChat } from 'stream-chat'
import { useChat } from 'store/selectors'
import useChatActions from 'store/actions/chat'
import ViewChannel from './ViewChannel'
import Loader from 'components/core/Loader'
import { Chat } from 'stream-chat-react'
import 'stream-chat-react/dist/css/index.css'
import 'components/Main/Chat/Main/Main.css'
import { useSettings } from 'store/selectors'
import Box from '@mui/material/Box'

const ChatWindow = ({ cid }) => {
  const { data: chat, error } = useChat()
  const { getChat } = useChatActions()
  const [client, setClient] = useState(null)
  const { theme } = useSettings()

  useEffect(() => {
    if (!chat) getChat()
  }, [chat, getChat])

  useEffect(() => {
    if (!chat) return

    const client = StreamChat.getInstance(chat.api_key, {
      enableInsights: true,
      enableWSFallback: true,
      timeout: 5000,
    })

    const connectUser = async () => {
      const { id, name, username, image, is_broadcasting } = chat.user

      await client.connectUser(
        {
          id,
          name,
          username,
          image,
          is_broadcasting,
        },
        chat.token
      )
      setClient(client)
    }

    connectUser()

    return () => client.disconnectUser()
  }, [chat])

  if (!client) return <Loader text="Loading Chat" error={error} />

  return (
    <Box
      sx={{
        height: '100%',
        '& .chat-gutter': {
          display: 'none',
        },
        '& .messaging.str-chat .str-chat__list': {
          paddingRight: '60px !important',
        },
        '& .messaging-channel-header': {
          display: 'none',
        },
      }}
    >
      <Chat client={client} theme={`messaging ${theme}`}>
        <ViewChannel cid={cid} />
      </Chat>
    </Box>
  )
}

export default ChatWindow
