import Box from '@mui/material/Box'
import SearchBar from './SearchBarLoader'
import Breadcrumbs from './Breadcrumbs'
import UpdatedAt from './UpdatedAt'
import { alpha } from '@mui/system/colorManipulator'

const Header = () => {
  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative',
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.7),
      }}
    >
      <SearchBar />
      <Breadcrumbs />
      <UpdatedAt />
    </Box>
  )
}

export default Header
