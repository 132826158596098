import Box from '@mui/material/Box'

const ModelInfo = ({ model }) => {
  const items = [
    {
      name: 'Input token price',
      value:
        '$' + (model.input_token_price * 1000000).toFixed(2) + ' / million',
    },
    {
      name: 'Output token price',
      value:
        '$' + (model.output_token_price * 1000000).toFixed(2) + ' / million',
    },
    {
      name: 'Context window',
      value: model.context_window,
    },
    {
      name: 'Max output tokens',
      value: model.max_output_tokens,
    },
    {
      name: 'Training data end',
      value: model.training_data_end,
    },
  ]

  return (
    <Box sx={{ mt: 1, fontSize: '0.9em' }}>
      {items.map(({ name, value }) => (
        <Box
          key={name}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 310,
          }}
        >
          <Box>{name}</Box>
          <Box>{value}</Box>
        </Box>
      ))}
    </Box>
  )
}

export default ModelInfo
