import Box from '@mui/material/Box'
import GlobalStyles from '@mui/material/GlobalStyles'
import { useMap } from 'store/selectors'
import { toDateString } from './utils'

const Popup = ({ reclipId }) => {
  const {
    data: { reclips },
  } = useMap()
  const reclip = reclips.find((r) => r.id === reclipId)

  if (!reclip) return null

  return (
    <>
      <Box
        sx={{
          borderRadius: '10px',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'divider',
          backgroundColor: 'background.paper',
          color: 'text.primary',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '0.8rem',
        }}
      >
        <Box>
          <b>@{reclip.username}</b>
        </Box>
        <Box>{toDateString(reclip.created_at + 'Z')}</Box>
        <Box>{reclip.clipped_at.address}</Box>
      </Box>
    </>
  )
}

export const PopupStyleOverrides = () => (
  <GlobalStyles
    styles={{
      '.mapboxgl-popup': {
        '& .mapboxgl-popup-tip': {
          borderColor: 'transparent !important',
        },
        '& .mapboxgl-popup-content': {
          background: 'none',
          padding: '6px',
          '& > button': {
            display: 'none',
          },
        },
      },
    }}
  />
)

export default Popup
