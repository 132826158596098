import Box from '@mui/material/Box'
import { useCallback } from 'react'
import { useSelectedMetric, useNetworksMetrics } from 'store/selectors'
import useNetworksActions from 'store/actions/networks'

const MetricSelector = () => {
  const selectedMetric = useSelectedMetric()
  const metrics = useNetworksMetrics()
  const { selectMetric } = useNetworksActions()

  const handleChange = useCallback(
    (event) => {
      selectMetric(event.target.value)
    },
    [selectMetric]
  )

  return (
    <Box
      sx={{
        // mt: 2,
        // mx: 2,
        // mb: 1,
        p: 1,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          color: 'text.primary',
          textTransform: 'capitalize',
        },
      }}
    >
      <select value={selectedMetric} onChange={handleChange}>
        {metrics
          .filter(({ is_number, is_date }) => is_number || is_date)
          .map(({ name }) => (
            <option key={name} value={name}>
              {name.replace(/_/g, ' ')}
            </option>
          ))}
      </select>
    </Box>
  )
}

export default MetricSelector
