export const DEFAULT_METRIC = 'clip_days'
export const HIDDEN_USER_FILL = 'rgba(0,0,0,1)'
export const HIDDEN_USER_STROKE = 'white'
export const NULL_METRIC_FILL = '#565656'
export const MIN_METRIC_HUE = 180
export const MAX_METRIC_HUE = 360
export const LINK_STROKE = 'rgba(255,255,255,0.5)'
export const LINK_STROKE_WIDTH = 1
export const LINK_STROKE_CHAT = '#fffa00'
export const LINK_STROKE_CHAT_HOVERED = 'white'
export const LINK_STROKE_WIDTH_CHAT = 5
export const MIN_NODE_SIZE = 7
export const NODE_HIGHLIGHT_FACTOR = 20
export const LINK_HIGHLIGHT_FACTOR = 15
export const OFFSET_X = -100
