import { useRef } from 'react'
import Box from '@mui/material/Box'
import InfiniteScroll from 'react-infinite-scroll-component'

const ResultsList = ({ items, ListItem, hasMore, handleNextPage, filters }) => {
  const scrollableDiv = useRef(null)
  return (
    <Box
      sx={{ height: '100%', overflow: 'auto' }}
      id="scrollableDiv"
      ref={scrollableDiv}
    >
      <InfiniteScroll
        dataLength={items.length}
        next={items.length === 0 ? undefined : handleNextPage}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        scrollThreshold={0.6}
      >
        {items.map((item, index) => (
          <Box
            sx={{
              py: 2,
              px: 1,
              pr: 2,
              borderBottomColor: 'divider',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              display: 'flex',
            }}
            key={index.toString()}
          >
            <ListItem item={item} highlightText={filters.text} />
          </Box>
        ))}
      </InfiniteScroll>
    </Box>
  )
}

export default ResultsList
