import { useState, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import SearchIcon from '@mui/icons-material/Search'
import api from 'services/api'
import { useAuth } from 'store/selectors'
import Highlighter from 'react-highlight-words'
import Preview from './Preview'
import { toDateString } from '../Networks/utils'
import CopyToClipboardButton from 'components/core/CopyToClipboard'
import EngineSelector from './EngineSelector'
import Divider from '@mui/material/Divider'

const Search = () => {
  const [searchParams, setSearchParams] = useState({
    searchTerm: '',
    engineName: null,
    page: 0,
    limitSearchToUser: false,
  })

  const [engines, setEngines] = useState([])
  const [items, setItems] = useState([])
  const { user } = useAuth()
  const [roundTripTime, setRoundTripTime] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    api.search.listVectorSearchEngines().then((engines) => {
      setEngines(engines)
      setSearchParams((params) => ({
        ...params,
        engineName: engines[0].name,
      }))
    })
  }, [])

  useEffect(() => {
    const { searchTerm, limitSearchToUser, engineName } = searchParams

    if (engineName === null) return

    const now = Date.now()

    if (searchTerm === '') {
      setItems([])
      setRoundTripTime(null)
    } else {
      setItems([])
      setIsLoading(true)

      const userId = limitSearchToUser ? user.id : undefined
      api.search
        .searchReclipsVector(searchTerm, userId, engineName)
        .then((items) => {
          setItems(items)
          setIsLoading(false)
          setRoundTripTime(Date.now() - now)
        })
    }
  }, [searchParams, user])

  const handleSearchTermChange = useCallback((e) => {
    setItems([])
    setSearchParams((params) => ({
      ...params,
      searchTerm: e.target.value,
      page: 0,
    }))
  }, [])

  const handleToggleLimit = useCallback((event) => {
    setSearchParams((params) => ({
      ...params,
      limitSearchToUser: event.target.checked,
      page: 0,
    }))
  }, [])

  const handleEngineChange = useCallback((engineName) => {
    setSearchParams((params) => ({
      ...params,
      engineName,
      page: 0,
    }))
  }, [])

  // const handleNextPage = useCallback(() => {
  //   setSearchParams((params) => ({
  //     ...params,
  //     page: params.page + 1,
  //   }))
  // }, [])

  const getHighlightTerms = (item) =>
    searchParams.searchTerm === '' ? [] : item.chunks.map((c) => c.chunk)

  const selectedEngine = engines
    ? engines.find((e) => e.name === searchParams.engineName)
    : null

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        }}
      >
        <Box
          sx={{
            px: 2,
            backgroundColor: 'background.paper',
            display: 'flex',
            alignItems: 'center',
            '& > input': {
              ml: 2,
              height: '60px',
              flex: 1,
              border: 0,
              outline: 0,
              backgroundColor: 'transparent',
              color: 'text.primary',
              fontSize: '20px',
            },
          }}
        >
          <SearchIcon sx={{ fontSize: 32 }} />
          <input
            type="text"
            defaultValue={searchParams.searchTerm}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSearchTermChange(e)
            }}
            onChange={(e) => {
              if (e.target.value === '') handleSearchTermChange(e)
            }}
            placeholder={
              searchParams.limitSearchToUser
                ? `search @${user.username}'s reclips`
                : 'search reclips'
            }
            autoFocus
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>Search @{user.username}'s reclips only</Box>
            <Checkbox
              sx={{ ml: 0.5 }}
              checked={searchParams.limitSearchToUser}
              onChange={handleToggleLimit}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>
        {searchParams.engineName && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ fontWeight: 'bold', mr: 2 }}>Engine:</Box>
              <EngineSelector
                engineName={searchParams.engineName}
                engineNames={engines.map((e) => e.name)}
                setEngineName={handleEngineChange}
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              {Object.keys(selectedEngine)
                .filter(
                  (field) =>
                    !['name', 'index_collection', 'index_name'].includes(field)
                )
                .map((field) => (
                  <Box key={field} sx={{ pl: 2 }}>
                    <b>{field}:</b> {selectedEngine[field]}
                  </Box>
                ))}
            </Box>
          </Box>
        )}
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            fontStyle: 'italic',
          }}
        >
          {isLoading ? (
            <Box sx={{ fontStyle: 'italic' }}>Loading...</Box>
          ) : (
            <>
              <Box sx={{ fontStyle: 'italic' }}>
                Showing {items.length}{' '}
                {items.length === 1 ? 'reclip' : 'reclips'}{' '}
              </Box>
              {roundTripTime !== null && (
                <Box>response time: {roundTripTime} ms</Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'auto',
          }}
        >
          {items.map((item, index) => (
            <Box
              sx={{
                p: 2,
                borderBottomColor: 'divider',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                display: 'flex',
              }}
              key={index.toString()}
            >
              <Preview reclip={item} />
              <Box sx={{ flex: 1, ml: 2 }}>
                <Box>
                  <b>Auto-title: </b>
                  {item.title_auto && (
                    <Highlighter
                      searchWords={getHighlightTerms(item)}
                      textToHighlight={item.title_auto}
                      autoEscape={true}
                    />
                  )}
                </Box>
                <Box>
                  <b>Title: </b>
                  {item.title && (
                    <Highlighter
                      searchWords={getHighlightTerms(item)}
                      textToHighlight={item.title}
                      autoEscape={true}
                    />
                  )}
                </Box>
                <Box>
                  <b>Username: </b>@{item.username}
                </Box>
                <Box>
                  <b>Created on: </b>
                  {toDateString(item.created_at)}
                </Box>
                {item.chunks && (
                  <Box>
                    <b>Score(s): </b>
                    {item.chunks.map((c) => c.score.toFixed(2)).join(', ')}
                  </Box>
                )}

                <Box>
                  <b>Transcript </b>
                  <CopyToClipboardButton
                    title="Copy transcript"
                    data={item.text}
                  />
                  <br />
                  {item.text && (
                    <Highlighter
                      searchWords={getHighlightTerms(item)}
                      textToHighlight={item.text}
                      autoEscape={true}
                    />
                  )}
                </Box>
              </Box>
              <Box sx={{ mr: 1 }}>
                <CopyToClipboardButton title="Copy id" data={item.source_id} />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Search
