import { useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import Avatar from 'components/core/Avatar'
import {
  useSelectedMetric,
  useNetworksMetrics,
  useSelectedNetworkNodes,
  useSelectedMetricRange,
} from 'store/selectors'
import useNetworksActions from 'store/actions/networks'
import { interpolateColor, toDateString } from '../../../utils'
import MetricSelector from '../../../MetricSelector'
import VirtualizedList from 'components/core/VirtualizedList'

const Metrics = () => {
  const metrics = useNetworksMetrics()
  const selectedMetric = useSelectedMetric()
  const nodes = useSelectedNetworkNodes()
  const metricRange = useSelectedMetricRange()
  const { selectNode, highlightNodes } = useNetworksActions()

  const [sortOrder /* setSortOrder */] = useState('desc')

  const sortedNodes = useMemo(() => {
    const modifiedNodes = nodes.filter(
      (node) =>
        node.is_reclip_user &&
        node.metrics[selectedMetric] !== undefined &&
        node.metrics[selectedMetric] !== null
    )

    const out = modifiedNodes.sort(
      (a, b) => b.metrics[selectedMetric] - a.metrics[selectedMetric]
    )

    if (sortOrder === 'asc') out.reverse()

    return out
  }, [nodes, selectedMetric, sortOrder])

  const metric = useMemo(() => {
    return metrics.find((metric) => metric.name === selectedMetric)
  }, [metrics, selectedMetric])

  const RowComponent = ({ item: { id, user, metrics } }) => (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      }}
      onMouseEnter={highlightNodes.bind(null, [id])}
      onMouseLeave={highlightNodes.bind(null, [])}
      onClick={() => {
        selectNode(id)
        highlightNodes([])
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <Avatar name={user?.full_name} image={user?.picture} size={30} />
        <Box sx={{ ml: 2 }}>@{user?.username}</Box>
      </Box>
      <Box sx={{ maxWidth: 100 }}>
        {metric.is_date
          ? toDateString(metrics[selectedMetric])
          : metrics[selectedMetric]}
      </Box>
      <Box
        sx={{
          ml: 6,
          mr: 1,
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: interpolateColor(
            metrics[selectedMetric],
            metricRange
          ),
        }}
      />
    </Box>
  )

  return (
    <>
      <Box sx={{ mx: 4, mt: 3, mb: 0, minWidth: 240, alignSelf: 'center' }}>
        <MetricSelector />
      </Box>
      <Box sx={{ p: 2, flex: 1 }}>
        <VirtualizedList
          items={sortedNodes}
          RowComponent={RowComponent}
          emptyText="No metrics available."
          divider
        />
      </Box>
    </>
  )
}

export default Metrics
