import CopyIcon from '@mui/icons-material/CopyAllOutlined'
import TooltipIcon from './Tooltip'

const CopyToClipboardButton = ({ data, title, placement = 'top' }) => {
  const handleClick = () => {
    navigator.clipboard.writeText(data)
  }

  return (
    <TooltipIcon
      Icon={CopyIcon}
      title={title}
      onClick={handleClick}
      placement={placement}
    />
  )
}

export default CopyToClipboardButton
