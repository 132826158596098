import Box from '@mui/material/Box'
import { useSelectedNode, useSelectedNodeConnections } from 'store/selectors'
import { HiddenIcon } from 'components/core/Icons'
import UserList from 'components/Main/Networks/Sidebar/core/UserList'

const SelectedHiddenUser = () => {
  const node = useSelectedNode()
  const connections = useSelectedNodeConnections()

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          p: 3,
          pb: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <HiddenIcon fontSize="2.4em" />
        </Box>
        <Box sx={{ fontWeight: 700, fontSize: '1.4em' }}>
          {node.phone_number}
        </Box>
        <Box sx={{ fontSize: '0.9em', display: 'flex', mt: 1 }}>
          <Box>{connections.length} reclip connections</Box>
        </Box>
      </Box>
      <UserList nodes={connections} />
    </Box>
  )
}

export default SelectedHiddenUser
