import { useCallback } from 'react'
import Box from '@mui/material/Box'
import NamedDivider from 'components/core/NamedDivider'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { DEFAULT_RADIUS, DEFAULT_SLOP } from './const'

const Filters = ({ filters, setFilters }) => {
  const handleInputChange = useCallback(
    (e) => {
      setFilters((f) => ({
        ...f,
        text: e.target.value,
      }))
    },
    [setFilters]
  )

  const handleRadiusChange = useCallback(
    (e) => {
      setFilters((f) => ({
        ...f,
        location: {
          ...f.location,
          radius: e.target.value ? +e.target.value : null,
        },
      }))
    },
    [setFilters]
  )

  const handleMidChange = useCallback(
    (e) => {
      setFilters((f) => ({
        ...f,
        age: {
          ...f.age,
          mid: e.target.value ? +e.target.value : null,
        },
      }))
    },
    [setFilters]
  )

  const handleSlopChange = useCallback(
    (e) => {
      setFilters((f) => ({
        ...f,
        age: {
          ...f.age,
          slop: +e.target.value,
        },
      }))
    },
    [setFilters]
  )

  const clearText = useCallback(() => {
    setFilters((f) => ({ ...f, text: '' }))
  }, [setFilters])

  const clearCenter = useCallback(() => {
    setFilters((f) => ({
      ...f,
      location: { ...f.location, center: null, radius: DEFAULT_RADIUS },
    }))
  }, [setFilters])

  const clearAge = useCallback(() => {
    setFilters((f) => ({ ...f, age: { mid: null, slop: DEFAULT_SLOP } }))
  }, [setFilters])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          '& input': {
            width: '100%',
            border: 0,
            backgroundColor: 'transparent',
            color: 'text.primary',
            fontSize: '1.1em',
          },
        }}
      >
        <SearchIcon sx={{ mr: 1 }} />
        <input
          placeholder="Search Schools"
          value={filters.text}
          onChange={handleInputChange}
        />
        <CloseIcon
          onClick={clearText}
          sx={{
            fontSize: 20,
            cursor: 'pointer',
            '&:hover': { opacity: 0.6 },
            visibility: filters.text ? 'visible' : 'hidden',
          }}
        />
      </Box>
      <NamedDivider title="location filter" sx={{ mx: 0 }} />
      <Box sx={{ p: 1 }}>
        {!filters.location.center ? (
          <Box>Click on map to apply location filter.</Box>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ fontWeight: 700, mr: 2, width: 45 }}>center:</Box>
              <Box sx={{ flex: 1 }}>
                [{filters.location.center.lng.toFixed(4)},{' '}
                {filters.location.center.lat.toFixed(4)}]
              </Box>
              <CloseIcon
                onClick={clearCenter}
                sx={{
                  fontSize: 20,
                  cursor: 'pointer',
                  '&:hover': { opacity: 0.6 },
                  visibility: filters.location.center ? 'visible' : 'hidden',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 2,
              }}
            >
              <Box sx={{ fontWeight: 700, mr: 2, width: 45 }}>radius: </Box>
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  px: 1,
                  py: 0.5,
                  borderRadius: '4px',
                  width: 100,
                  '& input': {
                    width: '100%',
                    border: 0,
                    backgroundColor: 'transparent',
                    color: 'text.primary',
                    fontSize: '1.1em',
                  },
                }}
              >
                <input
                  type="text"
                  value={filters.location.radius || ''}
                  onChange={handleRadiusChange}
                />
              </Box>
              <Box sx={{ ml: 1 }}>miles</Box>
            </Box>
          </>
        )}
      </Box>
      <NamedDivider title="age filter" sx={{ mx: 0 }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
        }}
      >
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ fontWeight: 700, mr: 1 }}>age</Box>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              px: 1,
              py: 0.5,
              borderRadius: '4px',
              width: 80,
              '& input': {
                width: '100%',
                border: 0,
                backgroundColor: 'transparent',
                color: 'text.primary',
                fontSize: '1.1em',
              },
            }}
          >
            <input
              type="text"
              value={filters.age.mid || ''}
              onChange={handleMidChange}
            />
          </Box>
          {filters.age.mid !== null && (
            <>
              <Box sx={{ fontWeight: 700, mr: 1, ml: 4 }}>+/-</Box>
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  px: 1,
                  py: 0.5,
                  borderRadius: '4px',
                  width: 80,
                  '& input': {
                    width: '100%',
                    border: 0,
                    backgroundColor: 'transparent',
                    color: 'text.primary',
                    fontSize: '1.1em',
                  },
                }}
              >
                <input
                  type="text"
                  value={filters.age.slop}
                  onChange={handleSlopChange}
                />
              </Box>
            </>
          )}
        </Box>
        <CloseIcon
          onClick={clearAge}
          sx={{
            fontSize: 20,
            visibility: filters.age.mid !== null ? 'visible' : 'hidden',
            cursor: 'pointer',
            '&:hover': { opacity: 0.6 },
          }}
        />
      </Box>
    </Box>
  )
}

export default Filters
