import Box from '@mui/material/Box'
import Suggestion from './Suggestion'
import { useRef, forwardRef, useImperativeHandle } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

const SuggestionList = forwardRef(
  ({ suggestions, handleNextPage, hasMore }, ref) => {
    const scrollableDiv = useRef(null)

    useImperativeHandle(
      ref,
      () => ({
        scrollToTop: () => {
          if (scrollableDiv.current !== null)
            scrollableDiv.current.scrollTop = 0
        },
      }),
      []
    )

    return (
      <Box
        sx={{ height: '100%', overflow: 'auto' }}
        id="scrollableDiv"
        ref={scrollableDiv}
      >
        <InfiniteScroll
          dataLength={suggestions.length}
          next={suggestions.length === 0 ? undefined : handleNextPage}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.6}
        >
          {suggestions.map((suggestion, index) => (
            <Suggestion key={index.toString()} suggestion={suggestion} />
          ))}
        </InfiniteScroll>
      </Box>
    )
  }
)

export default SuggestionList
