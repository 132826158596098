import { useMemo } from 'react'
import { useSelectedNetworkNodes } from 'store/selectors'
import UserList from 'components/Main/Networks/Sidebar/core/UserList'

const NetworkUsers = () => {
  const nodes = useSelectedNetworkNodes()

  const filteredNodes = useMemo(() => {
    if (!nodes) return []
    return nodes.filter((node) => node.is_reclip_user)
  }, [nodes])

  if (!filteredNodes) return null

  return <UserList nodes={filteredNodes} />
}

export default NetworkUsers
