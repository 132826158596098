import Box from '@mui/material/Box'
import LivePhotoIcon from '@mui/icons-material/AcUnit'

const ReclipPreview = ({ reclip, sizeRatio = 1 }) => {
  const color1 = reclip.background_color1
  const color2 = reclip.background_color2
  const hasCoverPhoto = !!reclip?.cover_photo
  const hasLivePhoto =
    hasCoverPhoto && typeof reclip.cover_photo.video_url === 'string'

  return (
    <Box
      sx={{
        width: 100 * sizeRatio,
        height: 150 * sizeRatio,
        background: hasCoverPhoto
          ? undefined
          : `linear-gradient(to top, ${color1}, ${color2})`,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 3,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 700,
        position: 'relative',
      }}
    >
      {hasCoverPhoto && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderRadius: 2,
            backgroundImage: `url(${reclip.cover_photo.url})`,
            backgroundSize: 'cover',
          }}
        />
      )}
      {hasLivePhoto && (
        <LivePhotoIcon
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            fontSize: '1.2em',
            cursor: 'pointer',
          }}
          onClick={() => window.open(reclip.cover_photo.video_url, '_blank')}
        />
      )}
    </Box>
  )
}

export default ReclipPreview
