import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import { useModal } from 'store/selectors'
import ReclipPlayer from 'components/core/ReclipPlayer'

const ReclipPlayerModal = () => {
  const {
    isOpen,
    params: { source_id },
  } = useModal('reclipPlayer')
  const { closeModal } = useModalsActions()

  return (
    <Dialog open={isOpen} onClose={closeModal.bind(null, 'reclipPlayer')}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {source_id && (
          <Box
            sx={{
              height: '750px',
              width: '420px',
            }}
          >
            <ReclipPlayer source_id={source_id} />
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default ReclipPlayerModal
