import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

const RadioButtons = ({ items, value, onChange }) => {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e) => {
          onChange(items[+e.target.value].value)
        }}
        value={items.findIndex((i) => i.value === value).toString()}
      >
        {items.map((item, index) => (
          <FormControlLabel
            key={index.toString()}
            value={index.toString()}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioButtons
