import Box from '@mui/material/Box'

const circleStyles = (backgroundColor) => ({
  width: 10,
  height: 10,
  backgroundColor,
  borderRadius: '50%',
  mr: 1,
})

const Legend = ({ colors }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      color: 'text.secondary',
      fontSize: '14px',
    }}
  >
    <Box sx={circleStyles(colors.SCHEDULED)} />
    <Box sx={{ mr: 4 }}>scheduled</Box>
    <Box sx={circleStyles(colors.ARCHIVED)} />
    <Box>sent</Box>
  </Box>
)

export default Legend
