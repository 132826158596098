import Box from '@mui/material/Box'
import Avatar from 'components/core/Avatar'
import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const Factor = ({ name, value, score }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <Box sx={{ fontWeight: 700, width: 200 }}>{name}</Box>
    <Box sx={{ width: 200, textAlign: 'right', mr: 4 }}>{value}</Box>
    <Box sx={{ flex: 1, textAlign: 'right' }}>{score}</Box>
  </Box>
)

const toDateAndTimeString = (d) => {
  if (!d) return null
  return dayjs().to(dayjs(d + 'Z'))
}

const getAge = (d) => {
  return dayjs().diff(dayjs(d), 'year')
}

const Suggestion = ({ suggestion }) => {
  return (
    <Box
      sx={{
        py: 2,
        px: 2.5,
        borderStyle: 'solid',
        borderColor: 'divider',
        borderWidth: 1,
        borderRadius: 2,
        my: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar
          name={suggestion.user.full_name}
          image={suggestion.user.picture}
          size={50}
        />
        <Box sx={{ flex: 1, ml: 2, minWidth: '180px' }}>
          <Box sx={{ fontWeight: 700, fontSize: '0.9em' }}>
            @{suggestion.user.username} | {suggestion.user.full_name}
          </Box>
          <Box sx={{ fontSize: '0.9em' }}>
            age: {getAge(suggestion.date_of_birth)}
            {/* | times suggested:{' '}
            {suggestion.times_suggested} */}
          </Box>
        </Box>
        <Box
          onClick={(e) => console.log(suggestion.score_details)}
          sx={{ fontWeight: 700 }}
        >
          {suggestion.score}
        </Box>
      </Box>
      <Box sx={{ fontSize: '0.8em', mt: 1 }}>
        <Factor
          name="last_active_at"
          value={toDateAndTimeString(suggestion.last_active_at)}
          score={suggestion.score_details.last_active_at}
        />
        <Factor
          name="joined_at"
          value={toDateAndTimeString(suggestion.joined_at)}
          score={suggestion.score_details.joined_at}
        />
        <Factor
          name="num_just_clipped_in_story"
          value={suggestion.num_just_clipped_in_story}
          score={suggestion.score_details.num_just_clipped_in_story}
        />
        <Factor
          name="num_shares_in_story"
          value={suggestion.num_shares_in_story}
          score={suggestion.score_details.num_shares_in_story}
        />
        <Factor
          name="shows_story_to_anyone"
          value={suggestion.shows_story_to_anyone ? 'true' : 'false'}
          score={suggestion.score_details.shows_story_to_anyone}
        />
        <Factor
          name="has_added_friend_back"
          value={suggestion.has_added_friend_back ? 'true' : 'false'}
          score={suggestion.score_details.has_added_friend_back}
        />
        <Factor
          name="last_suggested_at"
          value={
            suggestion.last_suggested_at
              ? toDateAndTimeString(suggestion.last_suggested_at)
              : 'never'
          }
          score={suggestion.score_details.last_suggested_at}
        />
      </Box>
    </Box>
  )
}

export default Suggestion
