import axios from 'axios'
import { ADMIN_API_URL } from 'const'

export const createUser = async ({ phoneNumber, reclipContactPhones }) => {
  try {
    const url = `${ADMIN_API_URL}/suggestions/onboarding/create_user`
    const { data } = await axios.put(url, {
      phone_number: phoneNumber,
      reclip_contact_phones: reclipContactPhones,
    })
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const deleteUser = async (userId) => {
  try {
    const url = `${ADMIN_API_URL}/suggestions/onboarding/delete_user/${userId}`
    const { data } = await axios.put(url)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const addFriend = async ({ addingUserId, addedUserId }) => {
  try {
    const url = `${ADMIN_API_URL}/suggestions/onboarding/add_friend`
    const { data } = await axios.put(url, {
      adding_user_id: addingUserId,
      added_user_id: addedUserId,
    })
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
