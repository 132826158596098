import Box from '@mui/material/Box'
import { useSelectedLink } from 'store/selectors'
import { FriendIcon, NonFriendIcon } from 'components/core/Icons'
import ChatInfo from './ChatInfo'
import UserInfo from 'components/Main/Networks/Sidebar/core/UserInfo'
import useNetworksActions from 'store/actions/networks'

const SelectedFriendship = () => {
  const link = useSelectedLink()
  const { friendship } = link
  const { highlightNodes, selectNode } = useNetworksActions()

  const Profile = ({ node, isFriend }) => (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <UserInfo
        user={node?.user}
        onMouseEnter={highlightNodes.bind(null, [node.id])}
        onMouseLeave={highlightNodes.bind(null, [])}
        onClick={selectNode.bind(null, node.id)}
      />
      <Box sx={{ mt: 1, fontSize: '1.8em' }}>
        {isFriend ? <FriendIcon /> : <NonFriendIcon />}
      </Box>
    </Box>
  )

  const [node0, node1] = friendship.nodes
  const { friends } = friendship

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* <FriendsTable friendship={friendship} /> */}
      <Box sx={{ display: 'flex', width: '80%', pt: 4, pb: 2 }}>
        <Box sx={{ width: '50%' }}>
          <Profile node={node0} isFriend={friends.includes(node0.user?.id)} />
        </Box>
        <Box sx={{ width: '50%' }}>
          <Profile node={node1} isFriend={friends.includes(node1.user?.id)} />
        </Box>
      </Box>
      <ChatInfo link={link} />
    </Box>
  )
}

export default SelectedFriendship
