const BATCH_SIZE = 30

// query channels with automatic retry on timeout
async function queryChannels(client, filters, sort, options) {
  while (true) {
    try {
      return await client.queryChannels(filters, sort, options)
    } catch (e) {
      console.log('query error:', e.message)
      if (!e.message.startsWith('timeout')) throw e
    }
  }
}

export async function getChannel(client, cid) {
  const channels = await queryChannels(
    client,
    {
      type: 'messaging',
      cid,
    },
    [],
    {
      watch: true,
      state: true,
      presence: true,
    }
  )
  return channels[0]
}

export async function getChannels(
  client,
  searchTerm,
  filterType,
  selectedUser,
  offset
) {
  const filters = (() => {
    if (selectedUser)
      return {
        type: 'messaging',
        is_team_reclip_channel: true, // NOTE
        members: { $in: [selectedUser.id] },
      }

    return {
      type: 'messaging',
      is_team_reclip_channel: true,
      [`team_reclip_${filterType}`]: true,
    }
  })()

  if (searchTerm)
    filters['$or'] = [
      { 'member.user.username': { $autocomplete: searchTerm } },
      { 'member.user.name': { $autocomplete: searchTerm } },
    ]

  const sort =
    filterType === 'faves' ? [{ last_updated: -1 }] : [{ last_message_at: -1 }]

  const limit = BATCH_SIZE

  let channels = await queryChannels(client, filters, sort, {
    watch: true,
    state: true,
    presence: true,
    limit,
    offset,
  })

  const hasMore = channels.length === limit

  return { channels, hasMore }
}

export async function markChannelAsRead(channel) {
  if (channel.data.team_reclip_unread)
    await channel.updatePartial({ unset: ['team_reclip_unread'] })
}
