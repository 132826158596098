import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Tabs from '../../core/Tabs'
import { useSelectedNetwork } from 'store/selectors'
import { NetworkIcon } from 'components/core/Icons'
import UserIcon from '@mui/icons-material/Person'
import HiddenIcon from '@mui/icons-material/VisibilityOff'
import MetricsIcon from '@mui/icons-material/BarChart'
import FriendsIcon from '@mui/icons-material/People'
import Users from './Users'
import Hidden from './Hidden'
import Metrics from './Metrics'
import Friendships from './Friendships'

const TABS = [
  {
    type: 'users',
    Icon: UserIcon,
    Component: Users,
  },
  {
    type: 'friendships',
    Icon: FriendsIcon,
    Component: Friendships,
  },
  {
    type: 'hidden',
    Icon: HiddenIcon,
    Component: Hidden,
  },
  {
    type: 'metrics',
    Icon: MetricsIcon,
    Component: Metrics,
  },
]

const NetworkOverview = () => {
  const [tab, setTab] = useState(TABS[0].type)
  const selectedNetwork = useSelectedNetwork()
  const { Component } = TABS.find((t) => t.type === tab)

  useEffect(() => {
    setTab('users')
  }, [selectedNetwork?.id])

  if (!selectedNetwork) return null

  const {
    name,
    num_reclip_nodes,
    num_friendships,
    num_non_reclip_nodes,
    avg_reclip_connections_per_user,
  } = selectedNetwork

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <NetworkIcon fontSize="1.8em" />
        </Box>
        <Box sx={{ fontWeight: 700, fontSize: '1.4em' }}>{name}</Box>
        <Box sx={{ fontSize: '0.9em', display: 'flex', mt: 1 }}>
          <Box>
            {num_reclip_nodes} {num_reclip_nodes === 1 ? 'user' : 'users'}
          </Box>
          <Box sx={{ mx: 1 }}>|</Box>
          <Box>
            {num_friendships}{' '}
            {num_friendships === 1 ? 'friendship' : 'friendships'}
          </Box>
          <Box sx={{ mx: 1 }}>|</Box>
          <Box>
            {num_non_reclip_nodes} hidden{' '}
            {num_non_reclip_nodes === 1 ? 'user' : 'users'}
          </Box>
          <Box sx={{ mx: 1 }}>|</Box>
          <Box>{avg_reclip_connections_per_user.toFixed(2)} ρ</Box>
        </Box>
      </Box>
      <Box sx={{ px: 2 }}>
        <Tabs tabs={TABS} activeTab={tab} onChange={setTab} />
      </Box>
      <Component />
    </Box>
  )
}

export default NetworkOverview
