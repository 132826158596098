// adapted from https://www.d3-graph-gallery.com/graph/histogram_basic.html

import { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import Box from '@mui/material/Box'
import { interpolateColor, toDateString } from './utils'

const Histogram = ({
  points,
  num_bins = 30,
  is_date = false,
  x_label = null,
  y_label = null,
}) => {
  const chartRef = useRef(null)

  useEffect(() => {
    if (!points) return

    const margin = { top: 6, right: 10, bottom: 55, left: 65 }
    const width = chartRef.current.offsetWidth - margin.left - margin.right
    const height = chartRef.current.offsetHeight - margin.top - margin.bottom

    const svg = d3
      .select(chartRef.current)
      .append('svg')
      // .style('outline', '1px red solid')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
    // .style('outline', '1px orange solid')

    const xMin = d3.min(points, (d) => d)
    const xMax = d3.max(points, (d) => d)
    const x = d3.scaleLinear().domain([xMin, xMax]).range([0, width])

    const xAxisG = svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))

    if (x_label)
      xAxisG
        .append('text')
        .attr('class', 'axis-label')
        .attr('x', width / 2)
        .attr('y', 50)
        .style('fill', 'white')
        .style('font-size', '16px')
        .text(x_label)

    if (is_date) xAxisG.selectAll('.tick text').text(toDateString)

    const histogram = d3
      .histogram()
      .value((d) => d)
      .domain(x.domain())
      .thresholds(x.ticks(num_bins))

    const bins = histogram(points)

    const y = d3.scaleLinear().range([height, 0])
    y.domain([0, d3.max(bins, (d) => d.length)])

    const yAxisG = svg.append('g').call(d3.axisLeft(y))

    if (y_label)
      yAxisG
        .append('text')
        .attr('class', 'axis-label')
        .attr('x', -(height / 2 - margin.bottom))
        .attr('y', -50)
        .attr('transform', `rotate(-90)`)
        .style('fill', 'white')
        .style('font-size', '16px')
        .text(y_label)

    const barWidth = width / bins.length
    const barSpacing = 0

    svg
      .selectAll('rect')
      .data(bins)
      .enter()
      .append('rect')
      .attr('x', 1)
      .attr(
        'transform',
        (d, i) => `translate(${i * barWidth + 0.5 * barSpacing},${y(d.length)})`
      )
      .attr('width', (d) => barWidth - barSpacing)
      .attr('height', (d) => height - y(d.length))
      .style('fill', (d) => interpolateColor(d.x0, { min: xMin, max: xMax }))

    const histContainer = chartRef.current

    return function cleanup() {
      histContainer.innerHTML = ''
    }
  }, [points, num_bins, is_date, x_label, y_label])

  return <Box ref={chartRef} sx={{ height: '100%' }} />
}

export default Histogram
