import { useCallback } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ViewShareIcon from '@mui/icons-material/Launch'
import dayjs from 'dayjs'
import Loader from 'components/core/Loader'

function toDateString(d) {
  return dayjs(d).format('M/D/YY')
}

const Table = ({ shares, isLoading }) => {
  const viewShare = useCallback((url) => {
    window.open(url, '_blank')
  }, [])

  if (isLoading)
    return (
      <Box sx={{ pt: '150px', display: 'flex', justifyContent: 'center' }}>
        <Loader text="Loading shared clips" />
      </Box>
    )

  return (
    <Box
      sx={{
        maxHeight: '600px',
        overflow: 'auto',
        fontSize: '0.9em',
        '& table': {
          borderCollapse: 'collapse',
          width: '100%',
        },
        '& th, td': {
          padding: 1,
          textAlign: 'left',
        },
        '& tbody tr:not(:last-of-type) td': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        },
        '& tbody tr td': {
          cursor: 'default',
        },
        '& th': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <table>
        <thead>
          <tr>
            <th>Shared On</th>
            <th>User Joined</th>
            <th>Username</th>
            <th>Title</th>
            <th>Has Realpic</th>
            <th>Clipped On</th>
            <th>View</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {shares.map(
            ({
              id,
              share_created_at,
              cover_photo_url,
              reclip_created_at,
              username,
              share_url,
              user_created_at,
              title,
            }) => (
              <tr key={id}>
                <td>
                  <Box sx={{ whiteSpace: 'nowrap' }}>
                    {toDateString(share_created_at)}
                  </Box>
                </td>
                <td>
                  <Box sx={{ whiteSpace: 'nowrap' }}>
                    {toDateString(user_created_at)}
                  </Box>
                </td>
                <td>{username}</td>
                <td>{title}</td>
                <td>{cover_photo_url ? 'Yes' : 'No'}</td>
                <td>
                  <Box sx={{ whiteSpace: 'nowrap' }}>
                    {toDateString(reclip_created_at)}
                  </Box>
                </td>
                <td>
                  <IconButton
                    size="small"
                    onClick={viewShare.bind(null, share_url)}
                  >
                    <ViewShareIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {!isLoading && shares.length === 0 && (
        <Box sx={{ fontStyle: 'italic', mt: 4 }}>
          There are no shared clips that match the given filters.
        </Box>
      )}
    </Box>
  )
}

export default Table
