import { useEffect, useState } from 'react'
import { useSelectedUserProfile } from 'store/selectors'
import api from 'services/api'
import SuggestionList from './SuggestionList'
import Loader from 'components/core/Loader'
import Box from '@mui/material/Box'

const Suggestions = () => {
  const { user } = useSelectedUserProfile()
  const [suggestions, setSuggestions] = useState(null)

  useEffect(() => {
    setSuggestions(null)
    api.networks.getSuggestions(user.id).then(setSuggestions)
  }, [user])

  if (suggestions === null)
    return (
      <Box sx={{ height: 80 }}>
        <Loader size={30} />
      </Box>
    )

  return (
    <SuggestionList
      nodes={suggestions}
      emptyText={`@${user.username} has no suggestions.`}
    />
  )
}

export default Suggestions
