import React from 'react'
import Box from '@mui/material/Box'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  tab: {
    padding: 1,
    borderTop: (theme) => `1px ${theme.palette.divider} solid`,
    borderBottom: (theme) => `1px ${theme.palette.divider} solid`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    flex: 1,
  },
  active: {
    backgroundColor: (theme) => theme.palette.action.selected,
    cursor: 'default',
  },
}

const Tabs = ({ tabs, activeTab, onChange }) => {
  return (
    <Box sx={styles.root}>
      {tabs.map(({ type, Icon }) => (
        <Box
          key={type}
          sx={{
            ...styles.tab,
            ...(activeTab === type ? styles.active : {}),
            '&:hover': {
              backgroundColor: activeTab === type ? undefined : 'action.hover',
            },
          }}
          onClick={() => onChange(type)}
        >
          <Icon />
          <Box sx={{ fontSize: '0.7em' }}>{type}</Box>
        </Box>
      ))}
    </Box>
  )
}

export default Tabs
