import { useRef, useCallback, useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import { toDateString } from 'components/Main/Networks/utils'

const VIEWER_WIDTH = 324
const VIEWER_HEIGHT = 600

const ReclipViewer = ({ reclip }) => {
  const { color1, color2 } = (() => {
    if (!reclip) return { color1: 'black', color2: 'black' }
    if (reclip.background) return reclip.background
    return {
      color1: reclip.backgroundColor1,
      color2: reclip.backgroundColor2,
    }
  })()
  const hasCoverPhoto = !!reclip?.coverPhoto
  const [time, setTime] = useState(0)
  const audioRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const onTimeUpdate = useCallback(() => {
    setTime(audioRef.current.currentTime)
  }, [])

  const caption = useMemo(() => {
    const { captions } = reclip
    for (let i = 0; i < captions.length; i++) {
      const { start, end, text } = captions[i]
      if (time > start && time < end) return text
    }

    return null
  }, [reclip, time])

  const image = useMemo(() => {
    const { images } = reclip
    for (let i = 0; i < images.length; i++) {
      const { start, end } = images[i]
      if (time > start && time < end) return images[i]
    }

    return null
  }, [reclip, time])

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          width: VIEWER_WIDTH,
          height: VIEWER_HEIGHT,
          background: `linear-gradient(to bottom, ${color1}, ${color2})`,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {hasCoverPhoto && !isPlaying && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              borderRadius: 2,
              backgroundImage: `url(${reclip.coverPhoto.url})`,
              backgroundSize: 'cover',
            }}
          />
        )}
        <Box
          sx={{
            fontSize: '3em',
            fontWeight: 700,
            textTransform: 'uppercase',
            px: 6,
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            transform: 'translateY(-50%)',
            zIndex: 2,
          }}
        >
          {caption}
        </Box>
        {image && (
          <Box
            sx={{
              position: 'absolute',
              top: `${image.position.y * 100}%`,
              left: `${image.position.x * 100}%`,
              width: '100%',
              transform: `translate(-50%, -50%) rotate(${image.transform.rotation}rad) scale(${image.transform.scale})`,
              zIndex: 1,
              '& img': {
                width: '100%',
              },
            }}
          >
            <img src={image.url} alt="gif" />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: 400,
          height: VIEWER_HEIGHT,
          ml: 4,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ height: VIEWER_HEIGHT - 60, overflow: 'auto', pr: '20px' }}>
          <Box>
            <b>Title: </b>
            {reclip.title || 'New Reclip'}
          </Box>
          <Box>
            <b>From: </b>@{reclip.message.username}
          </Box>
          <Box>
            <b>Received: </b>
            {toDateString(reclip.message.created_at)}
          </Box>
          <Box>
            <b>Reclip ID: </b>
            {reclip.reclipId}
          </Box>
          <Box sx={{ flex: 1 }}>
            <b>Transcript: </b>
            {reclip.captions
              .map(({ text }) => text)
              .join(' ')
              .trim()}
          </Box>
        </Box>
        <Box
          sx={{
            mt: 2,
            '& audio': {
              width: '100%',
              display: 'block',
              height: '60px',
            },
          }}
        >
          <audio
            ref={audioRef}
            autoPlay
            onPlay={setIsPlaying.bind(null, true)}
            onPause={setIsPlaying.bind(null, false)}
            onTimeUpdate={onTimeUpdate}
            controls
          >
            <source
              src={reclip.audio.asset_url}
              type={reclip.audio.mime_type}
            />
            Your browser does not support the audio element.
          </audio>
        </Box>
      </Box>
    </Box>
  )
}

export default ReclipViewer
