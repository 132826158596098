import useModalsActions from 'store/actions/modals'
import Box from '@mui/material/Box'
import ViewIcon from '@mui/icons-material/Visibility'

const ViewButton = ({ message }) => {
  const { openModal } = useModalsActions()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ViewIcon
        sx={{
          '&:hover': {
            color: 'primary.main',
            cursor: 'pointer',
          },
        }}
        onClick={openModal.bind(null, 'message', { message })}
      />
    </Box>
  )
}

export default ViewButton
