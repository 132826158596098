import { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import api from 'services/api'
import ChatWindow from './ChatWindow'
import Button from '@mui/material/Button'
import RadioButtons from 'components/core/RadioButtons'

const DEFAULT_MESSAGE =
  "hey we loved this clip! do you mind if we publish it on Team Reclip's story?"

const Chat = ({ storyItem, closeChat, onSave }) => {
  const [chatUser, setChatUser] = useState(null)
  const [consentGiven, setConsentGiven] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!storyItem) {
      setChatUser(null)
      setError(null)
      return
    }

    setConsentGiven(storyItem.consent_to_publish)
    api.users
      .getUser(storyItem.share.reclip.user_id)
      .then(setChatUser)
      .catch(() =>
        setError(
          `User @${storyItem.share.reclip.username} not found or deleted.`
        )
      )
  }, [storyItem])

  const pasteIntoInput = useCallback((content) => {
    // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-change-or-input-event-in-react-js
    const element = document.querySelector('.str-chat__textarea textarea')
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLTextAreaElement.prototype,
      'value'
    ).set
    nativeInputValueSetter.call(element, content)
    var evt = new Event('change', { bubbles: true })
    element.dispatchEvent(evt)
  }, [])

  const isChatOpen = !!storyItem

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        pointerEvents: 'none',
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          opacity: isChatOpen ? 1 : 0,
          transition: 'opacity 0.5s ease',
          pointerEvents: isChatOpen ? 'all' : 'none',
          cursor: isChatOpen ? 'zoom-out' : 'default',
        }}
        onClick={isChatOpen ? closeChat : undefined}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          width: 700,
          backgroundColor: 'background.default',
          transform: isChatOpen ? 0 : 'translateX(-100%)',
          transition: 'all 0.5s ease',
          pointerEvents: 'all',
          cursor: 'default',
        }}
      >
        {error && (
          <Box
            sx={{
              fontSize: '1.2em',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {error}
          </Box>
        )}
        {storyItem && chatUser && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box
              sx={{
                px: 3,
                py: 2,
                fontWeight: 700,
                backgroundColor: 'background.paper',
              }}
            >
              @{chatUser.username} →{' '}
              {storyItem.share.reclip.title ||
                storyItem.share.reclip.title_auto ||
                'New Clip'}
            </Box>
            <Box sx={{ flex: 1, position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <ChatWindow cid={chatUser.team_reclip_cid} />
              </Box>
            </Box>
            <Box sx={{ p: 3, backgroundColor: 'background.paper' }}>
              <Box sx={{ mb: 1 }}>
                <Button
                  variant="outlined"
                  onClick={pasteIntoInput.bind(null, DEFAULT_MESSAGE)}
                  size="small"
                  sx={{ mr: 2 }}
                >
                  Paste Consent Request
                </Button>
                <Button
                  variant="outlined"
                  onClick={pasteIntoInput.bind(null, storyItem.share.url)}
                  size="small"
                >
                  Paste Share Link
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 3,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ mr: 2 }}>
                    Has the user consented to publication?
                  </Box>
                  <RadioButtons
                    items={[
                      {
                        value: false,
                        label: 'No',
                      },
                      {
                        value: true,
                        label: 'Yes',
                      },
                    ]}
                    value={consentGiven}
                    onChange={setConsentGiven}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button variant="outlined" onClick={closeChat}>
                    Close
                  </Button>
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    onClick={onSave.bind(null, consentGiven)}
                    disabled={consentGiven === storyItem.consent_to_publish}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Chat
