import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

const SetMode = ({ modes, mode, setMode }) => {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e) => setMode(e.target.value)}
        value={mode}
      >
        {modes.map((mode) => (
          <FormControlLabel
            key={mode.type}
            value={mode.type}
            control={<Radio />}
            label={mode.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default SetMode
