import { useEffect, useRef, useMemo, useState } from 'react'
import { mapbox } from 'services/mapbox'
import Box from '@mui/material/Box'
import Popup, { PopupStyleOverrides } from './Popup'
import useModalsActions from 'store/actions/modals'

const Map = ({ reclips }) => {
  const [map, setMap] = useState(null)
  const mapContainer = useRef(null)
  const popupContainer = useRef(null)
  const popup = useRef(null)
  const [selectedReclipId, setSelectedReclipId] = useState(null)
  const { openModal } = useModalsActions()

  const geoJson = useMemo(() => {
    if (!reclips) return null

    return {
      type: 'FeatureCollection',
      features: reclips.map((reclip) => ({
        type: 'Feature',
        properties: {
          reclipId: reclip.id,
          userId: reclip.user_id,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            reclip.clipped_at.coordinates.lng,
            reclip.clipped_at.coordinates.lat,
          ],
        },
      })),
    }
  }, [reclips])

  useEffect(() => {
    const map = new mapbox.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [-99, 39],
      zoom: 4,
    })

    map.on('load', () => setMap(map))

    return () => map.remove()
  }, [])

  useEffect(() => {
    if (!map) return

    map.addSource('reclips', {
      type: 'geojson',
      data: null,
    })

    map.addLayer({
      id: 'circles',
      type: 'circle',
      source: 'reclips',
      paint: {
        'circle-radius': {
          base: 5,
          stops: [
            [8, 5],
            [15, 10],
          ],
        },
        'circle-color': '#FFB800', // '#ff0000',
        'circle-opacity': 0.8,
      },
    })

    map.on('mouseenter', 'circles', (e) => {
      map.getCanvas().style.cursor = 'pointer'

      const coordinates = e.features[0].geometry.coordinates.slice()
      const { reclipId } = e.features[0].properties

      setSelectedReclipId(reclipId)

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      popup.current = new mapbox.Popup({ maxWidth: 'none' })
        .setLngLat(coordinates)
        .setDOMContent(popupContainer.current)
        .addTo(map)
    })

    map.on('mouseleave', 'circles', () => {
      map.getCanvas().style.cursor = ''

      setSelectedReclipId(null)

      popup.current?.remove()
    })

    map.on('click', 'circles', (e) => {
      const { userId } = e.features[0].properties
      openModal('profile', { userId })
    })
  }, [map, openModal])

  useEffect(() => {
    if (!map || !geoJson) return
    map.getSource('reclips').setData(geoJson)
  }, [map, geoJson])

  return (
    <>
      <Box ref={mapContainer} sx={{ width: '100%', height: '100%' }}>
        {map && (
          <Box ref={popupContainer}>
            {selectedReclipId && <Popup reclipId={selectedReclipId} />}
          </Box>
        )}
      </Box>
      <PopupStyleOverrides />
    </>
  )
}

export default Map
