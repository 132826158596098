import { useEffect, useState, useCallback, useRef } from 'react'
import Box from '@mui/material/Box'
import api from 'services/api'
import Intro from './Intro'
import ConfigEditor from './ConfigEditor'
import Divider from '@mui/material/Divider'
import Test from './Test'
import Publish from './Publish'

const GPT = () => {
  const [configs, setConfigs] = useState(null)
  const [models, setModels] = useState(null)
  const [config, setConfig] = useState(null)
  const [testResponse, setTestResponse] = useState(null)
  const [configChanged, setConfigChanged] = useState(false)
  const [isSavingConfig, setIsSavingConfig] = useState(false)
  const [isTestingConfig, setIsTestingConfig] = useState(false)
  const initialConfig = useRef(null)

  const resetConfig = useCallback((config) => {
    initialConfig.current = { ...config }
    setConfig(config)
    setConfigChanged(false)
  }, [])

  const updateConfig = useCallback((field, value) => {
    setConfig((config) => ({
      ...config,
      [field]: value,
    }))
    setConfigChanged(true)
  }, [])

  const saveConfig = useCallback(() => {
    setIsSavingConfig(true)
    setTestResponse(null)
    api.openai.saveConfig(config).then((savedConfig) => {
      resetConfig(savedConfig)
      setIsSavingConfig(false)
    })
  }, [config, resetConfig])

  const cancelConfigChanges = useCallback(() => {
    resetConfig(initialConfig.current)
  }, [resetConfig])

  const testConfig = useCallback(
    (text) => {
      setIsTestingConfig(true)
      setTestResponse(null)
      api.openai.testConfig(text, config).then((response) => {
        setTestResponse(response)
        setIsTestingConfig(false)
      })
    },
    [config]
  )

  const clearTestResponse = useCallback(() => {
    setTestResponse(null)
  }, [])

  useEffect(() => {
    api.openai.getMetadata().then((meta) => {
      console.log('meta', meta)
      setConfigs(meta.configs)
      setModels(meta.models)

      const defaultConfig = meta.configs.find((c) => c.type === 'autotitles')
      resetConfig(defaultConfig || meta.configs[0])
    })
  }, [resetConfig])

  if (!config) return null

  return (
    <Box
      sx={{
        p: 4,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ width: 800, height: '100%', overflow: 'auto' }}>
        <Intro config={config} configs={configs} setConfig={resetConfig} />
        <ConfigEditor
          config={config}
          updateConfig={updateConfig}
          models={models}
        />
      </Box>
      <Divider orientation="vertical" sx={{ mx: 4 }} />
      <Box sx={{ width: 800, height: '100%', overflow: 'auto' }}>
        <Test
          testConfig={testConfig}
          testResponse={testResponse}
          isTestingConfig={isTestingConfig}
          clearTestResponse={clearTestResponse}
        />
        <Box sx={{ display: 'none' }}>
          <Publish
            saveConfig={saveConfig}
            cancelConfigChanges={cancelConfigChanges}
            configChanged={configChanged}
            isSavingConfig={isSavingConfig}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default GPT
