import { useCallback } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'

const Toggle = ({ isChecked, setIsChecked, label }) => {
  const handleChange = useCallback(
    (e) => {
      setIsChecked(!isChecked)
    },
    [setIsChecked, isChecked]
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox checked={isChecked} onChange={handleChange} />
      <Box sx={{ ml: 0.5 }}>{label}</Box>
    </Box>
  )
}

export default Toggle
