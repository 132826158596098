import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import { useModal } from 'store/selectors'
import Message from 'components/Main/Chat/Broadcast/Message'

const MessageModal = () => {
  const {
    isOpen,
    params: { message },
  } = useModal('message')
  const { closeModal } = useModalsActions()

  return (
    <Dialog
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { backgroundColor: 'background.default' } }}
      onClose={closeModal.bind(null, 'message')}
    >
      <Box sx={{ p: 4 }}>{message && <Message message={message} />}</Box>
    </Dialog>
  )
}

export default MessageModal
