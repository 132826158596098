import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import Hub from '@mui/icons-material/Hub'
import People from '@mui/icons-material/People'
import Person from '@mui/icons-material/Person'
import Hidden from '@mui/icons-material/VisibilityOff'

export const NetworkIcon = ({ fontSize = '1.0em' }) => <Hub sx={{ fontSize }} />

export const UserIcon = ({ fontSize = '1.0em' }) => <Person sx={{ fontSize }} />

export const FriendshipIcon = ({ fontSize = '1.0em' }) => (
  <People sx={{ fontSize }} />
)

export const FriendIcon = ({ fontSize = '1.0em' }) => (
  <Check sx={{ color: '#34e434', fontSize }} />
)

export const NonFriendIcon = ({ fontSize = '1.0em' }) => (
  <Close sx={{ color: '#ff4242', fontSize }} />
)

export const HiddenIcon = ({ fontSize = '1.0em' }) => (
  <Hidden sx={{ fontSize }} />
)
