import Box from '@mui/material/Box'

const Link = ({ link }) => {
  return (
    <Box sx={{ p: 1, fontWeight: 700 }}>
      {link.friendship.nodes
        .map((node) => '@' + node?.user?.username)
        .join(' and ')}
    </Box>
  )
}

export default Link
