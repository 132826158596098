import { useEffect, useState, useRef, useCallback } from 'react'
import api from 'services/api'

const initialResults = {
  items: [],
  hasMore: false,
  error: null,
  isLoading: false,
}

export default function useSearchResults(params) {
  const [results, setResults] = useState(initialResults)
  const pending = useRef([])
  const filtersRef = useRef(null)

  const searchSchools = useCallback(
    async (filters, offset, limit, requestKey) => {
      try {
        setResults((results) => ({ ...results, isLoading: true }))

        const location = (() => {
          if (filters.location === null || filters.location.center === null)
            return null

          return filters.location.radius === null
            ? filters.location
            : {
                ...filters.location,
                radius: filters.location.radius * 1609.34,
              }
        })()

        const age = filters.age.mid
          ? {
              min: filters.age.mid - filters.age.slop,
              max: filters.age.mid + filters.age.slop,
            }
          : null

        const data = await api.schools.searchSchools({
          filters: { ...filters, location, age },
          limit,
          offset,
        })

        if (pending.current.includes(requestKey))
          setResults((results) => ({
            items: offset === 0 ? data.hits : [...results.items, ...data.hits],
            hasMore: data.hits.length === limit,
            error: null,
            isLoading: false,
            numHits: data.num_hits,
            processingTimeMs: data.processing_time_ms,
            query: data.query,
          }))
      } catch (err) {
        setResults((results) => ({
          ...results,
          error: err.message,
          isLoading: false,
        }))
      } finally {
        pending.current = pending.current.filter((key) => key !== requestKey)
      }
    },
    []
  )

  useEffect(() => {
    const { filters = null, offset = 0, limit = 10 } = params || {}

    if (!filters) {
      pending.current = []
      return setResults(initialResults)
    }

    if (filtersRef.current !== filters) {
      pending.current = []
      filtersRef.current = filters
    }

    const requestKey = Math.random()
    pending.current.push(requestKey)
    searchSchools(filters, offset, limit, requestKey)
  }, [params, searchSchools])

  return results
}
