import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { useEffect, useState } from 'react'
import { Avatar } from 'stream-chat-react'
import api from 'services/api'
import { toDateString, getAge } from 'services/utils'

const EXCLUDED_METRICS = ['username', 'age', 'signup_date']

function renderMetric(value) {
  const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/
  return typeof value === 'string' && value.match(dateRegex)
    ? toDateString(value)
    : value
}

const Heading = ({ title }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      fontStyle: 'italic',
      color: 'text.secondary',
      mx: 2,
      fontSize: '0.9em',
    }}
  >
    <Divider sx={{ flex: 1 }} />
    <Box sx={{ mx: 1 }}>{title}</Box>
    <Divider sx={{ flex: 1 }} />
  </Box>
)

const UserProfile = ({ userId }) => {
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    api.map.getProfile(userId).then(setProfile)
  }, [userId])

  if (!profile) return null

  const { user, metrics, friends } = profile

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          my: 2,
        }}
      >
        <Avatar name={user.full_name} image={user.picture} size={100} />
        <Box sx={{ ml: 8, textAlign: 'center' }}>
          <Box sx={{ fontWeight: 700 }}>{user.full_name}</Box>
          <Box sx={{ mt: 1, fontSize: '0.9em', textAlign: 'center' }}>
            <Box>@{user.username}</Box>
            <Box>{getAge(user.date_of_birth)} years old</Box>
            <Box>Joined {toDateString(user.created_at)}</Box>
          </Box>
        </Box>
      </Box>
      <Heading title="metrics" />
      <Box sx={{ textAlign: 'center', py: 2 }}>
        {(() => {
          if (!metrics)
            return <Box sx={{ fontStyle: 'italic' }}>No metrics available</Box>

          return (
            <Box sx={{ px: 4 }}>
              {Object.keys(metrics)
                .filter((field) => !EXCLUDED_METRICS.includes(field))
                .map((field) => (
                  <Box
                    key={field}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '0.8em',
                      float: 'left',
                      width: '50%',
                    }}
                  >
                    <Box sx={{ fontWeight: 700, mr: 1 }}>{field}:</Box>
                    <Box>{renderMetric(metrics[field])}</Box>
                  </Box>
                ))}
            </Box>
          )
        })()}
      </Box>
      <Heading title={`friends (${friends.length})`} />
      <Box
        sx={{
          mx: 4,
          mt: 1,
          flex: 1,
          '& > table': {
            width: '100%',
            '& tr': {
              height: '35px',
              '& td:first-of-type': {
                width: '80px',
              },
            },
          },
        }}
      >
        <table>
          <tbody>
            {friends.map((friend) => (
              <tr key={friend.id}>
                <td>
                  <Avatar
                    name={friend.full_name}
                    image={friend.picture}
                    size={30}
                  />
                </td>
                <td>{friend.full_name}</td>
                <td>@{friend.username}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          mt: 4,
          justifyContent: 'center',
          fontSize: '0.8em',
        }}
      >
        <Box
          sx={{
            fontStyle: 'italic',
            color: 'text.secondary',
            textAlign: 'center',
            mr: 1,
          }}
        >
          {user.id}
        </Box>
        <CopyToClipboard data={user.id} />
      </Box> */}
    </Box>
  )
}

export default UserProfile
