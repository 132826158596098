import { useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import { useAuth } from 'store/selectors'
import api from 'services/api'
import GetName from './GetName'
import GetSegment from './GetSegment'
import GetSchedule from './GetSchedule'
// import GetNotification from './GetNotification'
import GetMessage from './GetMessage'
import Header from './Header'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Loader from 'components/core/Loader'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/KeyboardArrowRight'

dayjs.extend(utc)

const FOOTER_HEIGHT = 80

const CreateBroadcast = ({ onCreate, onCancel }) => {
  const { user: teamReclipUser } = useAuth()
  const [name, setName] = useState('')
  const [segment, setSegment] = useState(undefined)
  const [scheduledFor, setScheduledFor] = useState(undefined)
  const [error, setError] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [message, setMessage] = useState(null)

  const send = useCallback(async () => {
    message.custom_data = { staff_member: teamReclipUser.username }

    const params = {
      name,
      segment_type: segment.type,
      user_ids: segment.userIds,
      message,
      scheduled_for: scheduledFor ? scheduledFor.utc().format() : null,
    }

    try {
      setError(null)
      setIsCreating(true)

      await api.broadcasts.createBroadcast(params)

      setIsCreating(false)
      onCreate()
    } catch (e) {
      console.log('caught exception', e)

      setIsCreating(false)
      if (e instanceof String) setError(e)
      else setError(e.toString())
    }
  }, [teamReclipUser.username, name, segment, scheduledFor, message, onCreate])

  const reset = useCallback(() => {
    setName('')
    setSegment(undefined)
    setScheduledFor(undefined)
    setMessage(null)
    setError(null)
  }, [])

  const readyToSend =
    name &&
    segment &&
    !(Array.isArray(segment.userIds) && segment.userIds.length === 0) &&
    scheduledFor !== undefined &&
    !(scheduledFor !== null && scheduledFor.isBefore(dayjs())) &&
    message?.isReady

  if (isCreating || error)
    return (
      <Box sx={{ height: '100%' }}>
        <Loader text="Creating broadcast" error={error} onRetry={reset} />
      </Box>
    )

  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative',
        borderWidth: 0,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        borderColor: 'divider',
      }}
    >
      <IconButton
        sx={{ position: 'absolute', top: 5, left: 5 }}
        onClick={onCancel}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          p: 7,
          pt: 1,
          overflow: 'auto',
          height: `calc(100% - ${FOOTER_HEIGHT}px)`,
        }}
      >
        <Header text="1. Name the broadcast" />
        <GetName name={name} onChange={setName} />
        <Header text="2. Select recipients" />
        <GetSegment segment={segment} onChange={setSegment} />
        <Header text="3. Schedule delivery" />
        <GetSchedule
          scheduledFor={scheduledFor}
          setScheduledFor={setScheduledFor}
        />
        {/* <Header text="4. Send a notification?" />
        <GetNotification
          sendNotification={sendNotification}
          onChange={setSendNotification}
        /> */}
        {/* <Box sx={{ fontSize: '0.9em', mt: 1 }}>
          If this is a single-message broadcast, choose Yes. If this is a
          multi-message broadcast, choose Yes for the last message, and No for
          all of the other messages.
        </Box> */}
        <Header text="4. Compose message" />
        <GetMessage setMessage={setMessage} />
        <Box
          sx={{
            mt: 3,
            fontSize: '0.9em',
          }}
        >
          Drag image or video files onto this input to include attachments in
          your message.
        </Box>
      </Box>
      {message && (
        <Box
          sx={{
            height: FOOTER_HEIGHT,
            display: 'flex',
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: 'divider',
            alignItems: 'center',
            p: 3,
          }}
        >
          <Button variant="contained" onClick={send} disabled={!readyToSend}>
            Create Broadcast
          </Button>
          <Button sx={{ ml: 4 }} variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CreateBroadcast
