import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

////////////// SIMPLE /////////////

export const auth = (state) => state.auth
export const chat = (state) => state.chat
// export const networks = (state) => state.networks
export const deepgram = (state) => state.deepgram
export const map = (state) => state.map
export const settings = (state) => state.settings
export const toaster = (state) => state.toaster
export const modals = (state) => state.modals
export const dashboard = (state) => state.dashboard

export const networksMeta = (state) => state.networks.meta
export const networksSidebar = (state) => state.networks.sidebar
export const networksError = (state) => state.networks.error
export const networks = (state) => state.networks.networks
export const networksUi = (state) => state.networks.ui
export const nodes = (state) => state.networks.nodes
export const links = (state) => state.networks.links
export const networksMetrics = (state) => state.networks.metrics
export const networksLoaders = (state) => state.networks.loaders

export const selectedNetworkId = (state) => state.networks.ui.selectedNetworkId
export const selectedNodeId = (state) => state.networks.ui.selectedNodeId
export const selectedLinkId = (state) => state.networks.ui.selectedLinkId
export const selectedUsername = (state) => state.networks.ui.selectedUsername
export const selectedFriendshipId = (state) =>
  state.networks.ui.selectedFriendshipId
export const selectedMetric = (state) => state.networks.ui.selectedMetric

////////////// CREATE SELECTOR /////////////

export const selectedNetwork = createSelector(
  [selectedNetworkId, networks],
  (selectedNetworkId, networks) => {
    if (!selectedNetworkId) return null
    return networks[selectedNetworkId]
  }
)

export const selectedNode = createSelector(
  [selectedNetwork, selectedNodeId],
  (selectedNetwork, selectedNodeId) => {
    if (!selectedNetwork) return null
    return selectedNetwork.nodes.find((n) => n.id === selectedNodeId)
  }
)

export const selectedLink = createSelector(
  [selectedNetwork, selectedLinkId],
  (selectedNetwork, selectedLinkId) => {
    if (!selectedNetwork) return null
    return selectedNetwork.links.find((n) => n.id === selectedLinkId)
  }
)

const getOtherPhone = (node, link) => {
  const { phone_numbers } = link
  if (phone_numbers[0] === node.phone_number) return phone_numbers[1]
  else return phone_numbers[0]
}

export const selectedUserProfile = createSelector(
  [selectedNetwork, selectedNode],
  (selectedNetwork, selectedNode) => {
    if (
      !selectedNetwork ||
      !selectedNetwork.isLoaded ||
      !selectedNode ||
      !selectedNode.user
    )
      return null

    const { nodes, links } = selectedNetwork

    const connectedLinks = links.filter((link) =>
      link.phone_numbers.includes(selectedNode.phone_number)
    )

    const friends = []
    const contacts = []
    connectedLinks.forEach((link) => {
      const otherPhone = getOtherPhone(selectedNode, link)
      const otherNode = nodes.find((node) => node.phone_number === otherPhone)
      if (link.contacts.includes(selectedNode.phone_number))
        contacts.push(otherNode)
      if (link.friendship?.friends.includes(selectedNode.user.id))
        friends.push(otherNode)
    })

    return {
      phone_number: selectedNode.phone_number,
      user: selectedNode.user,
      metrics: selectedNode.metrics,
      friends,
      contacts,
    }
  }
)

export const selectedNodeConnections = createSelector(
  [selectedNetwork, selectedNode],
  (selectedNetwork, selectedNode) => {
    if (!selectedNetwork || !selectedNetwork.isLoaded || !selectedNode)
      return null

    const { nodes } = selectedNetwork
    return nodes.filter((node) =>
      selectedNode.connections.includes(node.phone_number)
    )
  }
)

export const selectedUserFriends = createSelector(
  [selectedNetwork, selectedUsername],
  (selectedNetwork, selectedUsername) => {
    if (!selectedNetwork || !selectedNetwork.isLoaded || !selectedUsername)
      return null

    const { nodes, links } = selectedNetwork

    const node = nodes.find((node) => node.user?.username === selectedUsername)

    if (!node) return null

    const userId = node.user.id

    const friendLinks = links.filter((link) =>
      link.friendship?.friends.includes(userId)
    )

    const friendUserIds = friendLinks.map((link) => {
      const userIds = link.friendship.users
      return userIds[0] === userId ? userIds[1] : userIds[0]
    })

    const friendNodes = nodes.filter((node) =>
      friendUserIds.includes(node.user?.id)
    )

    return friendNodes
  }
)

export const selectedFriendship = createSelector(
  [selectedNetwork, selectedFriendshipId],
  (selectedNetwork, selectedFriendshipId) => {
    if (!selectedNetwork || !selectedNetwork.isLoaded || !selectedFriendshipId)
      return null

    const link = selectedNetwork.links.find(
      (link) => link.friendship?.id === selectedFriendshipId
    )

    return {
      ...link.friendship,
      chat_history: link.chat_history,
    }
  }
)

export const networkTotals = createSelector([networks], (networks) => {
  if (!networks) return null

  const data = {
    num_nodes: 0,
    num_reclip_nodes: 0,
    num_non_reclip_nodes: 0,
  }

  networks.data.networks.forEach(
    ({ num_nodes, num_reclip_nodes, num_non_reclip_nodes }) => {
      data.num_nodes += num_nodes
      data.num_reclip_nodes += num_reclip_nodes
      data.num_non_reclip_nodes += num_non_reclip_nodes
    }
  )

  return data
})

export const selectedNetworkNodes = createSelector(
  [selectedNetwork],
  (selectedNetwork) => selectedNetwork?.nodes
)

export const selectedNetworkLinks = createSelector(
  [selectedNetwork],
  (selectedNetwork) => selectedNetwork?.links
)

export const selectedMetricRange = createSelector(
  [selectedNetworkNodes, selectedMetric],
  (selectedNetworkNodes, selectedMetric) => {
    if (!selectedNetworkNodes || !selectedMetric) return null

    const arr = selectedNetworkNodes
      .filter(
        (node) =>
          node.metrics[selectedMetric] !== null &&
          node.metrics[selectedMetric] !== undefined
      )
      .map((node) => node.metrics[selectedMetric])

    return {
      max: arr.reduce((a, b) => Math.max(a, b), -Infinity),
      min: arr.reduce((a, b) => Math.min(a, b), Infinity),
    }
  }
)

// export const selectedUsernameNode = createSelector(
//   [networks, selectedUsername],
//   (networks, selectedUsername) => {
//     if (!networks || !selectedUsername) return null

//     const loadedNetworks = Object.values(networks).filter(
//       (network) => network.isLoaded
//     )

//     for (let i = 0; i < loadedNetworks.length; i++) {
//       const network = loadedNetworks[i]
//       const node = network.nodes.find(
//         (node) => node.user?.username === selectedUsername
//       )
//       if (node) return node
//     }

//     return null
//   }
// )

//////////// USE SELECTOR /////////

export const useAuth = () => useSelector(auth)
export const useChat = () => useSelector(chat)
export const useNetworksMeta = () => useSelector(networksMeta)
export const useNetworksSidebar = () => useSelector(networksSidebar)
export const useNetworksError = () => useSelector(networksError)
export const useSelectedNetworkId = () => useSelector(selectedNetworkId)
export const useSelectedNetwork = () => useSelector(selectedNetwork)
export const useNetworks = () => useSelector(networks)
export const useNetworksUi = () => useSelector(networksUi)
export const useNetworkTotals = () => useSelector(networkTotals)
export const useSelectedNetworkNodes = () => useSelector(selectedNetworkNodes)
export const useSelectedNetworkLinks = () => useSelector(selectedNetworkLinks)
export const useSelectedNodeId = () => useSelector(selectedNodeId)
export const useSelectedNode = () => useSelector(selectedNode)
export const useSelectedLink = () => useSelector(selectedLink)
export const useSelectedLinkId = () => useSelector(selectedNodeId)
export const useSelectedMetricRange = () => useSelector(selectedMetricRange)
export const useSelectedUsername = () => useSelector(selectedUsername)
export const useSelectedUserProfile = () => useSelector(selectedUserProfile)
export const useSelectedUserFriends = () => useSelector(selectedUserFriends)
export const useSelectedFriendshipId = () => useSelector(selectedFriendshipId)
export const useSelectedFriendship = () => useSelector(selectedFriendship)
export const useNetworksMetrics = () => useSelector(networksMetrics)
export const useSelectedMetric = () => useSelector(selectedMetric)
export const useNetworksLoaders = () => useSelector(networksLoaders)
export const useSelectedNodeConnections = () =>
  useSelector(selectedNodeConnections)
export const useMap = () => useSelector(map)
export const useDeepgram = () => useSelector(deepgram)
export const useSettings = () => useSelector(settings)
export const useToaster = () => useSelector(toaster)
export const useModals = () => useSelector(modals)
export const useDashboard = () => useSelector(dashboard)

///// PARAMATERIZED USE SELECTOR ///

export const useModal = (modalId) =>
  useModals()[modalId] || { isOpen: false, params: {} }
