import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/shares`

export const getShare = async (shareCode) => {
  try {
    const { data: share } = await axios.get(`${BASE_URL}/code/${shareCode}`)
    return share
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
