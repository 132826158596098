import Box from '@mui/material/Box'

const TypeSelector = ({ tagType, tagTypes, onChange }) => {
  return (
    <Box
      sx={{
        p: 0.75,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          minWidth: '150px',
          color: 'text.primary',
          fontWeight: 700,
        },
      }}
    >
      <select onChange={(e) => onChange(e.target.value)} value={tagType}>
        {tagTypes.map((t) => (
          <option key={t} value={t}>
            {t}
          </option>
        ))}
      </select>
    </Box>
  )
}

export default TypeSelector
