import { useSelectedUserProfile } from 'store/selectors'
import UserList from 'components/Main/Networks/Sidebar/core/UserList'

const Friends = () => {
  const { user, friends } = useSelectedUserProfile()

  return (
    <UserList nodes={friends} emptyText={`@${user.username} has no friends.`} />
  )
}

export default Friends
