import { useEffect, useMemo } from 'react'
import {
  useNetworksUi,
  useSelectedMetricRange,
  useSelectedNode,
  useSelectedLink,
} from 'store/selectors'
import { interpolateColor } from '../../utils'
import {
  NULL_METRIC_FILL,
  HIDDEN_USER_FILL,
  HIDDEN_USER_STROKE,
  LINK_STROKE,
  LINK_STROKE_WIDTH,
  LINK_STROKE_CHAT,
  LINK_STROKE_CHAT_HOVERED,
  LINK_STROKE_WIDTH_CHAT,
  NODE_HIGHLIGHT_FACTOR,
  LINK_HIGHLIGHT_FACTOR,
  MIN_NODE_SIZE,
} from '../../config'

const SUPPRESSION_OPACITY = 0.5

export default function useAddStyles({ svg, node, link }) {
  const {
    selectedMetric,
    highlightedNodes,
    highlightedLinks,
    hoveredNode,
    hoveredLink,
  } = useNetworksUi()
  const metricRange = useSelectedMetricRange()
  const colorMetric = selectedMetric
  const selectedNode = useSelectedNode()
  const selectedLink = useSelectedLink()

  const nodeColor = useMemo(() => {
    return (d) => {
      const value = d.metrics[colorMetric]
      if (value === undefined || value === null) return NULL_METRIC_FILL
      return interpolateColor(value, metricRange)
    }
  }, [colorMetric, metricRange])

  // node radius
  useEffect(() => {
    node.attr('r', (d) => {
      const highlightFactor = highlightedNodes[d.id] ? NODE_HIGHLIGHT_FACTOR : 0
      return d.connections.length + MIN_NODE_SIZE + highlightFactor
    })
  }, [node, highlightedNodes])

  // node fill / stroke
  useEffect(() => {
    node.style('fill', (d) =>
      d.is_reclip_user ? nodeColor(d) : HIDDEN_USER_FILL
    )

    node.style('stroke', (d) => {
      if (d.id === hoveredNode || d.id === selectedNode?.id) return 'white'

      return d.is_reclip_user
        ? 'black'
        : d.id === hoveredNode
        ? 'black'
        : HIDDEN_USER_STROKE
    })

    node.style('stroke-width', (d) => {
      if (d.id === hoveredNode || d.id === selectedNode?.id) return 4

      return d.is_reclip_user ? 1 : 1
    })

    node.style('opacity', (d) =>
      !selectedLink && (!selectedNode || d.id === selectedNode.id)
        ? 1
        : SUPPRESSION_OPACITY
    )

    node.style('cursor', (d) => (d.id === hoveredNode ? 'pointer' : 'default'))
  }, [node, nodeColor, hoveredNode, selectedNode, selectedLink])

  // link stroke
  useEffect(() => {
    link.style('stroke', (d) =>
      !!d.friendship
        ? d.index === hoveredLink
          ? LINK_STROKE_CHAT_HOVERED
          : LINK_STROKE_CHAT
        : LINK_STROKE
    )
    link.style('cursor', (d) =>
      d.index === hoveredLink ? 'pointer' : 'default'
    )

    link.style('opacity', (d) =>
      !selectedNode && (!selectedLink || d.id === selectedLink.id)
        ? 1.0
        : SUPPRESSION_OPACITY
    )
  }, [link, hoveredLink, selectedNode, selectedLink])

  // link stroke width
  useEffect(() => {
    link.attr('stroke-width', (d) => {
      const highlightFactor = highlightedLinks[d.id] ? LINK_HIGHLIGHT_FACTOR : 0
      return (
        highlightFactor +
        (!!d.friendship ? LINK_STROKE_WIDTH_CHAT : LINK_STROKE_WIDTH)
      )
    })
  }, [link, highlightedLinks])

  // no pointer events on non-chat links
  useEffect(() => {
    link.filter((d) => !d.friendship).attr('pointer-events', 'none')
  }, [link])
}
