import Box from '@mui/material/Box'

const ModelSelector = ({ engineName, engineNames, setEngineName }) => {
  return (
    <Box
      sx={{
        p: 0.75,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        width: 200,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          color: 'text.primary',
          fontWeight: 700,
          fontStyle: 'normal',
        },
      }}
    >
      <select
        onChange={(e) => setEngineName(e.target.value)}
        value={engineName}
      >
        {engineNames.map((name) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>
    </Box>
  )
}

export default ModelSelector
