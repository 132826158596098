import React, { useMemo } from 'react'
import Avatar from 'components/core/Avatar'
import { useSelectedNetworkLinks } from 'store/selectors'
import useNetworksActions from 'store/actions/networks'
import Box from '@mui/material/Box'
import { FriendIcon, NonFriendIcon } from 'components/core/Icons'
import VirtualizedList from 'components/core/VirtualizedList'

const FriendshipUser = ({ user, isFriend }) => (
  <Box
    sx={{
      width: '46.5%',
      mr: 1,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Avatar name={user.full_name} image={user.picture} size={30} />
    <Box
      sx={{
        ml: 1,
        flex: 1,
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        @{user.username}
      </Box>
    </Box>
    <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
      {isFriend ? <FriendIcon /> : <NonFriendIcon />}
    </Box>
  </Box>
)

const Friendships = () => {
  const links = useSelectedNetworkLinks()
  const { highlightLinks, selectLink } = useNetworksActions()

  const filteredLinks = useMemo(() => {
    if (!links) return null
    return links.filter((link) => !!link.friendship)
  }, [links])

  const RowComponent = ({ item: { friendship, id } }) => {
    const user0 = friendship.nodes[0]?.user
    const user1 = friendship.nodes[1]?.user
    return (
      <Box
        onMouseEnter={highlightLinks.bind(null, [id])}
        onMouseLeave={highlightLinks.bind(null, [])}
        onClick={() => {
          selectLink(id)
          highlightLinks([])
        }}
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          height: '45px',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        <FriendshipUser
          user={user0}
          isFriend={friendship.friends.includes(user0.id)}
        />
        <FriendshipUser
          user={user1}
          isFriend={friendship.friends.includes(user1.id)}
        />
      </Box>
    )
  }

  if (!filteredLinks) return null

  return (
    <Box sx={{ p: 2, flex: 1 }}>
      <VirtualizedList
        items={filteredLinks}
        RowComponent={RowComponent}
        emptyText="There are no friendships in this network."
        divider
      />
    </Box>
  )
}

export default Friendships
