import Box from '@mui/material/Box'

const Node = ({ node }) => (
  <Box
    sx={{ fontWeight: 'bold', textAlign: 'center', p: 1, fontSize: '0.9em' }}
  >
    {node.is_reclip_user ? `@${node.user.username}` : node.phone_number}
  </Box>
)

export default Node
