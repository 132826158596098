import {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import List from './List'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import useSearchResults from './useSearchResults'
import CircularProgress from '@mui/material/CircularProgress'
import RefreshIcon from '@mui/icons-material/Refresh'
import LoadingButton from '@mui/lab/LoadingButton'

const Search = forwardRef(
  ({ ListItem, search, filters, exportResults }, ref) => {
    const [searchParams, setSearchParams] = useState({
      filters: null,
      page: 0,
      limit: 20,
    })

    const { results, updateHits, updateAllHits, updateHitsFunc } =
      useSearchResults(search, searchParams)

    const [isExporting, setIsExporting] = useState(false)

    useEffect(() => {
      setSearchParams((params) => ({ ...params, filters, page: 0 }))
    }, [filters])

    const handleNextPage = useCallback(() => {
      setSearchParams((params) => ({ ...params, page: params.page + 1 }))
    }, [])

    const refresh = useCallback(() => {
      const filters = searchParams.filters
      setSearchParams((params) => ({ ...params, page: 0, filters: null }))
      setTimeout(() => {
        setSearchParams((params) => ({ ...params, filters }))
      })
    }, [searchParams])

    useImperativeHandle(
      ref,
      () => ({
        updateHits,
        updateAllHits,
        updateHitsFunc,
      }),
      [updateHits, updateAllHits, updateHitsFunc]
    )

    const doExport = useCallback(async () => {
      setIsExporting(true)
      const url = await exportResults(filters)
      setIsExporting(false)
      window.open(url, '_blank')
    }, [exportResults, filters])

    return (
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              visibility: results.initialLoad ? 'hidden' : 'visible',
              fontStyle: 'italic',
            }}
          >
            showing {results.hits.length} out of {results.numHits} (in{' '}
            {results.processingTimeMs} ms)
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {results.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <RefreshIcon
                size={20}
                onClick={refresh}
                sx={{ cursor: 'pointer', '&:hover': { opacity: '0.6' } }}
              />
            )}
            <LoadingButton
              sx={{ fontStyle: 'normal', ml: 2 }}
              size="small"
              variant="outlined"
              onClick={doExport}
              loading={isExporting}
            >
              Export
            </LoadingButton>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ flex: 1, position: 'relative' }}>
          <Box
            sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
          >
            <List
              items={results.hits}
              ListItem={ListItem}
              hasMore={results.hasMore}
              handleNextPage={handleNextPage}
              filters={filters}
            />
          </Box>
        </Box>
      </Box>
    )
  }
)

export default Search
