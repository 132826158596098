// adapted from https://d3-graph-gallery.com/graph/scatter_basic.html

import { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import Box from '@mui/material/Box'

const ScatterPlot = ({ points }) => {
  const chartRef = useRef(null)

  useEffect(() => {
    if (!points) return

    const margin = { top: 10, right: 10, bottom: 35, left: 35 }
    const width = chartRef.current.offsetWidth - margin.left - margin.right
    const height = chartRef.current.offsetHeight - margin.top - margin.bottom

    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
    // .style('outline', '1px red solid')

    const xMin = d3.min(points, (d) => d.x)
    const xMax = d3.max(points, (d) => d.x)
    const yMin = d3.min(points, (d) => d.y)
    const yMax = d3.max(points, (d) => d.y)

    const x = d3.scaleLinear().domain([xMin, xMax]).range([0, width])
    svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x))

    const y = d3.scaleLinear().domain([yMin, yMax]).range([height, 0])
    svg.append('g').call(d3.axisLeft(y))

    svg
      .append('g')
      .selectAll('dot')
      .data(points)
      .enter()
      .append('circle')
      .attr('cx', (d) => x(d.x))
      .attr('cy', (d) => y(d.y))
      .attr('r', 2.5)
      .style('fill', 'red')

    const histContainer = chartRef.current

    return function cleanup() {
      histContainer.innerHTML = ''
    }
  }, [points])

  return <Box ref={chartRef} sx={{ height: '100%' }} />
}

export default ScatterPlot
