import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import { useModal } from 'store/selectors'
import ReclipViewer from '../reclips/Viewer'

const ReclipModal = () => {
  const {
    isOpen,
    params: { reclip },
  } = useModal('reclip')
  const { closeModal } = useModalsActions()

  return (
    <Dialog open={isOpen} onClose={closeModal.bind(null, 'reclip')}>
      <Box sx={{ p: 4 }}>{reclip && <ReclipViewer reclip={reclip} />}</Box>
    </Dialog>
  )
}

export default ReclipModal
