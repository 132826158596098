import React from 'react'

import './MessagingThread.css'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const MessagingThreadHeader = ({ closeThread, thread }) => {
  const getReplyCount = () => {
    if (!thread?.reply_count) return ''
    if (thread.reply_count === 1) return '1 reply'
    return `${thread.reply_count} Replies`
  }

  return (
    <div className="custom-thread-header">
      <div className="custom-thread-header__left">
        <p className="custom-thread-header__left-title">Thread</p>
        <p className="custom-thread-header__left-count">{getReplyCount()}</p>
      </div>
      <IconButton onClick={closeThread} sx={{ marginRight: 2 }}>
        <CloseIcon />
      </IconButton>
      {/* <CloseThreadIcon {...{ closeThread }} /> */}
    </div>
  )
}

export default MessagingThreadHeader
