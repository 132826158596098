import { useState } from 'react'
import Box from '@mui/material/Box'
import ArchiveList from './ArchiveList'
import ScheduledList from './ScheduledList'
import PendingList from './PendingList'
import Calendar from './Calendar'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import LoadButton from './LoadButton'
import Tabs from './Tabs'

const TABS = [
  {
    type: 'SCHEDULED',
    text: 'Scheduled',
  },
  {
    type: 'PENDING',
    text: 'In Progress',
  },
  {
    type: 'ARCHIVED',
    text: 'Sent',
  },
  {
    type: 'CALENDAR',
    text: 'Calendar',
  },
]

const BroadcastList = ({
  broadcasts,
  deleting,
  onDelete,
  onCreate,
  loadBroadcasts,
  isLoading,
}) => {
  const [tab, setTab] = useState(TABS[0].type)

  const scheduled = broadcasts.filter((b) => b.status === 'SCHEDULED')
  const pending = broadcasts.filter((b) => b.status === 'PENDING')
  const archived = broadcasts.filter(
    (b) => !['SCHEDULED', 'PENDING'].includes(b.status)
  )

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          tabs={TABS}
          tab={TABS.find((t) => t.type === tab)}
          setTab={setTab}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LoadButton isLoading={isLoading} onClick={loadBroadcasts} />
          <Button
            sx={{ ml: 3 }}
            size="small"
            variant="contained"
            onClick={onCreate}
            disabled={true} // because there's max-depth issue regarding the GetMessage component of CreateBroadcast
          >
            Create Broadcast
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />

      {(() => {
        switch (tab) {
          case TABS[0].type:
            return (
              <ScheduledList
                broadcasts={scheduled}
                deleting={deleting}
                onDelete={onDelete}
              />
            )
          case TABS[1].type:
            return <PendingList broadcasts={pending} />
          case TABS[2].type:
            return (
              <ArchiveList
                broadcasts={archived}
                deleting={deleting}
                onDelete={onDelete}
              />
            )
          case TABS[3].type:
            return <Calendar broadcasts={broadcasts} />
          default:
            return null
        }
      })()}
    </Box>
  )
}

export default BroadcastList
