import Box from '@mui/material/Box'
import PlayIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined'
import friendlyDate from './friendlyDate'

const IdleOverlay = ({ play, reclip }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.6) 100%)',
      }}
      onClick={play}
    >
      <PlayIcon sx={{ width: '100px', height: '100px' }} />
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          p: 2,
        }}
      >
        <Box
          sx={{
            fontSize: '2em',
            fontWeight: 700,
            lineHeight: '1.2em',
            mb: 1,
          }}
        >
          {reclip.title || reclip.title_auto || 'New Reclip'}
        </Box>
        <Box>
          Clipped <b>{friendlyDate(reclip)}</b> by @<b>{reclip.username}</b>
        </Box>
      </Box>
    </Box>
  )
}

export default IdleOverlay
