import { useCallback, useState } from 'react'
import { MessageSimple } from 'stream-chat-react'
import Box from '@mui/material/Box'
import { useMessageContext } from 'stream-chat-react'
import GlobalStyles from '@mui/material/GlobalStyles'
import ReclipMessage from './ReclipMessage'
import { isReclipMessage } from '../../reclips/utils'
import Loader from 'components/core/Loader'
import api from 'services/api'

import './CustomMessage.css'

// width of area where staff members' names are listed next to messages
const GUTTER_WIDTH = 180

const CustomMessage = (props) => {
  const [isSendingFeedback, setIsSendingFeedback] = useState(false)
  const { message, isMyMessage } = useMessageContext()
  const { staff_member, isSystemMessage, systemMessageData } = message

  if (isSystemMessage) console.log(message)

  const postToFeedback = useCallback(async () => {
    setIsSendingFeedback(true)

    const reclipLink = (() => {
      if (
        message.attachments &&
        message.attachments.length > 0 &&
        message.attachments[0].isReclipPlaybackAttachment
      ) {
        const { title } = message.attachments[0]
        const source_id = title.replace('.m4a', '')
        return window.location.origin + `/r/${source_id}`
      } else {
        return null
      }
    })()

    const text = reclipLink ? `${message.text}: ${reclipLink}` : message.text

    await api.chat.postToFeedback({
      title: `Feedback from ${message.user.name} (@${message.user.username})`,
      message: text,
    })

    setIsSendingFeedback(false)
  }, [message])

  const messageEl = (() => {
    if (isSystemMessage && systemMessageData.messageType === 'replyToStory') {
      return (
        <Box sx={{ fontSize: '0.9em', mb: 1 }}>
          Replying to{' '}
          <Box
            sx={{
              display: 'inline-block',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() =>
              window.open(
                window.origin + '/s/' + systemMessageData.storyShareCode,
                '_blank'
              )
            }
          >
            {systemMessageData.storyDisplayTitle}
          </Box>
          <br />
          on the Team Reclip story.
        </Box>
      )
    } else if (isReclipMessage(message)) {
      return <ReclipMessage />
    } else {
      return <MessageSimple {...props} />
    }
  })()

  const gutterStyles = {
    width: `${GUTTER_WIDTH}px`,
    textAlign: 'center',
    position: 'absolute',
    bottom: '11px',
    transform: 'translateX(10px)',
    left: '100%',
    fontSize: 12,
    wordBreak: 'break-word',
    color: (theme) => theme.palette.text.secondary,
    px: 2,
    lineHeight: '12px',
    cursor: 'default',
  }

  return (
    <Box
      sx={{
        position: 'relative',
        '& .str-chat__message-actions-box': {
          backgroundImage: 'none !important',
          backgroundColor: (theme) =>
            `${theme.palette.background.paper} !important`,
        },
        '& .str-chat__modal__close-button': {
          display: 'none',
        },
        '& .str-chat__message-text-inner': {
          backgroundColor: (theme) =>
            `${theme.palette.background.paper} !important`,
          border: 'none !important',
          minHeight: 'none !important',
          px: 2,
          py: 1,
        },
      }}
    >
      {messageEl}
      <GlobalStyles
        styles={{
          '.messaging.str-chat .str-chat__list': {
            paddingRight: `${GUTTER_WIDTH}px`,
          },
        }}
      />
      {staff_member && (
        <Box className="chat-gutter" sx={gutterStyles}>
          {staff_member.replace('@reclip.app', '')}
        </Box>
      )}
      {!isMyMessage() && !isSystemMessage && (
        <Box
          className="chat-gutter"
          sx={{
            ...gutterStyles,
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={postToFeedback}
        >
          {isSendingFeedback ? <Loader size={12} /> : '#feedback'}
        </Box>
      )}
    </Box>
  )
}

export default CustomMessage
