import React, { useContext } from 'react'
import { logChatPromiseExecution } from 'stream-chat'
import {
  MessageList,
  MessageInput,
  Thread,
  Window,
  useChannelActionContext,
} from 'stream-chat-react'
import { useAuth } from 'store/selectors'
import MessagingChannelHeader from './MessagingChannelHeader'
import MessagingInput from './MessagingInput'
import Divider from '@mui/material/Divider'

import { GiphyContext } from '.'

const ChannelInner = ({ inputOnly = false }) => {
  const { giphyState, setGiphyState } = useContext(GiphyContext)
  const { sendMessage } = useChannelActionContext()
  const { user } = useAuth()

  const overrideSubmitHandler = (message) => {
    let updatedMessage

    if (message.attachments?.length && message.text?.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '')
      updatedMessage = { ...message, text: updatedText }
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`
      updatedMessage = { ...message, text: updatedText }
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message
      const parentMessage = newMessage.parent

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      }

      const sendMessagePromise = sendMessage(messageToSend, {
        staff_member: user.username,
      })
      logChatPromiseExecution(sendMessagePromise, 'send message')
    }

    setGiphyState(false)
  }

  const actions = [
    'delete',
    'edit',
    /* 'flag', 'mute', */ 'react' /* 'reply' */,
  ]

  // NOTE: this is used for broadcasts, where we grab the message content
  // from the input rather than using streamchat's native submit function
  if (inputOnly)
    return (
      <Window>
        <MessageInput
          overrideSubmitHandler={() => console.log('ignoring submit')}
          shouldSubmit={(event) => false}
        />
      </Window>
    )

  return (
    <>
      <Window>
        <MessagingChannelHeader />
        <MessageList messageActions={actions} />
        <Divider />
        <MessageInput overrideSubmitHandler={overrideSubmitHandler} />
      </Window>
      <Thread Input={MessagingInput} />
    </>
  )
}

export default ChannelInner
