import Box from '@mui/material/Box'
import { useAuth } from 'store/selectors'
import {
  API_URL,
  ADMIN_API_URL,
  NOTIFICATIONS_API_URL,
  TASKS_API_URL,
  SHARE_VIDEOS_URL,
  // BROADCASTS_API_URL,
} from 'const'
import { useNavigate, useLocation } from 'react-router-dom'

const HEADER_HEIGHT = 45

const ApiButton = ({ onClick, title, selected }) => (
  <Box
    sx={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      px: 4,
      fontWeight: 700,
      cursor: selected ? 'default' : 'pointer',
      backgroundColor: selected ? 'action.selected' : undefined,
      '&:hover': {
        backgroundColor: selected ? undefined : 'action.hover',
      },
    }}
    onClick={onClick}
  >
    {title}
  </Box>
)

const Swagger = () => {
  const { token } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const APIS = {
    main: {
      title: 'Main',
      url: `${API_URL}/swagger?access_token=${token}`,
      pathname: '/swagger',
    },
    admin: {
      title: 'Admin',
      url: `${ADMIN_API_URL}/docs`,
      pathname: '/swagger/admin',
    },
    notifications: {
      title: 'Notifications',
      url: `${NOTIFICATIONS_API_URL}/docs`,
      pathname: '/swagger/notifications',
    },
    tasks: {
      title: 'Tasks',
      url: `${TASKS_API_URL}/docs`,
      pathname: '/swagger/tasks',
    },
    shareVideos: {
      title: 'Share Videos',
      url: `${SHARE_VIDEOS_URL}/docs`,
      pathname: '/swagger/videos',
    },
    // broadcasts: {
    //   title: 'Broadcasts',
    //   url: `${BROADCASTS_API_URL}/docs`,
    //   pathname: '/swagger/broadcasts',
    // },
  }

  const selectedAPI = Object.values(APIS).find(
    (api) => api.pathname === pathname
  )

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: `${HEADER_HEIGHT}px`,
        }}
      >
        <Box sx={{ fontWeight: 700, fontSize: '1.2em', ml: 2 }}>API Docs</Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}
        >
          {Object.values(APIS).map((api) => (
            <ApiButton
              key={api.pathname}
              title={api.title}
              onClick={navigate.bind(null, api.pathname)}
              selected={pathname === api.pathname}
            />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          height: `calc(100% - ${HEADER_HEIGHT}px)`,
          backgroundColor: 'white',
          '& iframe': {
            width: '100%',
            height: '100%',
            borderWidth: 0,
          },
        }}
      >
        <iframe src={selectedAPI.url} title="docs" />
      </Box>
    </Box>
  )
}

export default Swagger
