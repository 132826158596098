import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/staff`

export const listStaffMembers = async () => {
  try {
    const { data: staffMembers } = await axios.get(BASE_URL)
    return staffMembers
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const createStaffMember = async (phoneNumber) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/${phoneNumber}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const deleteStaffMember = async (userId) => {
  try {
    await axios.delete(`${BASE_URL}/${userId}`)
    return null
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
