import Box from '@mui/material/Box'
import Legend from './Legend'
import ForceGraph from './ForceGraph'
import { TooltipProvider } from './Tooltip'
import { useNetworksMetrics, useSelectedNetwork } from 'store/selectors'
import { OFFSET_X } from '../config'

const MAX_NODES = 1500

const Main = () => {
  const selectedNetwork = useSelectedNetwork()
  const metrics = useNetworksMetrics()

  if (!selectedNetwork) {
    console.log('no selected network')
    return null
  }

  const { nodes, links } = selectedNetwork
  if (!nodes || !links) return null

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      {nodes.length <= MAX_NODES ? (
        <TooltipProvider>
          <ForceGraph nodes={nodes} links={links} />
        </TooltipProvider>
      ) : (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              transform: `translateX(${OFFSET_X}px)`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ fontSize: '1.5em' }}>
              this network is too big to render
            </Box>
            <Box sx={{ fontSize: '6em' }}>😕</Box>
          </Box>
        </Box>
      )}
      {metrics?.length > 0 && <Legend />}
    </Box>
  )
}

export default Main
