import Box from '@mui/material/Box'

const TopTags = ({ tags, onClick }) => {
  if (!tags || tags.length === 0) return null

  const max = tags[0][1]

  return (
    <Box sx={{ fontSize: '0.9em', pr: 2 }}>
      {tags.map((tag) => (
        <Box key={tag[0]} sx={{ mb: 1.5 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={onClick.bind(null, tag[0])}
            >
              {tag[0]}
            </Box>
            <Box>{tag[1]}</Box>
          </Box>
          <Box
            sx={{
              mt: 0.5,
              height: '4px',
              borderRadius: '2px',
              backgroundColor: 'primary.main',
              width: `${(100 * tag[1]) / max}%`,
            }}
          />
        </Box>
      ))}
    </Box>
  )
}

export default TopTags
