import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 2,
        width: '100%',
        height: '60px',
      }}
    >
      <Box sx={{ fontWeight: 'bold' }}>Clips Shared With Team Reclip</Box>
      <IconButton onClick={navigate.bind(null, '/chat')}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default Header
