import { useEffect, useState } from 'react'
import * as d3 from 'd3'
import { useNetworksUi } from 'store/selectors'
import { OFFSET_X } from '../../config'

export default function useAddZoom({ svg, zoomG, width, height }) {
  const { bounds } = useNetworksUi()

  const [zoom, setZoom] = useState(null)
  const [inTransition, setInTransition] = useState(null)
  const [transform, setTransform] = useState({
    x: OFFSET_X,
    y: 0,
    k: 1.2, // this should depend on number of nodes
  })

  useEffect(() => {
    if (!zoom || inTransition) return

    const t = d3.zoomIdentity
      .translate(transform.x, transform.y)
      .scale(transform.k)

    zoom.transform(svg, t)
  }, [svg, zoom, transform.x, transform.y, transform.k, inTransition])

  useEffect(() => {
    function zoomed({ transform }) {
      zoomG.attr('transform', transform)
    }

    function zoomedWheel({ transform }) {
      setTransform(transform)
    }

    const zoom = d3
      .zoom()
      .scaleExtent([0.1, 10])
      .on('zoom', zoomed)
      .on('zoom.wheel', zoomedWheel)

    svg.call(zoom)

    setZoom(() => zoom)

    return () => {
      zoom.on('zoom', null)
    }
  }, [svg, zoomG])

  useEffect(() => {
    if (!bounds) return

    const { xMin, yMin, xMax, yMax, padding } = bounds

    const transform = d3.zoomIdentity
      .translate(OFFSET_X, 0)
      .scale(
        (1 - padding) / Math.max((xMax - xMin) / width, (yMax - yMin) / height)
      )
      .translate(-(xMin + xMax) / 2, -(yMin + yMax) / 2)

    setInTransition(true)
    svg
      .transition()
      .duration(750)
      .call(zoom.transform, transform)
      .on('end', () => setInTransition(false))
  }, [svg, zoom, bounds, width, height])
}
