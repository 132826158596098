import Reclip from './Reclip'
import Message from './Message'
import ConfirmDeleteBroadcast from './ConfirmDeleteBroadcast'

const Modals = () => {
  return (
    <>
      <Reclip />
      <Message />
      <ConfirmDeleteBroadcast />
    </>
  )
}

export default Modals
