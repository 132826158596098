import { useChatContext } from 'stream-chat-react'
import UserProfile from '../UserProfile'
import Box from '@mui/material/Box'
import { useChat } from 'store/selectors'
import Channel from '../Channel'

const ViewChannel = () => {
  const { channel: activeChannel } = useChatContext()
  const { selectedUser } = useChat()

  if (!activeChannel && !selectedUser)
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 700,
        }}
      >
        Click on a channel to the left
      </Box>
    )

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ flex: 1 }}>
        <Channel />
      </Box>
      <Box
        sx={{
          borderLeft: 1,
          borderColor: 'divider',
          width: '300px',
          height: '100%',
        }}
      >
        <UserProfile />
      </Box>
    </Box>
  )
}

export default ViewChannel
