import React from 'react'
import Box from '@mui/material/Box'

const Test = () => {
  return (
    <Box
      sx={{
        padding: 2,
        maxWidth: 600,
        margin: '200px auto',
        textAlign: 'center',
      }}
    >
      <h1
        sx={{
          marginBottom: 1,
          color: 'primary.main',
          fontWeight: 600,
        }}
      >
        Settings
      </h1>
    </Box>
  )
}

export default Test
