import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingIcon = ({ Icon, isLoading, onClick }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    <Icon
      sx={{
        visibility: isLoading ? 'hidden' : 'visible',
        '&:hover': {
          color: 'primary.main',
          cursor: 'pointer',
        },
      }}
      onClick={onClick}
    />
    {isLoading && (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CircularProgress sx={{ display: 'block' }} size={16} />
      </Box>
    )}
  </Box>
)

export default LoadingIcon
