import { useMemo } from 'react'
import Box from '@mui/material/Box'

const Stats = ({ requests }) => {
  const { minProcessingTime, maxProcessingTime, avgProcessingTime } =
    useMemo(() => {
      const times = requests.map((r) => r.processing_time)
      const sum = times.reduce((p, c) => p + c, 0)
      return {
        minProcessingTime: Math.min(...times).toFixed(2),
        maxProcessingTime: Math.max(...times).toFixed(2),
        avgProcessingTime: times.length
          ? (sum / times.length).toFixed(2)
          : undefined,
      }
    }, [requests])

  return (
    <Box>
      <Box>average processing time: {avgProcessingTime} secs</Box>
      <Box>
        range: {minProcessingTime} - {maxProcessingTime} secs
      </Box>
    </Box>
  )
}

export default Stats
