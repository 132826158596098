import { useState, useEffect } from 'react'
import { useChatContext } from 'stream-chat-react'
import Channel from 'components/Main/Chat/Channel'
import Box from '@mui/material/Box'

const ViewChannel = ({ cid }) => {
  const [isReady, setIsReady] = useState(false)
  const { client, setActiveChannel } = useChatContext()

  useEffect(() => {
    if (!client) return

    let channel

    const createChannel = async () => {
      channel = await client.channel('messaging', cid.replace('messaging:', ''))
      setActiveChannel(channel)
      await channel.watch()
      setIsReady(true)
    }

    createChannel()

    return () => {
      setActiveChannel(null)
    }
  }, [client, setActiveChannel, cid])

  if (!isReady) return null

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Channel />
    </Box>
  )
}

export default ViewChannel
