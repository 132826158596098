function getMinutesAgo(date) {
  const now = new Date()
  return Math.round((now.getTime() - date.getTime()) / 1000 / 60)
}

function isToday(date) {
  const today = new Date()
  return today.toDateString() === date.toDateString()
}

function isYesterday(date) {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  return yesterday.toDateString() === date.toDateString()
}

export default function friendlyDate(reclip) {
  const d = (() => {
    if (reclip.clipped_at)
      // the clipped_at date is in the clipper's local timezone, so drop
      // the timezone offset from the end of the string so that it gets
      // displayed as local time for the clipper
      return reclip.clipped_at.date_local_tz.replace(/[+-]\d+:\d+$/, '')
    else {
      // the created_at is in UTC, so add the Z to the end of the string
      // so that it gets converted to the viewer's local timezone
      const d = new Date(reclip.created_at + 'Z')
      const dateString = d
        .toLocaleDateString([], {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
        .split(',')
      return `${dateString[0].trim()}, ${dateString[1].trim()}`
    }
  })()

  const date = new Date(d)

  // const now = new Date()
  // const date = new Date(now.getTime() - 30000)

  const minutesAgo = getMinutesAgo(date)
  if (minutesAgo <= 1) return '1 min ago'
  if (minutesAgo < 60) return `${minutesAgo} min ago`

  const timeString = date.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })

  if (isToday(date)) return `Today, ${timeString}`
  if (isYesterday(date)) return `Yesterday, ${timeString}`

  const dateString = date.toLocaleDateString([], {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
  return `${dateString} ${timeString}`
}
