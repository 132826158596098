import axios from 'axios'
import queryString from 'query-string'
import { toTimestamp } from 'components/Main/Deepgram/utils'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/deepgram`

// function randomDeepgramData() {
//   return Array.from({ length: 100 }).map(() => ({
//     processing_time: 10,
//     audio_duration: 5,
//     start_time: Date.now(),
//     end_time: Date.now(),
//   }))
// }

export const getDeepgramData = async (params = {}) => {
  const query = queryString.stringify(params)
  const { data: requests } = await axios.get(`${BASE_URL}?${query}`)

  requests.forEach((request) => {
    request.start_time_ts = toTimestamp(request.start_time)
    request.end_time_ts = toTimestamp(request.end_time)
  })

  return requests
}
