import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import LinkButton from 'components/core/LinkButton'

const Legend = ({ isLoading, error, datasets }) => {
  return (
    <Box
      sx={{
        borderRadius: 2,
        py: 2,
        px: 2,
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 330,
      }}
    >
      <Box sx={{ fontWeight: 700, fontSize: '1.2em', mb: 2 }}>Schools</Box>
      <Divider sx={{ width: '100%' }} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        {isLoading && (
          <Box sx={{ my: 1.5 }}>
            <CircularProgress size={30} />
          </Box>
        )}
        {!isLoading &&
          datasets &&
          datasets.map((dataset) => (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              key={dataset.id}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    backgroundColor: dataset.color,
                    borderRadius: '50%',
                    mr: 1,
                  }}
                />
                <Box>{dataset.id}</Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 2 }}>{dataset.count}</Box>
                <LinkButton url={dataset.url} />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default Legend
