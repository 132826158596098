import LinkIcon from '@mui/icons-material/OpenInNew'

const LinkButton = ({ url, size = 'small' }) => (
  <LinkIcon
    fontSize="inherit"
    sx={{
      display: 'block',
      '&:hover': {
        color: 'text.secondary',
        cursor: 'pointer',
      },
    }}
    onClick={() => window.open(url, '_blank')}
  />
)

export default LinkButton
