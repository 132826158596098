import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/share_videos`

export const getShareVideo = async (shareCode) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/from_share_code/${shareCode}`)
    return data.video_url
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getShareVideoFromSourceId = async (sourceId) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/from_source_id/${sourceId}`)
    return data.video_url
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
