import { useEffect, useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import api from 'services/api'
import SuggestionList from './SuggestionList'

const UI_WIDTH = 1000

const OnboardingSuggestions = () => {
  const [suggestions, setSuggestions] = useState([])
  const [userId, setUserId] = useState(null)

  const createUser = useCallback(async () => {
    const phoneNumber = '+19253308901'
    const reclipContactPhones = [
      // '+447834593842', // fru
      // '+447707665121', // danfruhman
    ]
    const { user_id, suggestions: s } = await api.onboarding.createUser({
      phoneNumber,
      reclipContactPhones,
    })
    setUserId(user_id)
    setSuggestions(s)
  }, [])

  const addFriend = useCallback(
    async (addedUserId) => {
      const s = await api.onboarding.addFriend({
        addingUserId: userId,
        addedUserId,
      })
      setSuggestions(s)
    },
    [userId]
  )

  useEffect(() => {
    createUser()
  }, [createUser])

  console.log(suggestions)

  return (
    <Box
      sx={{
        height: '100%',
        width: UI_WIDTH,
        display: 'flex',
        margin: '0 auto',
      }}
    >
      <Box sx={{ width: UI_WIDTH / 2 }}>onboard user</Box>
      <Divider orientation="vertical" />
      <Box
        sx={{
          p: 2,
          height: '100%',
          width: UI_WIDTH / 2,
        }}
      >
        <SuggestionList suggestions={suggestions} addFriend={addFriend} />
      </Box>
    </Box>
  )
}

export default OnboardingSuggestions
