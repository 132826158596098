import { useCallback } from 'react'
import useModalsActions from 'store/actions/modals'
import Button from '@mui/material/Button'

const PublishButton = ({ publish }) => {
  const { openModal } = useModalsActions()

  const confirm = useCallback(() => {
    openModal('confirmPublish', { onConfirm: publish })
  }, [publish, openModal])

  return (
    <Button fullWidth variant="contained" onClick={confirm}>
      Publish Changes
    </Button>
  )
}

export default PublishButton
