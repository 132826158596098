// an adaptation of defaultFindChunks here:
// https://github.com/bvaughn/highlight-words-core/blob/master/src/utils.js
export default function findChunksAutoComplete({
  autoEscape,
  caseSensitive,
  sanitize,
  searchWords,
  textToHighlight,
}) {
  return searchWords
    .filter((searchWord) => searchWord) // Remove empty words
    .reduce((chunks, searchWord) => {
      // the key: match words at the beginning of the text or preceded by a space
      const regex = new RegExp(`(^| )${searchWord}`, caseSensitive ? 'g' : 'gi')

      let match
      while ((match = regex.exec(textToHighlight))) {
        // the other key: remove any preceding space when highlighting
        let start = match[0].startsWith(' ') ? match.index + 1 : match.index
        let end = regex.lastIndex
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ highlight: false, start, end })
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++
        }
      }

      return chunks
    }, [])
}
