import { useMemo } from 'react'
import Box from '@mui/material/Box'
import { useNetworksMeta } from 'store/selectors'

const UpdatedAt = () => {
  const meta = useNetworksMeta()

  const updated = useMemo(() => {
    if (!meta) return null

    const { updated_at } = meta

    const d = new Date(updated_at + 'Z')

    return d.toLocaleString([], {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short',
    })
  }, [meta])

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box sx={{ color: 'text.secondary', fontSize: '0.8em' }}>Updated</Box>
      <Box sx={{ fontWeight: 700 }}>{updated}</Box>
    </Box>
  )
}

export default UpdatedAt
