import { useEffect } from 'react'
import * as d3 from 'd3'

const INITIAL_VIS_FORCES = {
  alphaDecay: 0.0228,
  charge: {
    enabled: true,
    strength: -200,
    distanceMin: 1,
    distanceMax: 2000,
  },
  forceXY: {
    enabled: true,
    strength: 0.3,
  },
  link: {
    enabled: true,
    strength: 0.3,
    distance: 10,
    iterations: 2,
  },
  center: {
    enabled: false,
    strength: 0.4,
  },
  collide: {
    enabled: false,
    strength: 0.7,
    iterations: 1,
    radius: 5,
  },
}

export default function useAddForces({ nodes, links, simulation }) {
  const visForces = INITIAL_VIS_FORCES

  // init forces
  useEffect(() => {
    simulation
      .nodes(nodes)
      .force(
        'link',
        d3
          .forceLink()
          .links(links)
          .id((d) => d.phone_number)
      )
      .force('charge', d3.forceManyBody())
      .force('collide', d3.forceCollide())
      .force('center', d3.forceCenter())
      .force('forceX', d3.forceX())
      .force('forceY', d3.forceY())
  }, [nodes, links, simulation])

  // update forces
  useEffect(() => {
    if (!visForces) return

    simulation.alphaDecay(visForces.alphaDecay)

    simulation
      .force('center')
      .strength(visForces.center.strength * visForces.center.enabled)

    simulation
      .force('charge')
      .strength(
        (d) =>
          (d.connections.length + 1) *
          visForces.charge.strength *
          visForces.charge.enabled
      )
      .distanceMin(visForces.charge.distanceMin)
      .distanceMax(visForces.charge.distanceMax)

    simulation
      .force('collide')
      .strength(visForces.collide.strength * visForces.collide.enabled)
      .radius(visForces.collide.radius)
      .iterations(visForces.collide.iterations)

    simulation
      .force('forceX')
      .strength(
        (d) =>
          Math.sqrt(d.connections.length + 1) *
          visForces.forceXY.strength *
          visForces.forceXY.enabled
      )

    simulation
      .force('forceY')
      .strength(
        (d) =>
          Math.sqrt(d.connections.length + 1) *
          visForces.forceXY.strength *
          visForces.forceXY.enabled
      )

    simulation
      .force('link')
      .strength(visForces.link.strength * visForces.link.enabled)
      .distance(visForces.link.distance)
      .iterations(visForces.link.iterations)

    simulation.alpha(1).restart()
  }, [simulation, visForces])
}
