import { useCallback } from 'react'
import Box from '@mui/material/Box'

const UserSelector = ({ user, users, setUser }) => {
  const handleChange = useCallback(
    (e) => {
      const newUser = users.find((u) => u.id === e.target.value)
      setUser(newUser)
    },
    [users, setUser]
  )

  return (
    <Box
      sx={{
        p: 0.75,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          minWidth: '150px',
          color: 'text.primary',
          fontWeight: 700,
        },
      }}
    >
      <select onChange={handleChange} value={user.id}>
        {users.map((u) => (
          <option key={u.id} value={u.id}>
            {u.username}
          </option>
        ))}
      </select>
    </Box>
  )
}

export default UserSelector
