import React, { useState } from 'react'
import Box from '@mui/material/Box'
import DragHandle from 'components/core/DragHandle'
import NetworkList from './NetworkList'
import NetworkExplorer from './NetworkExplorer'
import Divider from '@mui/material/Divider'
import { alpha } from '@mui/system/colorManipulator'

const INITIAL_WIDTH = 560
const HANDLE_WIDTH = 6

const Sidebar = () => {
  const [width, setWidth] = useState(INITIAL_WIDTH)

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.7),
      }}
    >
      <Box sx={{ width: `${width - HANDLE_WIDTH / 2}px`, display: 'flex' }}>
        <Box sx={{ height: '100%', flex: 0 }}>
          <NetworkList />
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ height: '100%', flex: 1 }}>
          <NetworkExplorer />
        </Box>
      </Box>
      <DragHandle onDrag={setWidth} width={HANDLE_WIDTH} />
    </Box>
  )
}

export default Sidebar
