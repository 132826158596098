import { useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import queryString from 'query-string'
import useAuthActions from 'store/actions/auth'
import { useAuth } from 'store/selectors'
import { AUTH_URL } from 'const'

const Auth = () => {
  const { getUser } = useAuthActions()
  const { loggedOut, error } = useAuth()

  useEffect(() => {
    const handleEvent = (event) => {
      if (event.data?.access_token)
        getUser({
          token: event.data.access_token,
          refreshToken: event.data.refresh_token,
          authUser: JSON.parse(atob(event.data.user)),
        })
    }

    window.addEventListener('message', handleEvent)
    return () => window.removeEventListener('message', handleEvent)
  }, [getUser])

  const iframeSrc = useMemo(() => {
    const params = loggedOut ? { logout_on_load: 1 } : { auto_redirect: 1 }

    if (error) params.error = error

    return `${AUTH_URL}?${queryString.stringify(params)}`
  }, [loggedOut, error])

  return (
    <Box
      sx={{
        height: '100vh',
        '& > iframe': {
          width: '100%',
          height: '100%',
          border: 0,
        },
      }}
    >
      <iframe src={iframeSrc} title="auth" />
    </Box>
  )
}

export default Auth
