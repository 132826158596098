import { useCallback } from 'react'
import Box from '@mui/material/Box'

const ViewTypeSelector = ({ viewType, viewTypes, setViewType }) => {
  const handleChange = useCallback(
    (e) => {
      setViewType(e.target.value)
    },
    [setViewType]
  )

  return (
    <Box
      sx={{
        p: 0.75,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          minWidth: '150px',
          color: 'text.primary',
          fontWeight: 700,
        },
      }}
    >
      <select onChange={handleChange} value={viewType}>
        {Object.values(viewTypes).map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
    </Box>
  )
}

export default ViewTypeSelector
