import { useCallback } from 'react'
import Box from '@mui/material/Box'

const NumberSelect = ({ label, value, minValue, maxValue, onSelect }) => {
  const handleChange = useCallback(
    (e) => {
      onSelect(e.target.value)
    },
    [onSelect]
  )

  const values = Array.from({ length: maxValue - minValue + 1 }).map(
    (el, idx) => idx + minValue
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ mr: 1 }}>{label}</Box>
      <Box
        sx={{
          p: 0.75,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          '& select': {
            backgroundColor: 'transparent',
            border: 0,
            outline: 0,
            width: '70px',
            color: 'text.primary',
            fontWeight: 700,
            textAlign: 'left',
          },
        }}
      >
        <select onChange={handleChange} value={value}>
          {values.map((num, index) => (
            <option key={index.toString()} value={num}>
              {num}
            </option>
          ))}
        </select>
      </Box>
    </Box>
  )
}

export default NumberSelect
