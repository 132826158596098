import Box from '@mui/material/Box'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteIconOutlined from '@mui/icons-material/FavoriteBorder'

const getTimeStamp = (channel) => {
  let lastHours = channel.state.last_message_at?.getHours()
  let lastMinutes = channel.state.last_message_at?.getMinutes()
  let half = 'AM'

  if (lastHours === undefined || lastMinutes === undefined) {
    return ''
  }

  if (lastHours > 12) {
    lastHours = lastHours - 12
    half = 'PM'
  }

  if (lastHours === 0) lastHours = 12
  if (lastHours === 12) half = 'PM'

  if (lastMinutes.toString().length === 1) {
    lastMinutes = `0${lastMinutes}`
  }

  return `${lastHours}:${lastMinutes} ${half}`
}

const getName = (channel, userID) => {
  const members = Object.values(channel.state.members).filter(
    ({ user }) => user.id !== userID
  )

  if (members.length === 0) return null

  const memberName = (member) => member.user.name

  if (members.length === 1) return memberName(members[0])

  return `${memberName(members[0])}, ${memberName[members[1]]}`
}

const getUsername = (channel, userID) => {
  const members = Object.values(channel.state.members).filter(
    ({ user }) => user.id !== userID
  )

  if (members.length === 0) return null

  const memberUsername = (member) =>
    member.user.username ? `@${member.user.username}` : null

  if (members.length === 1) return memberUsername(members[0])

  return `${memberUsername(members[0])}, ${memberUsername(members[1])}`
}

const getLatestMessage = (channel) => {
  const sets = channel.state.messageSets.filter((set) => set.isLatest)

  if (sets.length === 0) return null

  const { messages } = sets[0]

  if (messages.length === 0) return null

  return messages[messages.length - 1].text
}

const ChannelPreview = ({
  channel,
  onClick,
  onFaveClick,
  userID,
  isActiveChannel,
}) => {
  return (
    <Box
      sx={{
        m: '10px',
        borderRadius: '8px',
        py: '8px',
        px: '12px',
        backgroundColor: isActiveChannel ? 'background.paper' : undefined,
        cursor: isActiveChannel ? 'default' : 'pointer',
        '&:hover': {
          backgroundColor: isActiveChannel ? undefined : 'action.hover',
        },
        outline: (theme) =>
          channel.data.team_reclip_unread
            ? `1px ${theme.palette.primary.main} solid`
            : 'none',
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            fontWeight: 700,
            fontSize: '0.9em',
            margin: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            flex: 1,
          }}
        >
          {channel.data.name || getName(channel, userID)}
        </Box>
        <Box sx={{ fontSize: '11px', color: '#858688' }}>
          {getTimeStamp(channel)}
        </Box>
      </Box>
      <Box sx={{ fontSize: '0.9em', flex: 1, mb: 0.5 }}>
        {getUsername(channel, userID)}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Box
          sx={{
            fontSize: '13px',
            color: '#858688',
            maxWidth: '70%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {getLatestMessage(channel) || 'Send a message'}
        </Box>
        {channel.data.team_reclip_faves ? (
          <FavoriteIcon
            onClick={onFaveClick}
            sx={{
              color: 'primary.main',
              fontSize: '1.3em',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.25)',
              },
            }}
          />
        ) : (
          <FavoriteIconOutlined
            onClick={onFaveClick}
            sx={{
              color: 'primary.main',
              fontSize: '1.3em',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.25)',
              },
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default ChannelPreview
