import { useState, useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import { useModal } from 'store/selectors'
import Button from '@mui/material/Button'
import Select from 'components/core/Select'
import { useAuth } from 'store/selectors'

const PickEditorModal = () => {
  const {
    isOpen,
    params: { onConfirm, staffUsers = [] },
  } = useModal('pickEditor')
  const { user } = useAuth()
  const { closeModal } = useModalsActions()
  const [editorUserId, setEditorUserId] = useState(user.id)

  const doClose = useCallback(() => {
    closeModal('pickEditor')
  }, [closeModal])

  const confirm = useCallback(
    (editorUserId) => {
      onConfirm(editorUserId)
      doClose()
    },
    [onConfirm, doClose]
  )

  return (
    <Dialog
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { backgroundColor: 'background.default' } }}
      onClose={doClose}
    >
      <Box sx={{ p: 4, backgroundColor: 'background.default', width: 350 }}>
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ fontWeight: 700, mb: 2, fontSize: '1.2em' }}>
            Choose Editor
          </Box>
          <Box
            sx={{
              width: 200,
            }}
          >
            <Select
              items={staffUsers.map((user) => ({
                id: user.id,
                label: `${user.username}`,
              }))}
              selectedItemId={editorUserId}
              onSelect={setEditorUserId}
            />
          </Box>
          <Box sx={{ mt: 2.5, textAlign: 'left', fontSize: '0.95em' }}>
            After editing, the editor should share the clip with Team Reclip.{' '}
            The clip can then be deleted from the editor's library.
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={confirm.bind(null, editorUserId)}
          >
            Confirm
          </Button>
          <Button sx={{ ml: 4 }} variant="outlined" onClick={doClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default PickEditorModal
