import axios from 'axios'
import { toTimestamp } from 'components/Main/Networks/utils'
import { ADMIN_API_URL } from 'const'
import queryString from 'query-string'

const BASE_URL = `${ADMIN_API_URL}/networks`

export const getNetworksReport = async (limit = 15) => {
  try {
    const { data: report } = await axios.get(
      `${ADMIN_API_URL}/networks/report?limit=${limit}`
    )
    return report
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

function getNode(nodes, phone) {
  return nodes.find((node) => node.phone_number === phone)
}

function modifiedNetwork(data) {
  const { network, nodes, links } = data

  // add source, target and usernames to links
  links.forEach((link) => {
    link.source = link.phone_numbers[0]
    link.target = link.phone_numbers[1]

    if (link.friendship)
      link.friendship.nodes = [
        getNode(nodes, link.phone_numbers[0]),
        getNode(nodes, link.phone_numbers[1]),
      ]
  })

  // sort nodes so that reclip users are higher z-index
  nodes.sort((a, b) => +a.is_reclip_user - +b.is_reclip_user)

  return { ...network, nodes, links }
}

export const getNetwork = async (networkId) => {
  try {
    const url = `${ADMIN_API_URL}/networks/network_id/${networkId}`
    const { data } = await axios.get(url)
    return modifiedNetwork(data)
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getNetworkByUsername = async (username) => {
  try {
    const params = queryString.stringify({ username })
    const url = `${ADMIN_API_URL}/networks/username?${params}`
    const { data } = await axios.get(url)
    return modifiedNetwork(data)
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getNetworks = async () => {
  try {
    const {
      data: {
        nodes,
        links,
        networks,
        meta: { metrics, ...meta },
        getstream_app_id,
      },
    } = await axios.get(BASE_URL)

    // add source, target and usernames to links
    links.forEach((link) => {
      link.source = link.phone_numbers[0]
      link.target = link.phone_numbers[1]

      if (link.friendship)
        link.friendship.nodes = [
          getNode(nodes, link.phone_numbers[0]),
          getNode(nodes, link.phone_numbers[1]),
        ]
    })

    // sort nodes so that reclip users are higher z-index
    nodes.sort((a, b) => +a.is_reclip_user - +b.is_reclip_user)

    // convert date metrics to timestamps
    metrics.forEach((metric) => {
      if (!metric.is_date) return

      metric.min = toTimestamp(metric.min)
      metric.max = toTimestamp(metric.max)
      nodes.forEach((node) => {
        if (node.is_reclip_user)
          node.metrics[metric.name] = toTimestamp(node.metrics[metric.name])
      })
    })

    return {
      nodes,
      links,
      networks,
      metrics,
      meta,
      getstream_app_id,
    }
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getSuggestedContacts = async (username) => {
  const { data } = await axios.get(`${BASE_URL}/contacts/suggested/${username}`)
  return data
}

export const getUserMetrics = async (username) => {
  try {
    const { data: metrics } = await axios.get(
      `${ADMIN_API_URL}/users/username/${username}/metrics`
    )
    return metrics
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getChatStatistics = async (cid) => {
  try {
    const { data } = await axios.get(`${ADMIN_API_URL}/networks/chat/${cid}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getSuggestions = async (userId) => {
  try {
    const { data } = await axios.get(
      `${ADMIN_API_URL}/networks/suggestions/${userId}`
    )
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
