const MIN_HUE = 240
const MAX_HUE = 360

export function interpolateColor(value, { min, max } = { min: 0, max: 1 }) {
  const ratio = (value - min) / (max - min)
  const hue = MIN_HUE + ratio * (MAX_HUE - MIN_HUE)
  return `hsla(${hue}, 100%, 50%, 100%)`
}

export function toTimestamp(d) {
  return d === null || d === undefined ? d : +new Date(d)
}

export function toDateString(d) {
  return d === null || d === undefined
    ? d
    : new Date(d).toLocaleDateString().replace(/\d\d(\d\d)$/, '$1')
}
