import axios from 'axios'
import { ADMIN_API_URL } from 'const'
import queryString from 'query-string'

export const getEvergreenSuggestions = async ({
  age,
  limit,
  offset,
  register_suggestions,
}) => {
  try {
    const url = `${ADMIN_API_URL}/suggestions/evergreen?${queryString.stringify(
      {
        user_age: age,
        limit,
        offset,
        register_suggestions,
      }
    )}`
    const { data } = await axios.get(url)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
