import { useMemo } from 'react'
import Box from '@mui/material/Box'
import { interpolateColor } from './utils'

const HEADERS = [
  {
    field: 'request_id',
    title: 'ID',
  },
  {
    field: 'start_date',
    title: 'Day',
  },
  {
    field: 'start_time',
    title: 'Started At',
  },
  {
    field: 'end_time',
    title: 'Finished At',
  },
  {
    field: 'tier',
    title: 'Tier',
  },
  {
    field: 'config.model',
    title: 'Model',
  },
  {
    field: 'processing_time',
    title: 'Processing Time (seconds)',
  },
  {
    field: 'audio_duration',
    title: 'Audio Duration (seconds)',
  },
]

function toTimestamp(d) {
  return d === null || d === undefined ? d : +new Date(d)
}

function toDateString(d) {
  if (d === null || d === undefined) return d
  const date = new Date(toTimestamp(d))
  return date.toLocaleDateString().replace(/\d\d(\d\d)$/, '$1')
}

function toTimeString(d) {
  if (d === null || d === undefined) return d
  const date = new Date(toTimestamp(d))
  return date.toLocaleTimeString().replace('AM', 'am').replace('PM', 'pm')
}

const RequestsTable = ({ requests }) => {
  const cleanRequests = useMemo(() => {
    if (!requests) return []

    return requests.map(
      ({
        request_id,
        start_time,
        end_time,
        tier,
        config,
        processing_time,
        audio_duration,
      }) => ({
        request_id: request_id.slice(0, 5),
        start_date: toDateString(start_time),
        start_time: toTimeString(start_time),
        end_time: toTimeString(end_time),
        tier,
        config,
        processing_time: processing_time.toFixed(2),
        audio_duration: audio_duration.toFixed(2),
      })
    )
  }, [requests])

  const { min, max } = useMemo(() => {
    const times = cleanRequests.map((r) => r.processing_time)
    return {
      min: Math.min(...times),
      max: Math.max(...times),
    }
  }, [cleanRequests])

  return (
    <Box
      sx={{
        px: 2,
        pb: 2,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'auto',
        '& table': {
          borderCollapse: 'collapse',
          width: '100%',
        },
        '& th, td': {
          padding: 1,
          textAlign: 'left',
        },
        '& tbody tr:not(:last-of-type) td': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        },
        '& tbody tr td': {
          cursor: 'default',
          // cursor: 'pointer',
        },
        // '& tbody tr:hover td': {
        //   backgroundColor: 'action.hover',
        // },
        '& th': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        },
      }}
    >
      <table>
        <thead>
          <tr>
            {HEADERS.map((header) => (
              <th key={header.field}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {cleanRequests.map(
            ({
              request_id,
              start_date,
              start_time,
              end_time,
              tier,
              config,
              processing_time,
              audio_duration,
            }) => (
              <tr key={request_id}>
                <td>{request_id}</td>
                <td>{start_date}</td>
                <td>{start_time}</td>
                <td>{end_time}</td>
                <td>{tier}</td>
                <td>{config?.model}</td>
                <td>
                  <Box
                    sx={{
                      display: 'inline-block',
                      mr: 2,
                      width: '12px',
                      height: '12px',
                      backgroundColor: interpolateColor(processing_time, {
                        min,
                        max,
                      }),
                      borderRadius: '50%',
                    }}
                  />
                  <Box sx={{ display: 'inline-block' }}>{processing_time}</Box>
                </td>
                <td>{audio_duration}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </Box>
  )
}

export default RequestsTable
