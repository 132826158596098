import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import ThemeProvider from 'theme/ThemeProvider'
import { ToastProvider } from 'hooks/useToast'
import CssBaseline from '@mui/material/CssBaseline'
import store from 'store'
import App from 'components/App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <ToastProvider>
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </ToastProvider>
    </ThemeProvider>
  </Provider>
)
