import React, { useEffect, useRef, useState, useCallback } from 'react'
import {
  /* Avatar, */ useChannelStateContext,
  useChatContext,
} from 'stream-chat-react'

import './MessagingChannelHeader.css'

import TypingIndicator from '../TypingIndicator'

import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

// const getAvatarGroup = (members) => {
//   if (members.length === 1) {
//     return (
//       <div className='messaging__channel-header__avatars'>
//         <Avatar image={getCleanImage(members[0])} name={members[0].user?.id} size={40} />;
//       </div>
//     );
//   }

//   if (members.length === 2) {
//     return (
//       <div className='messaging__channel-header__avatars two'>
//         <span>
//           <Avatar
//             image={getCleanImage(members[0])}
//             name={members[0].user?.id}
//             shape='square'
//             size={40}
//           />
//         </span>
//         <span>
//           <Avatar
//             image={getCleanImage(members[1])}
//             name={members[1].user?.id}
//             shape='square'
//             size={40}
//           />
//         </span>
//       </div>
//     );
//   }

//   if (members.length === 3) {
//     return (
//       <div className='messaging__channel-header__avatars three'>
//         <span>
//           <Avatar
//             image={getCleanImage(members[0])}
//             name={members[0].user?.id}
//             shape='square'
//             size={40}
//           />
//         </span>
//         <span>
//           <Avatar
//             image={getCleanImage(members[1])}
//             name={members[1].user?.id}
//             shape='square'
//             size={20}
//           />
//           <Avatar
//             image={getCleanImage(members[2])}
//             name={members[2].user?.id}
//             shape='square'
//             size={20}
//           />
//         </span>
//       </div>
//     );
//   }

//   if (members.length >= 4) {
//     return (
//       <div className='messaging__channel-header__avatars four'>
//         <span>
//           <Avatar
//             image={getCleanImage(members[members.length - 1])}
//             name={members[0].user?.id}
//             shape='square'
//             size={20}
//           />
//           <Avatar
//             image={getCleanImage(members[members.length - 2])}
//             name={members[1].user?.id}
//             shape='square'
//             size={20}
//           />
//         </span>
//         <span>
//           <Avatar
//             image={getCleanImage(members[members.length - 3])}
//             name={members[2].user?.id}
//             shape='square'
//             size={20}
//           />
//           <Avatar
//             image={getCleanImage(members[members.length - 4])}
//             name={members[3].user?.id}
//             shape='square'
//             size={20}
//           />
//         </span>
//       </div>
//     );
//   }

//   return null;
// };

const MessagingChannelHeader = () => {
  const { client } = useChatContext()
  const { channel } = useChannelStateContext()

  const [channelName, setChannelName] = useState(channel?.data.name || '')
  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState('')

  const inputRef = useRef()

  const members = Object.values(channel.state?.members || {}).filter(
    (member) => member.user?.id !== client.user?.id
  )

  const updateChannel = async (e) => {
    if (e) e.preventDefault()

    if (channelName && channelName !== channel.data.name) {
      await channel.update(
        { name: channelName },
        { text: `Channel name changed to ${channelName}` }
      )
    }

    setIsEditing(false)
  }

  const deleteChannel = useCallback(() => {
    channel.delete()
  }, [channel])

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  useEffect(() => {
    if (!channelName) {
      setTitle(members.map((member) => member.user?.name).join(', '))
    }
  }, [channelName, members])

  const EditHeader = () => (
    <form
      style={{ flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault()
        inputRef.current.blur()
      }}
    >
      <input
        autoFocus
        className="channel-header__edit-input"
        onBlur={updateChannel}
        onChange={(e) => setChannelName(e.target.value)}
        placeholder="Type a new name for the channel"
        ref={inputRef}
        value={channelName}
      />
    </form>
  )

  return (
    <Box
      className="messaging-channel-header"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '60px',
        paddingLeft: '20px',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'divider',
      }}
    >
      {/* {getAvatarGroup(members)} */}
      {!isEditing ? (
        <div className="channel-header__name">{channelName || title}</div>
      ) : (
        <EditHeader />
      )}
      <div className="messaging__channel-header__right">
        <TypingIndicator />
        {!isEditing ? (
          <IconButton
            title="Edit Channel Name"
            sx={{ marginLeft: 2 }}
            onClick={() => setIsEditing(true)}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton
            title="Save Channel Name"
            sx={{ marginLeft: 2 }}
            onClick={() => setIsEditing(false)}
          >
            <SaveIcon />
          </IconButton>
        )}
        <IconButton
          title="Delete Channel"
          sx={{ marginLeft: 2 }}
          onClick={deleteChannel}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </Box>
  )
}

export default React.memo(MessagingChannelHeader)
