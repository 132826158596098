import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export default function BasicDatePicker({ value, setValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={['DatePicker']}
        sx={{
          pt: 0,
          '& .MuiInputBase-input': {
            py: 1,
          },
        }}
      >
        <DatePicker
          label={null}
          value={value}
          onChange={(newValue) => setValue(newValue)}
          timezone="UTC"
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}
