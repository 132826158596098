import { useCallback } from 'react'
import Box from '@mui/material/Box'

const ShareSelector = ({ share, shares, setShare }) => {
  const handleChange = useCallback(
    (e) => {
      const newShare = shares.find((s) => s.code === e.target.value)
      setShare(newShare)
    },
    [shares, setShare]
  )

  return (
    <Box
      sx={{
        p: 0.75,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          minWidth: '180px',
          color: 'text.primary',
          fontWeight: 700,
        },
      }}
    >
      <select onChange={handleChange} value={share.code}>
        {shares.map((s) => (
          <option key={s.code} value={s.code}>
            {s.code + (s.is_from_story ? ' (story)' : '')}
          </option>
        ))}
      </select>
    </Box>
  )
}

export default ShareSelector
