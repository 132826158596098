import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import Player from 'components/core/SharePlayer'

const SharePlayer = () => {
  const params = useParams()

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 'calc(90vh * 9 / 16)',
          height: '90vh',
          overflow: 'hidden',
        }}
      >
        <Player shareCode={params.share_code} />
      </Box>
    </Box>
  )
}

export default SharePlayer
