import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Loader from 'components/core/Loader'

export const NoChannels = ({ filterType }) => (
  <Box sx={{ m: 4, textAlign: 'center' }}>
    {(() => {
      switch (filterType) {
        case 'all':
          return 'No channels'
        case 'unread':
          return 'No unread channels'
        case 'faves':
          return 'No channels'
        default:
          return ''
      }
    })()}
  </Box>
)

export const ChannelLoader = ({ error, onRetry }) =>
  error ? (
    <Box sx={{ m: 4, textAlign: 'center' }}>
      <Box sx={{ mb: 2 }}>Load channels failed: {error}</Box>
      <Button onClick={onRetry}>Retry</Button>
    </Box>
  ) : (
    <Box sx={{ my: 4 }}>
      <Loader size={20} text="Loading channels" />
    </Box>
  )
