import Box from '@mui/material/Box'
import { useMemo } from 'react'
import useNetworksActions from 'store/actions/networks'
import VirtualizedList from 'components/core/VirtualizedList'
import Avatar from 'components/core/Avatar'

const NetworkUsers = ({ nodes, emptyText }) => {
  const { highlightNodes, selectNode } = useNetworksActions()

  const sortedNodes = useMemo(() => {
    if (!nodes) return []
    return nodes.sort((a, b) =>
      (a.user?.username?.toLowerCase() || 'zzz') >
      (b.user?.username?.toLowerCase() || 'zzz')
        ? 1
        : -1
    )
  }, [nodes])

  const RowComponent = ({ item: node }) => {
    if (!node.user) return null
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          px: 1,
          height: '45px',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
        onMouseEnter={highlightNodes.bind(null, [node.id])}
        onMouseLeave={highlightNodes.bind(null, [])}
        onClick={() => {
          selectNode(node.id)
          highlightNodes([])
        }}
      >
        <Avatar
          name={node.user.full_name}
          image={node.user.picture}
          size={30}
        />
        <Box sx={{ ml: 2, minWidth: '180px' }}>@{node.user.username}</Box>
        <Box>{node.user.full_name}</Box>
      </Box>
    )
  }

  if (!sortedNodes) return null

  return (
    <Box sx={{ p: 2, flex: 1 }}>
      <VirtualizedList
        items={sortedNodes}
        RowComponent={RowComponent}
        emptyText={emptyText}
        divider
      />
    </Box>
  )
}

export default NetworkUsers
