import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'

const TYPES = [
  'any user',
  'any user who was in their first week on Reclip',
  'any user who was NOT in their first week on Reclip',
]

const ShareDayFilter = ({ filters, setFilters }) => {
  const [value, setValue] = useState(TYPES[0])

  const handleChange = useCallback(
    (e) => {
      setValue(e.target.value)

      switch (e.target.value) {
        case TYPES[0]:
          return setFilters((f) => ({
            ...f,
            share_day_min: null,
            share_day_max: null,
          }))
        case TYPES[1]:
          return setFilters((f) => ({
            ...f,
            share_day_min: null,
            share_day_max: 9,
          }))
        case TYPES[2]:
          return setFilters((f) => ({
            ...f,
            share_day_min: 9,
            share_day_max: null,
          }))
        default:
          return
      }
    },
    [setFilters]
  )

  // const value =
  //   filters.share_day_min === null && filters.share_day_max === null
  //     ? TYPES[0]
  //     : TYPES[1]

  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          minWidth: '400px',
          color: 'text.primary',
          fontWeight: 700,
        },
      }}
    >
      <select onChange={handleChange} value={value}>
        {TYPES.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
    </Box>
  )
}

export default ShareDayFilter
