import Box from '@mui/material/Box'

const Pulse = () => (
  <Box
    sx={{
      display: 'inline-block',
      '& .pulse': {
        width: '10px',
        height: '10px',
        backgroundColor: 'aqua',
        animation: 'pulse-animation 2s infinite',
        borderRadius: '50%',
        boxShadow: '0px 0px 1px 1px #0000001a',
      },
    }}
  >
    <Box className="circle pulse orange" />
  </Box>
)

export default Pulse
