import Box from '@mui/material/Box'
import Suggestion from './Suggestion'

const SuggestionList = ({ suggestions, addFriend }) => {
  return (
    <Box sx={{ height: '100%', overflow: 'auto' }}>
      {suggestions.map((suggestion, index) => (
        // <Box key={index.toString()}>{suggestion.username}</Box>
        <Suggestion
          key={index.toString()}
          suggestion={suggestion}
          addFriend={addFriend.bind(null, suggestion.id)}
        />
      ))}
    </Box>
  )
}

export default SuggestionList
