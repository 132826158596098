import React, { useState } from 'react'
import { Channel as StreamChannel } from 'stream-chat-react'
import CustomMessage from './CustomMessage'
import ChannelInner from './ChannelInner'
import MessagingInput from './MessagingInput'
import MessagingThreadHeader from './MessagingThreadHeader'

export const GiphyContext = React.createContext({})

const Channel = ({ inputOnly = false }) => {
  const [giphyState, setGiphyState] = useState(false)
  const giphyContextValue = { giphyState, setGiphyState }

  return (
    <StreamChannel
      Input={MessagingInput}
      maxNumberOfFiles={10}
      Message={CustomMessage}
      multipleUploads={true}
      ThreadHeader={MessagingThreadHeader}
      TypingIndicator={() => null}
    >
      <GiphyContext.Provider value={giphyContextValue}>
        <ChannelInner inputOnly={inputOnly} />
      </GiphyContext.Provider>
    </StreamChannel>
  )
}

export default Channel
