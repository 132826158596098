import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export default function BasicDatePicker({ value, onChange, label }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={['DatePicker']}
        sx={{
          // pt: 0,
          '& .MuiInputBase-input': {
            // py: 1,
            // maxWidth: 150,
          },
        }}
      >
        <DatePicker
          label={label}
          value={value}
          onChange={onChange}
          timezone="UTC"
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}
