import { useEffect, useState, useCallback } from 'react'
import api from 'services/api'
import Player from 'components/core/Player'
import Loader from 'components/core/Loader'
import { ADMIN_API_URL } from 'const'
import { useAuth } from 'store/selectors'
import useToast from 'hooks/useToast'

const ReclipLoader = ({ source_id }) => {
  const [reclip, setReclip] = useState(null)
  const [audioUrl, setAudioUrl] = useState(null)
  const [error, setError] = useState(null)
  const { token } = useAuth()
  const [isSaving, setIsSaving] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (!source_id) return setError('Source ID not provided.')

    api.reclips
      .getReclip(source_id)
      .then(setReclip)
      .catch((e) => setError(e.message))
  }, [source_id])

  useEffect(() => {
    if (!source_id) return setError('Source ID not provided.')

    const audioUrl = `${ADMIN_API_URL}/reclips/${source_id}/audio?trim=0`
    fetch(audioUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          return response.json().then((data) => {
            return Promise.reject(data?.detail)
          })
        } else return response.blob()
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob)
        setAudioUrl(blobUrl)
      })
      .catch((err) => setError(err))
  }, [source_id, token])

  const onSave = useCallback(async () => {
    if (!reclip) return

    setIsSaving(true)
    toast({
      severity: 'success',
      message: 'Creating video. This may take a minute...',
      autoHideDuration: 5000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    })
    try {
      const videoUrl = await api.shareVideos.getShareVideoFromSourceId(
        reclip.source_id
      )
      window.open(videoUrl, '_blank')
    } catch (e) {
      toast({
        severity: 'error',
        message: e.toString(),
      })
    } finally {
      setIsSaving(false)
    }
  }, [reclip, toast])

  if (!reclip || !audioUrl) return <Loader error={error} />

  return (
    <Player
      reclip={reclip}
      audioUrl={audioUrl}
      onSave={onSave}
      isSaving={isSaving}
    />
  )
}

export default ReclipLoader
