import React, { useEffect, useState } from 'react'
import { StreamChat } from 'stream-chat'
import { useChat } from 'store/selectors'
import useChatActions from 'store/actions/chat'
import Main from './Main'
import Loader from 'components/core/Loader'

const ChatLoader = () => {
  const { data: chat, error } = useChat()
  const { getChat } = useChatActions()
  const [client, setClient] = useState(null)

  useEffect(() => {
    if (!chat) getChat()
  }, [chat, getChat])

  useEffect(() => {
    if (!chat) return

    const client = StreamChat.getInstance(chat.api_key, {
      enableInsights: true,
      enableWSFallback: true,
      timeout: 5000,
    })

    const connectUser = async () => {
      const { id, name, username, image, is_broadcasting } = chat.user

      await client.connectUser(
        {
          id,
          name,
          username,
          image,
          is_broadcasting,
        },
        chat.token
      )
      setClient(client)
    }

    connectUser()

    return () => client.disconnectUser()
  }, [chat])

  if (!client) return <Loader text="Loading Chat" error={error} />

  return <Main client={client} />
}

export default ChatLoader
