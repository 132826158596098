import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { ADMIN_API_URL } from 'const'

let refresh = null

createAuthRefreshInterceptor(axios, (failedRequest) => {
  const refreshUrl = `${ADMIN_API_URL}/refresh?refresh_token=${refresh}`
  return axios.get(refreshUrl).then(({ data: token }) => {
    failedRequest.response.config.headers['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return Promise.resolve()
  })
})

export const setTokens = (token, refreshToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  refresh = refreshToken
}

export const deleteTokens = () => {
  delete axios.defaults.headers.common['Authorization']
  refresh = null
}
