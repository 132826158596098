import { useMemo, useCallback } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { useLocation } from 'react-router-dom'

const ENVIRONMENTS = [
  {
    name: 'Production',
    abbrev: 'P',
    url: 'https://admin.reclip.app',
  },
  {
    name: 'Staging',
    abbrev: 'S',
    url: 'https://staging-admin.reclip.app',
  },
  {
    name: 'Develop',
    abbrev: 'D',
    url: 'https://develop-admin.reclip.app',
  },
  {
    name: 'Test',
    abbrev: 'T',
    url: 'https://test-admin.reclip.app',
  },
  ...(window.location.origin.includes('localhost')
    ? [
        {
          name: 'Local',
          abbrev: 'L',
          url: window.location.origin,
        },
      ]
    : []),
]

function getEnv() {
  return ENVIRONMENTS.find((env) => env.url === window.location.origin)
}

export default function EnvironmentToggle() {
  const env = useMemo(getEnv, [])
  const location = useLocation()

  const handleChange = useCallback(
    (e) => {
      const newEnv = ENVIRONMENTS.find((env) => env.name === e.target.value)
      const url = `${newEnv.url}${location.pathname}${location.search}`
      window.open(url, '_blank')
    },
    [location]
  )

  if (!env) return null

  return (
    <>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '60px',
          pr: '16px',
          '& > select': {
            backgroundColor: 'transparent',
            color: 'text.primary',
            border: 0,
            outline: 0,
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
          },
        }}
      >
        <Box
          sx={{
            minWidth: '68px',
            fontSize: '20px',
            fontWeight: 'bold',
            pl: '22px',
          }}
        >
          {env?.abbrev}
        </Box>
        <select value={env?.name || ''} onChange={handleChange}>
          {ENVIRONMENTS.map((e) => (
            <option key={e.name} value={e.name}>
              {e.name}
            </option>
          ))}
        </select>
      </Box>
    </>
  )
}
