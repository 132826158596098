import Box from '@mui/material/Box'
import { useMemo } from 'react'
import CopyToClipboard from 'components/core/CopyToClipboard'
import TooltipIcon from 'components/core/Tooltip'
import { useNetworksMeta } from 'store/selectors'
import ChatIcon from '@mui/icons-material/Chat'
import ChatHistory from './ChatHistory'

const ChatInfo = ({ link: { friendship, chat_history } }) => {
  const { getstream_app_id } = useNetworksMeta()

  const getstreamUrl = useMemo(() => {
    return (
      'https://dashboard.getstream.io/' +
      `app/${getstream_app_id}/chat/explorer` +
      `?path=channels,channel~${friendship.getstream_cid}`
    )
  }, [getstream_app_id, friendship.getstream_cid])

  return (
    <Box>
      <Box
        sx={{
          fontWeight: 700,
          fontSize: '1.3em',
          textAlign: 'center',
          mb: 0,
          mt: 2,
        }}
      >
        Chat
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CopyToClipboard
          data={friendship.getstream_cid}
          title="copy getstream cid"
        />
        <TooltipIcon
          Icon={ChatIcon}
          title="view channel in getstream"
          onClick={() => window.open(getstreamUrl, '_blank')}
        />
      </Box>
      <ChatHistory history={chat_history} />
    </Box>
  )
}

export default ChatInfo
