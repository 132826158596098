import Box from '@mui/material/Box'
import { useMemo } from 'react'
import { isDate, toDateString } from 'services/utils'
import Avatar from 'components/core/Avatar'
import CopyToClipboard from 'components/core/CopyToClipboard'
import TooltipIcon from 'components/core/Tooltip'
import ChatIcon from '@mui/icons-material/Chat'
import { useNetworksMeta } from 'store/selectors'

function getAge(birthdayStr) {
  const birthday = new Date(birthdayStr)
  if (!isDate(birthday)) return null
  const ageDifMs = Date.now() - birthday
  const ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

const UserInfo = ({ user, onClick, onMouseEnter, onMouseLeave }) => {
  const { getstream_app_id } = useNetworksMeta()

  const getstreamUrl = useMemo(() => {
    return (
      'https://dashboard.getstream.io/' +
      `app/${getstream_app_id}/chat/explorer` +
      `?path=users,user~${user.id}`
    )
  }, [getstream_app_id, user.id])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Avatar
        name={user.full_name}
        image={user.picture}
        size={60}
        enlargeOnHover
      />

      <Box
        sx={{
          textAlign: 'center',
          width: 180,
          fontSize: '1.1em',
          mt: 1,
        }}
      >
        <Box
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          sx={{
            fontWeight: 700,
            '&:hover': {
              textDecoration: !!onMouseEnter ? 'underline' : 'none',
              cursor: !!onMouseEnter ? 'pointer' : 'default',
            },
          }}
        >
          @{user.username}
        </Box>
        <Box sx={{ mt: 1, fontSize: '0.9em', textAlign: 'center' }}>
          <Box>{user.full_name}</Box>
          <Box>{getAge(user.date_of_birth)} years old</Box>
          <Box>Joined {toDateString(user.created_at)}</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CopyToClipboard data={user.id} title="copy user id" />
          <TooltipIcon
            Icon={ChatIcon}
            title="view user in getstream"
            onClick={() => window.open(getstreamUrl, '_blank')}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default UserInfo
