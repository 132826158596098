import api from 'services/api'
import useActions from 'hooks/useActions'

export const types = {
  GET_CHAT_SUCCESS: 'chat/GET_CHAT_SUCCESS',
  GET_CHAT_FAILURE: 'chat/GET_CHAT_FAILURE',
  SET_SELECTED_USER: 'chat/SET_SELECTED_USER',
}

export const getChat = () => {
  return async (dispatch) => {
    try {
      const chat = await api.chat.getChat()
      dispatch({
        type: types.GET_CHAT_SUCCESS,
        data: chat,
      })
    } catch (error) {
      dispatch({
        type: types.GET_CHAT_FAILURE,
        data: error.message,
      })
    }
  }
}

export const setSelectedUser = (user) => ({
  type: types.SET_SELECTED_USER,
  data: user,
})

export default useActions.bind(null, {
  getChat,
  setSelectedUser,
})

const initialState = {
  data: null,
  error: null,
  selectedUser: null,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CHAT_SUCCESS:
      return {
        data: action.data,
        error: null,
      }

    case types.GET_CHAT_FAILURE:
      return {
        data: null,
        error: action.data,
      }

    case types.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.data,
      }

    default:
      return state
  }
}
