import { useState, useEffect, useMemo, useCallback } from 'react'
import Box from '@mui/material/Box'
import api from 'services/api'
import Cloud from './Cloud'
import Filters from './Filters'
import NamedDivider from 'components/core/NamedDivider'
import Counts from './Counts'
import Loader from 'components/core/Loader'
import Search from './Search'

const INITIAL_FILTERS = {
  startDate: null,
  endDate: null,
  limit: 100,
}

const Tags = ({ meta }) => {
  const [tags, setTags] = useState(null)
  const [tagType, setTagType] = useState(Object.keys(meta)[0])
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const [isLoading, setIsLoading] = useState(true)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [searchTag, setSearchTag] = useState(null)

  const getTagCounts = useCallback(async () => {
    if (filters.startDate === null || filters.endDate === null) return
    setIsLoading(true)
    const tags = await api.openai.getTagCounts(filters)
    setTags(tags)
    setIsLoading(false)
  }, [filters])

  const searchTags = useCallback((tag) => {
    setIsSearchOpen(true)
    setSearchTag(tag)
  }, [])

  useEffect(() => {
    getTagCounts()
  }, [getTagCounts])

  const filteredTags = useMemo(() => {
    if (!tags) return []
    return tags[tagType] || []
  }, [tags, tagType])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        position: 'relative',
      }}
    >
      <Box sx={{ flex: 1 }}>
        {isLoading && <Loader title="loading tags" />}
        {!isLoading && filteredTags.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              fontSize: '1.5em',
            }}
          >
            No data available for selected filters.
          </Box>
        )}
        {!isLoading && filteredTags.length > 0 && (
          <Cloud tags={filteredTags} onClick={searchTags} />
        )}
      </Box>
      <Box
        sx={{
          borderWidth: 1,
          borderLeftStyle: 'solid',
          borderLeftColor: 'divider',
          py: 2,
          width: 350,
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            fontSize: '1.4em',
            textAlign: 'center',
            fontWeight: 700,
            mx: 2,
          }}
        >
          Reclip Tags
        </Box>
        <NamedDivider title="filters" sx={{ m: 2 }} />
        <Box sx={{ mx: 2 }}>
          <Filters
            tagTypes={Object.keys(meta)}
            tagType={tagType}
            onChangeTagType={setTagType}
            filters={filters}
            onChangeFilters={setFilters}
          />
        </Box>
        <NamedDivider title="counts" sx={{ m: 2 }} />
        <Box sx={{ flex: 1, position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              overflow: 'auto',
              ml: 2,
            }}
          >
            <Counts tags={filteredTags} onClick={searchTags} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          pointerEvents: 'none',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            opacity: isSearchOpen ? 1 : 0,
            transition: 'opacity 0.5s ease',
            pointerEvents: isSearchOpen ? 'all' : 'none',
            cursor: isSearchOpen ? 'zoom-out' : 'default',
          }}
          onClick={isSearchOpen ? setIsSearchOpen.bind(null, false) : undefined}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 700,
            backgroundColor: 'background.default',
            transform: isSearchOpen ? 0 : 'translateX(-100%)',
            transition: 'all 0.5s ease',
            pointerEvents: 'all',
            cursor: 'default',
            zIndex: 1,
          }}
        >
          <Search tag={searchTag} tagType={tagType} filters={filters} />
        </Box>
      </Box>
    </Box>
  )
}

const LoadMeta = () => {
  const [meta, setMeta] = useState(null)

  useEffect(() => {
    api.openai.getTagsMeta().then(setMeta)
  }, [])

  return meta ? <Tags meta={meta} /> : null
}

export default LoadMeta
