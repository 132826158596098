import api from 'services/api'
import useActions from 'hooks/useActions'

export const types = {
  GET_DEEPGRAM_DATA_SUCCESS: 'deepgram/GET_DEEPGRAM_DATA_SUCCESS',
  GET_DEEPGRAM_DATA_FAILURE: 'deepgram/GET_DEEPGRAM_DATA_FAILURE',
  UPDATE_DEEPGRAM_DATA_SUCCESS: 'deepgram/UPDATE_DEEPGRAM_DATA_SUCCESS',
  UPDATE_DEEPGRAM_DATA_FAILURE: 'deepgram/UPDATE_DEEPGRAM_DATA_FAILURE',
}

export const getDeepgramData = (params) => {
  return async (dispatch) => {
    try {
      const deepgramData = await api.deepgram.getDeepgramData(params)
      dispatch({
        type: types.GET_DEEPGRAM_DATA_SUCCESS,
        data: deepgramData,
      })
    } catch (error) {
      dispatch({
        type: types.GET_DEEPGRAM_DATA_FAILURE,
        data: error.message,
      })
    }
  }
}

export const updateDeepgramData = () => {
  return async (dispatch, getState) => {
    const latestRequest = getState().deepgram.data[0]
    try {
      const newData = await api.deepgram.getDeepgramData({
        start: latestRequest.start_time,
      })
      dispatch({
        type: types.UPDATE_DEEPGRAM_DATA_SUCCESS,
        data: newData,
      })
    } catch (error) {
      dispatch({
        type: types.UPDATE_DEEPGRAM_DATA_FAILURE,
        data: error.message,
      })
    }
  }
}

export default useActions.bind(null, {
  getDeepgramData,
  updateDeepgramData,
})

function dedupe(list, dedupeKey) {
  const keys = {}
  const deduped = []
  list.forEach((element) => {
    const key = dedupeKey(element)
    if (!keys[key]) {
      keys[key] = true
      deduped.push(element)
    }
  })
  return deduped
}

const initialState = {
  data: null,
  error: null,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEEPGRAM_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        error: null,
      }

    case types.GET_DEEPGRAM_DATA_FAILURE:
      return {
        ...state,
        data: null,
        error: action.data,
      }

    case types.UPDATE_DEEPGRAM_DATA_SUCCESS:
      return {
        ...state,
        data: dedupe([...action.data, ...state.data], (el) => el.request_id),
        error: null,
      }

    case types.UPDATE_DEEPGRAM_DATA_FAILURE:
      return {
        ...state,
        error: action.data,
      }

    default:
      return state
  }
}
