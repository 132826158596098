// demo
// https://wordcloud2-js.timdream.org/#

// docs
// https://github.com/timdream/wordcloud2.js/blob/gh-pages/API.md

import { useEffect, useRef } from 'react'
import WordCloud from 'wordcloud'
import Box from '@mui/material/Box'
import { interpolate } from 'components/Main/Networks/utils'

import { COLOR_SCHEMES, randomColor } from './colors'

const Cloud = ({ tags, onClick }) => {
  const cloudRef = useRef(null)

  useEffect(() => {
    if (tags.length === 0) return

    const minCount = tags[tags.length - 1][1]
    const maxCount = tags[0][1]

    const { offsetWidth: width } = cloudRef.current

    const minFontSize = 14
    const maxFontSize = Math.min(180 * (width / 1400), 180)

    const data = tags.map((tag) => [...tag])

    WordCloud(cloudRef.current, {
      list: data,
      backgroundColor: 'transparent',
      gridSize: 25,
      weightFactor: (size) => {
        if (maxCount === minCount) return 2 * minFontSize
        return interpolate(
          size,
          { min: minCount, max: maxCount },
          { min: minFontSize, max: maxFontSize }
        )
      },
      click: (tag) => onClick(tag[0]),
      color: () => randomColor(COLOR_SCHEMES.pastel),
      classes: 'tag',
      drawOutOfBound: false,
      shrinkToFit: true,
      fontFamily: 'sans-serif',
      rotateRatio: 0,
    })
  }, [tags, onClick])

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'hidden',
        p: 4,
        '& > #cloud': {
          width: '100%',
          height: '100%',
        },
        backgroundColor: 'background.default',
        '& > #cloud > .tag': {
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}
    >
      <div id="cloud" ref={cloudRef} />
    </Box>
  )
}

export default Cloud
