import { useState, useCallback, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import SearchIcon from '@mui/icons-material/Search'
import api from 'services/api'
import { useAuth } from 'store/selectors'
import Highlighter from 'react-highlight-words'
import Preview from './Preview'
import { toDateString } from '../Networks/utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import CopyToClipboardButton from 'components/core/CopyToClipboard'
import OpenLinkIcon from '@mui/icons-material/OpenInNew'
import TooltipIcon from 'components/core/Tooltip'
import LoadingButton from '@mui/lab/LoadingButton'
import findChunksAutoComplete from './findChunksAutocomplete'

const HEADER_HEIGHT = 122

const Search = () => {
  const [searchParams, setSearchParams] = useState({
    searchTerm: '',
    page: 0,
    limitSearchToUser: false,
  })

  const [items, setItems] = useState([])
  const [results, setResults] = useState(null)
  const { user } = useAuth()
  const scrollableDiv = useRef(null)
  const [roundTripTime, setRoundTripTime] = useState(null)
  const [isExporting, setIsExporting] = useState(false)

  useEffect(() => {
    const { searchTerm, page, limitSearchToUser } = searchParams

    const now = Date.now()

    api.search
      .searchReclips(searchTerm, page, limitSearchToUser)
      .then((results) => {
        setResults(results)
        setItems((items) => {
          if (page === 0) {
            scrollableDiv.current.scrollTop = 0
            return results.hits
            // return [results.hits[0]]
          } else {
            return [...items, ...results.hits]
          }
        })
        setRoundTripTime(Date.now() - now)
      })
  }, [searchParams])

  const handleSearchTermChange = useCallback((e) => {
    setSearchParams((params) => ({
      ...params,
      searchTerm: e.target.value,
      page: 0,
    }))
  }, [])

  const handleToggleLimit = useCallback((event) => {
    setSearchParams((params) => ({
      ...params,
      limitSearchToUser: event.target.checked,
      page: 0,
    }))
  }, [])

  const handleNextPage = useCallback(() => {
    setSearchParams((params) => ({
      ...params,
      page: params.page + 1,
    }))
  }, [])

  const exportReclips = useCallback(async () => {
    setIsExporting(true)
    const url = await api.search.exportReclips(
      searchParams.searchTerm,
      searchParams.limitSearchToUser
    )
    setIsExporting(false)
    window.open(url, '_blank')
  }, [searchParams])

  return (
    <Box sx={{ height: '100%', overflow: 'hidden', width: '100%' }}>
      <Box
        sx={{
          width: '100%',
          height: `${HEADER_HEIGHT}px`,
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        }}
      >
        <Box
          sx={{
            px: 2,
            backgroundColor: 'background.paper',
            display: 'flex',
            alignItems: 'center',
            '& > input': {
              ml: 2,
              height: '60px',
              flex: 1,
              border: 0,
              outline: 0,
              backgroundColor: 'transparent',
              color: 'text.primary',
              fontSize: '20px',
            },
          }}
        >
          <SearchIcon sx={{ fontSize: 32 }} />
          <input
            type="text"
            value={searchParams.searchTerm}
            onChange={handleSearchTermChange}
            placeholder={
              searchParams.limitSearchToUser
                ? `search @${user.username}'s reclips`
                : 'search reclips'
            }
            autoFocus
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>Search @{user.username}'s reclips only</Box>
            <Checkbox
              sx={{ ml: 0.5 }}
              checked={searchParams.limitSearchToUser}
              onChange={handleToggleLimit}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>
        {results !== null && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              fontStyle: 'italic',
            }}
          >
            <Box sx={{ fontStyle: 'italic' }}>
              Showing {items.length} {items.length === 1 ? 'reclip' : 'reclips'}{' '}
              out of {results.num_hits} (in {roundTripTime} ms)
            </Box>
            <LoadingButton
              sx={{ fontStyle: 'normal' }}
              size="small"
              variant="contained"
              onClick={exportReclips}
              loading={isExporting}
              disabled={
                searchParams.searchTerm.trim() === '' || items.length === 0
              }
            >
              Export
            </LoadingButton>
          </Box>
        )}
      </Box>
      <Box
        sx={{ height: `calc(100% - ${HEADER_HEIGHT}px)`, overflow: 'auto' }}
        id="scrollableDiv"
        ref={scrollableDiv}
      >
        {results !== null && (
          <InfiniteScroll
            dataLength={items.length}
            next={items.length === 0 ? undefined : handleNextPage}
            hasMore={items.length < results.num_hits}
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.6}
          >
            {items.map((item, index) => (
              <Box
                sx={{
                  p: 2,
                  borderBottomColor: 'divider',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  display: 'flex',
                }}
                key={index.toString()}
              >
                <Preview reclip={item} />
                <Box sx={{ flex: 1, ml: 2 }}>
                  <Box>
                    <b>Title: </b>
                    {(item.title || item.title_auto) && (
                      <Highlighter
                        searchWords={[searchParams.searchTerm]}
                        textToHighlight={item.title || item.title_auto}
                        findChunks={findChunksAutoComplete}
                      />
                    )}
                  </Box>
                  <Box>
                    <b>Username: </b>@{item.username}
                  </Box>
                  <Box>
                    <b>Created on: </b>
                    {toDateString(item.created_at)}
                  </Box>
                  <Box>
                    <b>Transcript </b>
                    <CopyToClipboardButton
                      title="Copy transcript"
                      data={item.text}
                    />
                    <br />
                    {item.text && (
                      <Highlighter
                        searchWords={[searchParams.searchTerm]}
                        textToHighlight={item.text}
                        findChunks={findChunksAutoComplete}
                      />
                    )}
                  </Box>
                </Box>
                <Box sx={{ mx: 1 }}>
                  <TooltipIcon
                    Icon={OpenLinkIcon}
                    title="watch clip"
                    onClick={() => {
                      window.open(
                        window.location.origin + '/r/' + item.source_id
                      )
                    }}
                  />
                  <Box sx={{ mt: 1 }}>
                    <CopyToClipboardButton
                      title="Copy id"
                      data={item.source_id}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  )
}

export default Search
