import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DatePicker from './DatePicker'

const DateRangePicker = ({ initialStartDate, initialEndDate, onSubmit }) => {
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)
  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mx: 4,
        width: 200,
      }}
    >
      <DatePicker label="from" value={startDate} onChange={setStartDate} />
      <Box sx={{ height: 12 }} />
      <DatePicker label="to" value={endDate} onChange={setEndDate} />
      <Button
        variant="outlined"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => onSubmit({ startDate, endDate })}
      >
        Search
      </Button>
    </Box>
  )
}

export default DateRangePicker
