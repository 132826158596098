import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate /*, useLocation */ } from 'react-router-dom'

const TABS = [
  {
    path: '/chat/welcome/list',
    title: 'Welcome Messages',
  },
  // {
  //   path: '/chat/welcome/history',
  //   title: 'Welcome History',
  // },
]

const Header = () => {
  const navigate = useNavigate()
  // const { pathname } = useLocation()
  return (
    <Box
      sx={{
        px: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          fontWeight: '700',
        }}
      >
        {TABS.map((tab) => (
          <Box
            key={tab.path}
            // onClick={navigate.bind(null, tab.path)}
            sx={{
              // textDecoration: pathname === tab.path ? 'underline' : 'none',
              pr: 2,
              // cursor: 'pointer',
              // '&:hover': {
              //   textDecoration: 'underline',
              // },
            }}
          >
            {tab.title}
          </Box>
        ))}
      </Box>
      <IconButton onClick={navigate.bind(null, '/chat')}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default Header
