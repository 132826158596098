import { MIN_METRIC_HUE, MAX_METRIC_HUE } from './config'

export function interpolateColor(value, { min, max } = { min: 0, max: 1 }) {
  const ratio = min === max ? 0 : (value - min) / (max - min)
  const hue = MIN_METRIC_HUE + ratio * (MAX_METRIC_HUE - MIN_METRIC_HUE)
  return `hsla(${hue}, 100%, 50%, 100%)`
}

export function colorString(color) {
  const { hue, saturation, lightness, alpha } = color
  return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`
}

export function colorArray(
  { hueRange: [min, max], saturation, lightness, alpha },
  numColors
) {
  if (numColors < 1) return []

  const inc = numColors === 1 ? 0 : (max - min) / (numColors - 1)
  return Array.from({ length: numColors }).map((_, idx) =>
    colorString({
      hue: min + inc * idx,
      saturation,
      lightness,
      alpha,
    })
  )
}

export function hueGradient({
  direction = 'right',
  hueRange = [MIN_METRIC_HUE, MAX_METRIC_HUE],
  saturation = 100,
  lightness = 50,
  alpha = 1,
  steps = 20,
} = {}) {
  return `
    linear-gradient(
      to ${direction},
        ${colorArray(
          {
            hueRange,
            saturation,
            lightness,
            alpha,
          },
          steps
        )}
    )
  `
}

export function interpolate(value, domain, range) {
  const slope = (range.max - range.min) / (domain.max - domain.min)
  return range.min + slope * (value - domain.min)
}

export function toDateString(d) {
  return d === null || d === undefined
    ? d
    : new Date(d).toLocaleDateString().replace(/\d\d(\d\d)$/, '$1')
}

export function toTimestamp(d) {
  return d === null || d === undefined ? d : +new Date(d)
}

export function getBounds(elements, padding = 0.5) {
  const xDomain = elements.map(({ x }) => x)
  const yDomain = elements.map(({ y }) => y)

  return {
    xMin: Math.min(...xDomain),
    yMin: Math.min(...yDomain),
    xMax: Math.max(...xDomain),
    yMax: Math.max(...yDomain),
    padding,
  }
}
