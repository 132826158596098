import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'

const ReclipPreview = ({ reclip }) => {
  const { openModal } = useModalsActions()
  const { color1, color2 } = (() => {
    if (!reclip) return { color1: 'black', color2: 'black' }
    if (reclip.background) return reclip.background
    return {
      color1: reclip.backgroundColor1,
      color2: reclip.backgroundColor2,
    }
  })()

  const hasCoverPhoto = !!reclip?.coverPhoto

  return (
    <Box
      onClick={openModal.bind(null, 'reclip', { reclip })}
      sx={{
        width: 150,
        height: 200,
        background: hasCoverPhoto
          ? undefined
          : `linear-gradient(to top, ${color1}, ${color2})`,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 3,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 700,
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      {hasCoverPhoto && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderRadius: 2,
            backgroundImage: `url(${reclip.coverPhoto.url})`,
            backgroundSize: 'cover',
          }}
        />
      )}
      {reclip ? reclip.title || 'New Reclip' : null}
    </Box>
  )
}

export default ReclipPreview
