import { useState } from 'react'
import Box from '@mui/material/Box'
import { useSelectedUserProfile } from 'store/selectors'
import UserInfo from 'components/Main/Networks/Sidebar/core/UserInfo'
import Friends from './Friends'
import Contacts from './Contacts'
import Metrics from './Metrics'
import Suggestions from './Suggestions'
import FriendsIcon from '@mui/icons-material/People'
import ContactsIcon from '@mui/icons-material/Phone'
import MetricsIcon from '@mui/icons-material/BarChart'
import SuggestionsIcon from '@mui/icons-material/Psychology'

import Tabs from '../../core/Tabs'

const TABS = [
  {
    type: 'contacts',
    Icon: ContactsIcon,
    Component: Contacts,
  },
  {
    type: 'friends',
    Icon: FriendsIcon,
    Component: Friends,
  },
  {
    type: 'metrics',
    Icon: MetricsIcon,
    Component: Metrics,
  },
  {
    type: 'suggestions',
    Icon: SuggestionsIcon,
    Component: Suggestions,
  },
]

const SelectedUser = () => {
  const [tab, setTab] = useState(TABS[0].type)
  const profile = useSelectedUserProfile()
  const { Component } = TABS.find((t) => t.type === tab)

  if (!profile) return null

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          pt: 4,
          pb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <UserInfo user={profile.user} />
      </Box>
      <Box sx={{ px: 2 }}>
        <Tabs tabs={TABS} activeTab={tab} onChange={setTab} />
      </Box>
      <Component metrics={profile.metrics} />
    </Box>
  )
}

export default SelectedUser
