import { useAuth } from 'store/selectors'
import Main from 'components/Main'
import Auth from 'components/Auth'
import './App.css'
import Modals from 'components/modals'

const App = () => {
  const { authUser } = useAuth()
  return (
    <>
      {authUser ? <Main /> : <Auth />}
      <Modals />
    </>
  )
}

export default App
