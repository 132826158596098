import Box from '@mui/material/Box'
import Avatar from 'components/core/Avatar'

const SearchResults = ({ users, onClickUser }) => (
  <>
    {users.map((user) => (
      <Box
        key={user.id}
        sx={{
          py: 1,
          px: 2,
          '&:hover': { backgroundColor: 'action.hover' },
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: 'divider',
        }}
        onClick={onClickUser.bind(null, user)}
      >
        <Avatar name={user.full_name} image={user.picture} size={30} />
        <Box sx={{ ml: 2, minWidth: 150, flex: 0 }}>@{user.username}</Box>
        <Box sx={{ ml: 2 }}>{user.full_name}</Box>
      </Box>
    ))}
  </>
)

export default SearchResults
