import React from 'react'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles'
import { useSettings } from 'store/selectors'
import themes from './'

const ThemeProvider = ({ children }) => {
  const { theme: themeType } = useSettings()
  const theme = createTheme(themes[themeType])
  console.log(theme)

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}

export default ThemeProvider
