import React, { useState } from 'react'
import { useChatContext } from 'stream-chat-react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import './CreateChannel.css'
import SelectUsers from '../SelectUsers'
import { useNavigate } from 'react-router-dom'

const CreateChannel = () => {
  const { client, setActiveChannel } = useChatContext()
  const [selectedUsers, setSelectedUsers] = useState([])
  const navigate = useNavigate()

  const createChannel = async () => {
    const selectedUsersIds = selectedUsers.map((u) => u.id)

    if (!selectedUsersIds.length) return

    const conversation = await client.channel('messaging', {
      members: [...selectedUsersIds, client.userID],
    })

    setActiveChannel(conversation)
    await conversation.watch()

    setSelectedUsers([])
    navigate('/chat')
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        height: '100%',
        color: 'text.primary',
      }}
      className="messaging-create-channel"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'background.paper',
          pr: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <SelectUsers
            selectedUsers={selectedUsers}
            onChangeSelectedUsers={setSelectedUsers}
          />
        </Box>
        <Button
          variant="contained"
          onClick={createChannel}
          disabled={selectedUsers.length === 0}
          sx={{ marginLeft: 1, marginRight: 1 }}
        >
          Start Chat
        </Button>
        <IconButton onClick={navigate.bind(null, '/chat')} title="Close">
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default React.memo(CreateChannel)
