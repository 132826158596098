import Profile from './Profile'
import ReclipPlayer from './ReclipPlayer'
import SharePlayer from './SharePlayer'

const Modals = () => {
  return (
    <>
      <Profile />
      <ReclipPlayer />
      <SharePlayer />
    </>
  )
}

export default Modals
