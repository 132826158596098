import { useMemo } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Histogram from './Histogram'

const Legend = ({ isLoading, error, reclips, days, setDays }) => {
  const points = useMemo(() => {
    if (!reclips) return null
    return reclips.map((reclip) => new Date(reclip.created_at + 'Z'))
  }, [reclips])

  const uniqueUsers = useMemo(() => {
    if (!reclips) return null

    return [...new Set(reclips.map((reclip) => reclip.user_id))].length
  }, [reclips])

  return (
    <Box
      sx={{
        borderRadius: 2,
        py: 2,
        px: 4,
        backgroundColor: 'rgba(0,0,0,0.7)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '360px',
      }}
    >
      <Box sx={{ fontWeight: 700, fontSize: '1.2em', mb: 2 }}>Recent Clips</Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'background.paper',
          borderRadius: '6px',
          pr: 1,
          '& > select': {
            outline: 'none',
            border: 'none',
            backgroundColor: 'transparent',
            color: 'white',
            width: '200px',
            fontSize: '1rem',
            padding: '0.5rem',
          },
        }}
      >
        <select
          value={days.toString()}
          onChange={(e) => setDays(+e.target.value)}
        >
          <option value="1">last 24 hours</option>
          <option value="3">last 3 days</option>
          <option value="7">last 7 days</option>
        </select>
      </Box>
      <Divider sx={{ my: 2, width: '100%' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '215px',
        }}
      >
        {isLoading && <CircularProgress size={30} />}
        {!isLoading && reclips && (
          <Box sx={{ textAlign: 'center' }}>
            <Box>
              <b>{reclips.length}</b> clips
            </Box>
            <Box>
              from <b>{uniqueUsers}</b> users
            </Box>
            <Box sx={{ width: '300px', mt: 3 }}>
              <Histogram points={points} is_date={true} num_bins={days * 24} />
            </Box>
          </Box>
        )}
      </Box>
      <Divider sx={{ my: 2, width: '100%' }} />
      <Box sx={{ fontSize: '0.9em', color: 'text.primary' }}>
        <Box>● hover on a clip to see more info</Box>
        <Box>● click on a clip to see the user's profile</Box>
      </Box>
    </Box>
  )
}

export default Legend
