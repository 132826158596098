import { useSelectedUserProfile } from 'store/selectors'
import UserList from 'components/Main/Networks/Sidebar/core/UserList'

const Contacts = () => {
  const { user, contacts } = useSelectedUserProfile()
  return (
    <UserList
      nodes={contacts.filter((c) => c.is_reclip_user)}
      emptyText={`@${user.username} has no contacts.`}
    />
  )
}

export default Contacts
