import { useCallback, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import { useModal } from 'store/selectors'
import Button from '@mui/material/Button'
import RadioButtons from 'components/core/RadioButtons'

const ConfirmDeleteBroadcastModal = () => {
  const {
    isOpen,
    params: { onConfirm },
  } = useModal('confirmPublish')
  const { closeModal } = useModalsActions()
  const [sendNotification, setSendNotification] = useState(null)

  const doClose = useCallback(() => {
    closeModal('confirmPublish')
    setSendNotification(null)
  }, [closeModal])

  const confirm = useCallback(() => {
    onConfirm(sendNotification)
    doClose()
  }, [onConfirm, doClose, sendNotification])

  return (
    <Dialog
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { backgroundColor: 'background.default' } }}
      onClose={doClose}
    >
      <Box sx={{ p: 4, backgroundColor: 'background.default' }}>
        <Box sx={{ mb: 4, textAlign: 'center', maxWidth: 400 }}>
          <Box sx={{ fontWeight: 700, fontSize: '1.1em', mb: 1 }}>
            Send a notification?
          </Box>
          <RadioButtons
            items={[
              {
                value: true,
                label: 'Yes',
              },
              {
                value: false,
                label: 'No',
              },
            ]}
            value={sendNotification}
            onChange={setSendNotification}
          />
          <Box sx={{ mt: 2, fontSize: '0.9em' }}>
            If you choose Yes, a notification will be sent <br />
            to all users when the story is published.
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={confirm}
            disabled={sendNotification === null}
          >
            Publish
          </Button>
          <Button sx={{ ml: 4 }} variant="outlined" onClick={doClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ConfirmDeleteBroadcastModal
