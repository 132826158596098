import * as user from './user'
import * as chat from './chat'
import * as broadcasts from './broadcasts'
import * as networks from './networks'
import * as users from './users'
import * as staff from './staff'
import * as search from './search'
import * as deepgram from './deepgram'
import * as map from './map'
import * as reclips from './reclips'
import * as shares from './shares'
import * as openai from './openai'
import * as schools from './schools'
import * as reactions from './reactions'
import * as teamReclipStory from './teamReclipStory'
import * as clusters from './clusters'
import * as shareVideos from './shareVideos'
import * as suggestions from './suggestions'
import * as onboarding from './onboarding'

const api = {
  user,
  chat,
  broadcasts,
  networks,
  users,
  staff,
  search,
  deepgram,
  map,
  reclips,
  shares,
  openai,
  schools,
  reactions,
  teamReclipStory,
  clusters,
  shareVideos,
  suggestions,
  onboarding,
}

export default api
