import Box from '@mui/material/Box'
import ReplayIcon from '@mui/icons-material/Replay'

const EndedOverlay = ({ replay }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.6) 100%)',
      }}
      onClick={replay}
    >
      <ReplayIcon sx={{ width: '100px', height: '100px' }} />
    </Box>
  )
}

export default EndedOverlay
