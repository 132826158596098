import axios from 'axios'
import { ADMIN_API_URL } from 'const'
import queryString from 'query-string'

const BASE_URL = `${ADMIN_API_URL}/openai`

export const getMetadata = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/meta`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getConfig = async (configType) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/configs/${configType}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const testConfig = async (text, config) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/configs/test`, {
      text,
      config,
    })
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const saveConfig = async (config) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/configs`, config)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getTagsMeta = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/tags/meta`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getTagCounts = async (filters) => {
  const query = queryString.stringify({
    limit: filters.limit,
    start_date: filters.startDate.format(),
    end_date: filters.endDate.format(),
  })

  try {
    const { data } = await axios.get(`${BASE_URL}/tags/counts?${query}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const searchTags = async (filters) => {
  const query = queryString.stringify({
    limit: filters.limit,
    start_date: filters.startDate.format(),
    end_date: filters.endDate.format(),
    tag_type: filters.tagType,
    tag: filters.tag,
  })

  try {
    const { data } = await axios.get(`${BASE_URL}/tags/search?${query}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
